export function shadeColor(color, percent) {
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  const newColor = `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
  return newColor;
}

export function getStatusColor(status) {
  switch (status) {
    case "Disponible":
      return "success";
    case "Bloqueado":
      return "error";
    case "Bloqueado/Posible a la venta":
      return "warning";
    case "De terceros":
      return "warning";
    case "Inversionista":
      return "warning";
    case "Señado":
      return "warning";
    case "Pendiente de escriturar":
      return "info";
    case "Con boleto":
      return "info";
    case "Escriturado":
      return "primary";
    default:
      return "dark";
  }
}

export function renderStatusPlano(status) {
  switch (status) {
    case "Zona comercial":
      return "#778da9";
    case "Sin asignar":
      return "#ced4da";
    case "Seleccionado":
      return "#cb0c9f";
    case "Disponible":
      return "#82d616";
    case "Bloqueado":
      return "#ea0606";
    case "Bloqueado/Posible a la venta":
      return "#bc3908";
    case "De terceros":
      return "#ef8937";
    case "Inversionista":
      return "#ff785a";
    case "Señado":
      return "#fbcf33";
    case "Pendiente de escriturar":
      return "#17c1e8";
    case "Con boleto":
      return "#2152ff";
    case "Escriturado":
      return "#133222";
    case "Producto actual":
      return "#133222";
    default:
      return "#344767";
  }
}

export function getColorNodo(status) {
  let statusFormat = status.toLowerCase();
  switch (statusFormat) {
    case "aprobado":
      return "#82d616";
    case "sin iniciar":
      return "#fbcf33";
    case "contratado":
      return "#ef8937";
    case "acordado":
      return "#cb0c9f";
    case "realizado":
      return "#2152ff";
    case "suspendido":
      return "#bc3908";
    case "cancelado":
      return "#ea0606";
    case "no aplica":
      return "#778da9";
    case "en proceso":
      return "#17c1e8";
    case "completado":
      return "#133222";
    case "pendiente de aprobación":
      return "#ef8937";
    default:
      return "#344767";
  }
}

export function getColorEstadoConsulta(estado) {
  switch (estado) {
    case "Nuevo":
      return "#cb0c9f";
    case "Primer Contacto":
      return "#17c1e8";
    case "Segundo Contacto":
      return "#133222";
    case "Tercer Contacto":
      return "#133222";
    case "Sin Respuesta":
      return "#fbcf33";
    case "Pasar Información":
      return "#ff785a";
    case "Visita":
      return "#2152ff";
    case "Rechazado":
      return "#ea0606";
    case "Presupuesto":
      return "#82d616";
    case "Por Contactar":
      return "#778da9";
    default:
      return "#344767";
  }
}

export function getUproColor(name) {
  switch (name) {
    case "uproGreen":
      return "#67C1B8";
    case "uproPink":
      return "#F0057A";
    case "uproYellow":
      return "#F59B24";
    case "uproBlue":
      return "#2C3087";
    case "dark":
      return "#344767";
    case "error":
      return "#ea0606";
    default:
      return "#96a2b8";
  }
}

export const uproColors = [
  "#F0057A",
  "#67C1B8",
  "#F59B24",
  "#2C3087",
  "#147877",
  "#DC2626",
  "#262261",
  "#8d0e57",
  "#1F2937",
  "#F0057A",
  "#67C1B8",
  "#F59B24",
  "#2C3087",
  "#147877",
  "#DC2626",
  "#262261",
  "#8d0e57",
  "#1F2937",
  "#F0057A",
  "#67C1B8",
  "#F59B24",
  "#2C3087",
  "#147877",
  "#DC2626",
  "#262261",
  "#8d0e57",
  "#1F2937",
];

export const uproColorsList = [
  {
    name: "Verde UPrO",
    color: "#67C1B8",
  },
  {
    name: "Rosa UPrO",
    color: "#F0057A",
  },
  {
    name: "Amarillo UPrO",
    color: "#F59B24",
  },
  {
    name: "Azul UPrO",
    color: "#2C3087",
  },
  {
    name: "Gris UPrO",
    color: "#344767",
  },
  {
    name: "Rojo UPrO",
    color: "#DC2626",
  },
];

export const tallesCalzado = [
  "35",
  "35.5",
  "36",
  "36.5",
  "37",
  "37.5",
  "38",
  "38.5",
  "39",
  "39.5",
  "40",
  "40.5",
  "41",
  "41.5",
  "42",
  "42.5",
  "43",
  "43.5",
  "44",
  "44.5",
  "45",
  "45.5",
  "46",
];

export const tallesRopa = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"];
