import { Backdrop, Card, Fade, Icon, Modal, Tooltip } from "@mui/material";
import SoftBox from "components/CheckBox";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90vw",
  maxHeight: "90vh",
  outline: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
};

export default function ModalImage({ open, handleClose, src }) {
  const containerRef = useRef(null);
  const imgRef = useRef(null);

  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });

  // Refs para el zoom con pinch
  const pinchStartDistanceRef = useRef(null);
  const pinchStartScaleRef = useRef(1);

  // Al abrir el modal, reiniciamos posición y recalculamos el scale para ajustar la imagen
  useEffect(() => {
    if (open) {
      setPosition({ x: 0, y: 0 });
      setDragging(false);
      setLastPosition({ x: 0, y: 0 });
      pinchStartDistanceRef.current = null;
      pinchStartScaleRef.current = 1;

      // Si la imagen ya está cargada, calculamos el scale inicial
      if (containerRef.current && imgRef.current && imgRef.current.complete) {
        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;
        const naturalWidth = imgRef.current.naturalWidth;
        const naturalHeight = imgRef.current.naturalHeight;
        const newScale = Math.min(containerWidth / naturalWidth, containerHeight / naturalHeight);
        setScale(newScale);
      } else {
        setScale(1);
      }
    }
  }, [open]);

  // onLoad de la imagen para calcular el scale inicial si aún no se hizo
  const handleImageLoad = () => {
    if (containerRef.current && imgRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;
      const naturalWidth = imgRef.current.naturalWidth;
      const naturalHeight = imgRef.current.naturalHeight;
      const newScale = Math.min(containerWidth / naturalWidth, containerHeight / naturalHeight);
      setScale(newScale);
    }
  };

  // Manejo de zoom con la rueda (desktop)
  const handleWheel = (e) => {
    e.preventDefault();
    const delta = e.deltaY > 0 ? -0.1 : 0.1;

    // Calcular el nuevo valor de zoom
    const newScale = scale + delta;

    // Verificar si el zoom llegó al mínimo (por ejemplo, 0.5), y si es así, restablecer al valor inicial
    if (newScale <= 0.5) {
      setScale(0.5); 
    } else {
      setScale(Math.min(Math.max(newScale, 0.5), 3));
    }
  };

  // Eventos de mouse para arrastrar
  const handleMouseDown = (e) => {
    e.preventDefault();
    setDragging(true);
    setLastPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    const deltaX = e.clientX - lastPosition.x;
    const deltaY = e.clientY - lastPosition.y;
    setPosition((prev) => ({
      x: prev.x + deltaX,
      y: prev.y + deltaY,
    }));
    setLastPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const handleTouchStart = (e) => {
    if (e.touches.length === 1) {
      setDragging(true);
      setLastPosition({
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      });
    } else if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(touch2.clientX - touch1.clientX, touch2.clientY - touch1.clientY);
      pinchStartDistanceRef.current = distance;
      pinchStartScaleRef.current = scale;
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 1 && dragging && !pinchStartDistanceRef.current) {
      const touch = e.touches[0];
      const deltaX = touch.clientX - lastPosition.x;
      const deltaY = touch.clientY - lastPosition.y;
      setPosition((prev) => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY,
      }));
      setLastPosition({ x: touch.clientX, y: touch.clientY });
    } else if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      if (pinchStartDistanceRef.current) {
        let newScale =
          pinchStartScaleRef.current * (distance / pinchStartDistanceRef.current);
        newScale = Math.min(Math.max(newScale, 0.5), 3);

        if (newScale <= 0.5) {
          setScale(0.5);
        } else {
          setScale(newScale);
        }
      }
    }
  };

  const handleTouchEnd = (e) => {
    if (e.touches.length < 2) {
      pinchStartDistanceRef.current = null;
    }
    if (e.touches.length === 0) {
      setDragging(false);
    }
  };

  const handleTouchCancel = () => {
    pinchStartDistanceRef.current = null;
    setDragging(false);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none"; 
    } else {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
    }
  
    return () => {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
    };
  }, [open]);
  
  useEffect(() => {
    if (!containerRef.current) return;

    const wheelHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const delta = e.deltaY > 0 ? -0.1 : 0.1;
      const newScale = scale + delta;
      if (newScale <= 0.5) {
        setScale(0.5);
      } else {
        setScale(Math.min(Math.max(newScale, 0.5), 3));
      }
    };

    const container = containerRef.current;
    container.addEventListener("wheel", wheelHandler, { passive: false });

    return () => {
      container.removeEventListener("wheel", wheelHandler);
    };
  }, [scale, open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Card
          ref={containerRef}
          sx={{
            ...style,
            touchAction: "none",
          }}
          onWheel={handleWheel}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchCancel}
        >
          <SoftBox
            p={2}
            pb={0}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <Tooltip title="Cerrar" placement="top">
              <SoftButton color="primary" onClick={handleClose} circular iconOnly>
                <Icon>close</Icon>
              </SoftButton>
            </Tooltip>
          </SoftBox>

          <img
            ref={imgRef}
            src={src}
            alt="Imagen"
            onLoad={handleImageLoad}
            style={{
              transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
              cursor: dragging ? "grabbing" : "grab",
              userSelect: "none",
            }}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalImage.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  src: PropTypes.string,
};
