// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MenuItem } from "@mui/material";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import ModalDelete from "components/Modals/Delete";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import { getUproColor } from "utils/colors";
import ModalComisiones from "../ModalComisiones";
import ModalDocumentacionCarrera from "../ModalDocumentacion";
import ModalExamenes from "../ModalExamenes";
import ModalMateria from "../ModalMateria";
import ModalNivel from "../ModalNivel";
import ModalPlan from "../ModalPlan";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalCarrera({ open, handleClose, refetch, idCarrera, tipo }) {
  const [carrera, setCarrera] = useState({
    planes: [],
  });
  const { handleSnackbar } = useContext(MessageManager);
  const [openModalDocumentacion, setOpenModalDocumentacion] = useState(false);
  const [expandedPlanes, setExpandedPlanes] = useState([]);
  const [expandedNiveles, setExpandedNiveles] = useState([]);
  const { menu } = useContext(MenuContext);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedNivel, setSelectedNivel] = useState(null);
  const [selectedMateria, setSelectedMateria] = useState(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalPlan, setOpenModalPlan] = useState(false);
  const [openModalNivel, setOpenModalNivel] = useState(false);
  const [openModalMateria, setOpenModalMateria] = useState(false);
  const [openModalComisiones, setOpenModalComisiones] = useState(false);
  const [openModalExamenes, setOpenModalExamenes] = useState(false);
  const [deleteData, setDeleteData] = useState({
    nombre: "",
    funcionDelete: () => {},
  });

  const [getCarrera, { data: selectedCarrera, refetch: refetchCarrera, loading }] = useLazyQuery(
    gql`
      query getCarrera($id: ID!) {
        carrera(id: $id) {
          id
          nombre
          codigo
          tipo
          descripcion
          requiereTalles
          unidadAcademica {
            id
          }
          sedes {
            id
          }
          planes {
            id
            nombre
            codigo
            niveles {
              id
              nombre
              codigo
              materias {
                id
                nombre
                codigo
                docentesTitulares {
                  id
                  nombre
                  apellido
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: idCarrera,
      },
      fetchPolicy: "network-only",
    }
  );

  const [deletePlanEstudio] = useMutation(
    gql`
      mutation deletePlanEstudio($id: ID!) {
        deletePlanEstudio(id: $id) {
          id
        }
      }
    `
  );

  const [deleteNivel] = useMutation(
    gql`
      mutation deleteNivel($id: ID!) {
        deleteNivel(id: $id) {
          id
        }
      }
    `
  );

  const [deleteMateria] = useMutation(
    gql`
      mutation deleteMateria($id: ID!) {
        deleteMateria(id: $id) {
          id
        }
      }
    `
  );

  const handleChanges = (e) => {
    setCarrera({
      ...carrera,
      [e.target.name]: e.target.value,
    });
  };

  const [saveCarrera, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveCarrera($input: CarreraInput!) {
        saveCarrera(input: $input) {
          id
        }
      }
    `
  );

  const { data: unidadesAcademicas, refetch: refetchUnidadesAcademicas } = useQuery(
    gql`
      query getUnidadesAcademicas {
        unidadesAcademicas {
          id
          nombre
          codigo
        }
      }
    `
  );

  const { data: sedes, refetch: refetchSedes } = useQuery(
    gql`
      query getSedes {
        sedes(order: { field: "nombre", order: ASC }) {
          id
          nombre
        }
      }
    `
  );

  const handleSave = () => {
    saveCarrera({
      variables: {
        input: {
          id: carrera?.id || null,
          nombre: carrera.nombre,
          codigo: carrera.codigo,
          descripcion: carrera.descripcion || null,
          idUnidadAcademica: carrera.idUnidadAcademica,
          tipo: carrera.tipo || tipo,
          idsSedes: carrera.idsSedes || [],
          requiereTalles: carrera.requiereTalles === 1,
        },
      },
    })
      .then(() => {
        refetch();
        handleSnackbar(
          tipo !== "Carrera" ? "Curso guardado correctamente" : "Carrera guardada correctamente",
          "success"
        );
        handleClose();
        setCarrera({
          planes: [],
          idsSedes: [],
        });
      })
      .catch((e) => {
        handleSnackbar(
          tipo !== "Carrera" ? "Error al guardar el curso" : "Error al guardar la carrera",
          "error"
        );
      });
  };

  useEffect(() => {
    if (selectedCarrera && open) {
      refetchSedes();
      refetchUnidadesAcademicas();
      setCarrera({
        id: selectedCarrera?.carrera.id,
        nombre: selectedCarrera?.carrera.nombre,
        codigo: selectedCarrera?.carrera.codigo,
        descripcion: selectedCarrera?.carrera.descripcion,
        idUnidadAcademica: selectedCarrera?.carrera?.unidadAcademica?.id,
        planes: selectedCarrera?.carrera?.planes || [],
        idsSedes: selectedCarrera?.carrera?.sedes?.map((sede) => sede.id) || [],
        tipo: selectedCarrera?.carrera?.tipo,
        requiereTalles: selectedCarrera?.carrera?.requiereTalles || 0,
      });
      setTimeout(() => {
        if (selectedCarrera?.carrera?.planes?.length) {
          setExpandedPlanes([
            selectedCarrera?.carrera?.planes[selectedCarrera?.carrera?.planes.length - 1].id,
          ]);
        }
      }, 100);
    } else {
      setCarrera({
        planes: [],
        idsSedes: [],
      });
    }
  }, [selectedCarrera, open]);

  useEffect(() => {
    if (open && idCarrera) {
      getCarrera();
    } else {
      setCarrera({
        planes: [],
        idsSedes: [],
      });
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedCarrera?.carrera
                    ? `Editar ${tipo.toLowerCase()}`
                    : `Agregar ${tipo.toLowerCase()}`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <Tooltip
                  title={
                    selectedCarrera?.carrera?.id
                      ? "Administrar documentación"
                      : "Debe crear la carrera antes de agregar documentación"
                  }
                  placement="top"
                >
                  <SoftBox mr={2}>
                    <SoftButton
                      color={"uproYellow"}
                      circular
                      onClick={() => setOpenModalDocumentacion(true)}
                      disabled={!selectedCarrera?.carrera?.id}
                    >
                      <Icon>description</Icon>
                      &nbsp;Documentación
                    </SoftButton>
                  </SoftBox>
                </Tooltip>
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                {tipo !== "Carrera" && (
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="codigo">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Tipo
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <Select name="tipo" value={carrera?.tipo || -1} onChange={handleChanges}>
                      <MenuItem value={-1} disabled>
                        Seleccione uno
                      </MenuItem>
                      <MenuItem value="Curso">Curso</MenuItem>
                      <MenuItem value="Curso de Ingreso">Curso de Ingreso</MenuItem>
                    </Select>
                  </Grid>
                )}
                <Grid item xs={12} sm={1}>
                  <InputLabel htmlFor="codigo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Código
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="codigo"
                    placeholder={
                      tipo === "Carrera"
                        ? "Ingrese el código de la carrera"
                        : "Ingrese el código del curso"
                    }
                    type="text"
                    name="codigo"
                    value={carrera?.codigo || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder={
                      tipo === "Carrera"
                        ? "Ingrese el nombre de la carrera"
                        : "Ingrese el nombre del curso"
                    }
                    type="text"
                    name="nombre"
                    value={carrera?.nombre || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                {tipo === "Carrera" && (
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="idUnidadAcademica">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Unidad académica
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <Select
                      name="idUnidadAcademica"
                      value={carrera?.idUnidadAcademica || -1}
                      onChange={handleChanges}
                    >
                      <MenuItem value={-1} disabled>
                        Seleccione uno
                      </MenuItem>
                      {unidadesAcademicas?.unidadesAcademicas?.map((ua) => (
                        <MenuItem key={ua.id} value={ua.id}>
                          {`${ua.codigo} - ${ua.nombre}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="requiereTalles">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Requiere talles
                    </SoftTypography>
                  </InputLabel>
                  <Tabs
                    value={carrera?.requiereTalles ? 1 : 0}
                    name="requiereTalles"
                    onChange={(e, value) => {
                      setCarrera({
                        ...carrera,
                        requiereTalles: value,
                      });
                    }}
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={!carrera?.requiereTalles ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={carrera?.requiereTalles ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>
                {tipo === "Carrera" && (
                  <Grid item xs={12}>
                    <InputLabel htmlFor="idsSedes">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Sedes donde se dictará
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <Autocomplete
                      multiple
                      noOptionsText="No hay sedes"
                      id="tags-outlined"
                      options={sedes?.sedes.map((sede) => sede.id) || []}
                      getOptionLabel={(option) => {
                        let sede = sedes?.sedes.find((s) => s.id == option);
                        return `${sede?.nombre}`;
                      }}
                      filterSelectedOptions
                      value={carrera?.idsSedes || []}
                      onChange={(e, value) => {
                        setCarrera({
                          ...carrera,
                          idsSedes: value,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                      sx={{
                        ".MuiAutocomplete-inputRoot": {
                          padding: `0 !important`,
                        },
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <InputLabel htmlFor="descripcion">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Descripción
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="descripcion"
                    placeholder={
                      tipo === "Carrera"
                        ? "Ingrese la descripción de la carrera"
                        : "Ingrese la descripción del curso"
                    }
                    type="text"
                    name="descripcion"
                    value={carrera?.descripcion || ""}
                    multiline
                    rows={4}
                    onChange={handleChanges}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mt={3}>
              <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="h6">Planes de estudio</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={
                      selectedCarrera?.carrera?.id
                        ? "Agregar plan de estudio"
                        : "Debe crear la carrera antes de agregar planes de estudio"
                    }
                    placement="top"
                  >
                    <SoftBox>
                      <SoftButton
                        color={"uproGreen"}
                        circular
                        onClick={() => {
                          setOpenModalPlan(true);
                        }}
                        disabled={!selectedCarrera?.carrera?.id}
                      >
                        <Icon>add</Icon>
                        &nbsp;Agregar
                      </SoftButton>
                    </SoftBox>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                {loading ? (
                  <Grid item xs={12}>
                    <Loading />
                  </Grid>
                ) : carrera?.planes?.length === 0 ? (
                  <Grid item xs={12}>
                    <SinDatos />
                  </Grid>
                ) : (
                  carrera?.planes?.map((plan, index) => (
                    <Grid item xs={12} key={plan.id}>
                      <Card
                        sx={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          px={2}
                          py={1}
                          sx={{
                            borderBottom:
                              !expandedPlanes.includes(plan.id) || !plan.niveles?.length
                                ? "none"
                                : "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                          }}
                          onClick={(e) => {
                            e.target.tagName !== "BUTTON" &&
                              e.target.tagName !== "SPAN" &&
                              plan.niveles?.length &&
                              setExpandedPlanes(
                                expandedPlanes.includes(plan.id)
                                  ? expandedPlanes.filter((i) => i !== plan.id)
                                  : [...expandedPlanes, plan.id]
                              );
                          }}
                        >
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox mr={1}>
                              <Tooltip title="Plan de estudio" placement="top">
                                <Icon color="primary">task</Icon>
                              </Tooltip>
                            </SoftBox>
                            <SoftTypography
                              variant="h6"
                              fontWeight="bold"
                            >{`${plan.codigo} - ${plan.nombre}`}</SoftTypography>
                            <SoftBox ml={1} display="flex" alignItems="center">
                              <SoftBadge
                                color="primary"
                                badgeContent={`${plan.niveles?.length} nivel${
                                  plan.niveles?.length === 1 ? "" : "es"
                                }`}
                              />
                            </SoftBox>
                          </SoftBox>
                          <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                            <SoftBox mr={2}>
                              <Tooltip title="Opciones" placement="top">
                                <SoftButton
                                  color="uproYellow"
                                  circular
                                  iconOnly
                                  onClick={(event) => {
                                    menu({
                                      open: event.currentTarget,
                                      align: "right",
                                      options: [
                                        {
                                          name: "Agregar nivel",
                                          icon: { icon: "add", color: getUproColor("uproGreen") },
                                          onClick: () => {
                                            setSelectedPlan(plan);
                                            setOpenModalNivel(true);
                                          },
                                        },
                                        {
                                          name: "Editar",
                                          icon: { icon: "edit", color: getUproColor("uproYellow") },
                                          onClick: () => {
                                            setSelectedPlan(plan);
                                            setOpenModalPlan(true);
                                          },
                                        },
                                        {
                                          name: "Eliminar",
                                          icon: { icon: "delete", color: "error" },
                                          onClick: () => {
                                            setDeleteData({
                                              nombre: `El plan ${plan.codigo} - ${plan.nombre}`,
                                              funcionDelete: () => {
                                                deletePlanEstudio({
                                                  variables: {
                                                    id: plan.id,
                                                  },
                                                })
                                                  .then(() => {
                                                    refetchCarrera();
                                                    handleSnackbar(
                                                      "Plan eliminado correctamente",
                                                      "success"
                                                    );
                                                  })
                                                  .catch((e) => {
                                                    handleSnackbar(
                                                      "Error al eliminar el plan",
                                                      "error"
                                                    );
                                                  });
                                              },
                                            });
                                            setOpenModalDelete(true);
                                          },
                                        },
                                      ],
                                      handleClose: () => {},
                                    });
                                  }}
                                >
                                  <Icon>more_vert</Icon>
                                </SoftButton>
                              </Tooltip>
                            </SoftBox>
                            <SoftBox>
                              <Tooltip
                                title={
                                  expandedPlanes.includes(plan.id)
                                    ? "Contraer plan"
                                    : "Expandir plan"
                                }
                                placement="top"
                              >
                                <SoftButton
                                  color="primary"
                                  circular
                                  iconOnly
                                  disabled={!plan.niveles?.length}
                                  onClick={() =>
                                    setExpandedPlanes(
                                      expandedPlanes.includes(plan.id)
                                        ? expandedPlanes.filter((i) => i !== plan.id)
                                        : [...expandedPlanes, plan.id]
                                    )
                                  }
                                >
                                  <Icon
                                    sx={{
                                      rotate: expandedPlanes.includes(plan.id) ? "180deg" : "0deg",
                                      transition: "all 0.5s",
                                    }}
                                  >
                                    expand_more
                                  </Icon>
                                </SoftButton>
                              </Tooltip>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                        <Collapse in={expandedPlanes.includes(plan.id)}>
                          {plan.niveles?.map((nivel, index) => (
                            <SoftBox key={nivel.id}>
                              <SoftBox
                                px={2}
                                py={1}
                                sx={{
                                  borderBottom:
                                    plan.niveles.length - 1 === index &&
                                    !expandedNiveles.includes(nivel.id)
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  },
                                }}
                                onClick={(e) => {
                                  e.target.tagName !== "BUTTON" &&
                                    e.target.tagName !== "SPAN" &&
                                    nivel.materias?.length &&
                                    setExpandedNiveles(
                                      expandedNiveles.includes(nivel.id)
                                        ? expandedNiveles.filter((i) => i !== nivel.id)
                                        : [...expandedNiveles, nivel.id]
                                    );
                                }}
                              >
                                <SoftBox display="flex" justifyContent="space-between">
                                  <SoftBox display="flex" alignItems="center" pl={2}>
                                    <SoftBox mr={1}>
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproPink"),
                                        }}
                                      >
                                        subdirectory_arrow_right
                                      </Icon>
                                    </SoftBox>
                                    <SoftBox mr={1}>
                                      <Tooltip title="Nivel" placement="top">
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproYellow"),
                                          }}
                                        >
                                          article
                                        </Icon>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftTypography variant="h6">{`${nivel.codigo} - ${nivel.nombre}`}</SoftTypography>
                                    <SoftBox ml={1} display="flex" alignItems="center">
                                      <SoftBadge
                                        color="uproYellow"
                                        badgeContent={`${nivel.materias?.length} Unidad${
                                          nivel.materias?.length === 1 ? "" : "es"
                                        } de Competencia`}
                                      />
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                  >
                                    <SoftBox mr={2}>
                                      <Tooltip title="Opciones" placement="top">
                                        <SoftButton
                                          color="uproYellow"
                                          circular
                                          iconOnly
                                          onClick={(event) => {
                                            menu({
                                              open: event.currentTarget,
                                              align: "right",
                                              options: [
                                                {
                                                  name: "Agregar Unidad de Competencia",
                                                  icon: {
                                                    icon: "add",
                                                    color: getUproColor("uproGreen"),
                                                  },
                                                  onClick: () => {
                                                    setSelectedNivel(nivel);
                                                    setOpenModalMateria(true);
                                                  },
                                                },
                                                {
                                                  name: "Editar",
                                                  icon: {
                                                    icon: "edit",
                                                    color: getUproColor("uproYellow"),
                                                  },
                                                  onClick: () => {
                                                    setSelectedPlan(plan);
                                                    setSelectedNivel(nivel);
                                                    setOpenModalNivel(true);
                                                  },
                                                },
                                                {
                                                  name: "Eliminar",
                                                  icon: { icon: "delete", color: "error" },
                                                  onClick: () => {
                                                    setDeleteData({
                                                      nombre: `El nivel ${nivel.codigo} - ${nivel.nombre}`,
                                                      funcionDelete: () => {
                                                        deleteNivel({
                                                          variables: {
                                                            id: nivel.id,
                                                          },
                                                        })
                                                          .then(() => {
                                                            if (
                                                              expandedNiveles.includes(nivel.id) &&
                                                              plan.niveles.length === 1
                                                            ) {
                                                              setExpandedPlanes(
                                                                expandedPlanes.filter(
                                                                  (i) => i !== plan.id
                                                                )
                                                              );
                                                            }
                                                            refetchCarrera();
                                                            handleSnackbar(
                                                              "Nivel eliminado correctamente",
                                                              "success"
                                                            );
                                                          })
                                                          .catch((e) => {
                                                            handleSnackbar(
                                                              "Error al eliminar el nivel",
                                                              "error"
                                                            );
                                                          });
                                                      },
                                                    });
                                                    setOpenModalDelete(true);
                                                  },
                                                },
                                              ],
                                              handleClose: () => {},
                                            });
                                          }}
                                        >
                                          <Icon>more_vert</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftBox>
                                      <Tooltip
                                        title={
                                          expandedNiveles.includes(nivel.id)
                                            ? "Contraer nivel"
                                            : "Expandir nivel"
                                        }
                                        placement="top"
                                      >
                                        <SoftButton
                                          color="primary"
                                          circular
                                          iconOnly
                                          disabled={!nivel.materias?.length}
                                          onClick={() =>
                                            setExpandedNiveles(
                                              expandedNiveles.includes(nivel.id)
                                                ? expandedNiveles.filter((i) => i !== nivel.id)
                                                : [...expandedNiveles, nivel.id]
                                            )
                                          }
                                        >
                                          <Icon
                                            sx={{
                                              rotate: expandedNiveles.includes(nivel.id)
                                                ? "180deg"
                                                : "0deg",
                                              transition: "all 0.5s",
                                            }}
                                          >
                                            expand_more
                                          </Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                              <Collapse in={expandedNiveles.includes(nivel.id)}>
                                {nivel.materias?.map((materia, index) => (
                                  <SoftBox
                                    key={materia.id}
                                    px={2}
                                    py={1}
                                    sx={{
                                      borderBottom:
                                        !expandedNiveles.includes(nivel.id) &&
                                        !nivel.materias.length
                                          ? "none"
                                          : "1px solid rgba(0, 0, 0, 0.12)",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                                      },
                                    }}
                                  >
                                    <SoftBox display="flex" justifyContent="space-between">
                                      <SoftBox display="flex" alignItems="center" pl={4}>
                                        <SoftBox mr={1}>
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproYellow"),
                                            }}
                                          >
                                            subdirectory_arrow_right
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox mr={1}>
                                          <Tooltip title="Unidad de Competencia" placement="top">
                                            <Icon
                                              sx={{
                                                color: getUproColor("uproGreen"),
                                              }}
                                            >
                                              book
                                            </Icon>
                                          </Tooltip>
                                        </SoftBox>
                                        <SoftTypography variant="h6">{`${materia.codigo} - ${materia.nombre}`}</SoftTypography>
                                      </SoftBox>
                                      <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                      >
                                        <SoftBox>
                                          <Tooltip title="Opciones" placement="top">
                                            <SoftButton
                                              color="uproYellow"
                                              circular
                                              iconOnly
                                              onClick={(event) => {
                                                menu({
                                                  open: event.currentTarget,
                                                  align: "right",
                                                  options: [
                                                    {
                                                      name: "Comisiones",
                                                      icon: {
                                                        icon: "group",
                                                        color: getUproColor("uproGreen"),
                                                      },
                                                      onClick: () => {
                                                        setSelectedMateria({
                                                          ...materia,
                                                          nivel: nivel,
                                                        });
                                                        setOpenModalComisiones(true);
                                                      },
                                                    },
                                                    {
                                                      name: "Exámenes",
                                                      icon: {
                                                        icon: "quiz",
                                                        color: getUproColor("uproPink"),
                                                      },
                                                      onClick: () => {
                                                        setSelectedMateria(materia);
                                                        setOpenModalExamenes(true);
                                                      },
                                                    },
                                                    {
                                                      name: "Editar",
                                                      icon: {
                                                        icon: "edit",
                                                        color: getUproColor("uproYellow"),
                                                      },
                                                      onClick: () => {
                                                        setSelectedNivel(nivel);
                                                        setSelectedMateria(materia);
                                                        setOpenModalMateria(true);
                                                      },
                                                    },
                                                    {
                                                      name: "Eliminar",
                                                      icon: { icon: "delete", color: "error" },
                                                      onClick: () => {
                                                        setDeleteData({
                                                          nombre: `La Unidad de Competencia ${materia.codigo} - ${materia.nombre}`,
                                                          funcionDelete: () => {
                                                            deleteMateria({
                                                              variables: {
                                                                id: materia.id,
                                                              },
                                                            })
                                                              .then(() => {
                                                                if (
                                                                  expandedNiveles.includes(
                                                                    nivel.id
                                                                  ) &&
                                                                  nivel.materias.length === 1
                                                                ) {
                                                                  setExpandedNiveles(
                                                                    expandedNiveles.filter(
                                                                      (i) => i !== nivel.id
                                                                    )
                                                                  );
                                                                }
                                                                refetchCarrera();
                                                                handleSnackbar(
                                                                  "Unidad de Competencia eliminada correctamente",
                                                                  "success"
                                                                );
                                                              })
                                                              .catch((e) => {
                                                                handleSnackbar(
                                                                  "Error al eliminar la Unidad de Competencia",
                                                                  "error"
                                                                );
                                                              });
                                                          },
                                                        });
                                                        setOpenModalDelete(true);
                                                      },
                                                    },
                                                  ],
                                                  handleClose: () => {},
                                                });
                                              }}
                                            >
                                              <Icon>more_vert</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                ))}
                              </Collapse>
                            </SoftBox>
                          ))}
                        </Collapse>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loadingSave}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  disabled={
                    loadingSave ||
                    !carrera?.codigo ||
                    !carrera?.nombre ||
                    (tipo === "Carrera" && carrera?.idsSedes?.length === 0) ||
                    (tipo === "Carrera" && !carrera?.idUnidadAcademica) ||
                    (tipo !== "Carrera" && !carrera?.tipo) ||
                    (carrera.codigo === selectedCarrera?.carrera?.codigo &&
                      carrera.nombre === selectedCarrera?.carrera?.nombre &&
                      carrera.idUnidadAcademica ===
                        selectedCarrera?.carrera?.unidadesAcademica?.id &&
                      carrera.descripcion === selectedCarrera?.carrera?.descripcion &&
                      carrera.tipo === selectedCarrera?.carrera?.tipo)
                  }
                  onClick={handleSave}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  {loadingSave ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
              <ModalDocumentacionCarrera
                open={openModalDocumentacion}
                handleClose={() => setOpenModalDocumentacion(false)}
                idCarrera={selectedCarrera?.carrera?.id}
                readOnly={false}
              />
            </SoftBox>
            <ModalDelete
              open={openModalDelete}
              handleClose={() => {
                setOpenModalDelete(false);
                setSelectedPlan(null);
                setSelectedNivel(null);
                setSelectedMateria(null);
                setDeleteData({
                  nombre: "",
                  funcionDelete: () => {},
                });
              }}
              nombre={deleteData.nombre}
              functionDelete={deleteData.funcionDelete}
            />
            <ModalPlan
              open={openModalPlan}
              handleClose={() => {
                setOpenModalPlan(false);
                setSelectedPlan(null);
                setSelectedNivel(null);
                setSelectedMateria(null);
              }}
              selectedPlan={selectedPlan}
              idCarrera={selectedCarrera?.carrera?.id}
              refetch={refetchCarrera}
              sedes={sedes?.sedes?.filter((sede) => carrera?.idsSedes?.includes(sede.id))}
            />
            <ModalNivel
              open={openModalNivel}
              handleClose={() => {
                setOpenModalNivel(false);
                setSelectedNivel(null);
                setSelectedPlan(null);
                setSelectedMateria(null);
              }}
              selectedNivel={selectedNivel}
              idPlan={selectedPlan?.id}
              refetch={() => {
                setExpandedPlanes([...expandedPlanes, selectedPlan.id]);
                refetchCarrera();
              }}
            />
            <ModalMateria
              open={openModalMateria}
              handleClose={() => {
                setOpenModalMateria(false);
                setSelectedMateria(null);
                setSelectedNivel(null);
                setSelectedPlan(null);
              }}
              selectedMateria={selectedMateria}
              idNivel={selectedNivel?.id}
              refetch={() => {
                setExpandedNiveles([...expandedNiveles, selectedNivel.id]);
                refetchCarrera();
              }}
            />
            <ModalComisiones
              open={openModalComisiones}
              handleClose={() => {
                setSelectedMateria(null);
                setOpenModalComisiones(false);
              }}
              selectedMateria={selectedMateria}
            />
            <ModalExamenes
              open={openModalExamenes}
              handleClose={() => {
                setSelectedMateria(null);
                setOpenModalExamenes(false);
              }}
              selectedMateria={selectedMateria}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCarrera.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  idCarrera: PropTypes.string,
  tipo: PropTypes.string,
};
