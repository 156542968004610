// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { validatePhone } from "utils/validations";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import { validarName } from "utils/validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalAula({ open, handleClose, refetch, selectedAula }) {
  const [aula, setAula] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const handleChanges = (e) => {
    setAula({
      ...aula,
      [e.target.name]: e.target.value,
    });
  };

  const [saveAula, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveAula($input: AulaInput!) {
        saveAula(input: $input) {
          id
        }
      }
    `
  );

  const { data: usuarios, refetch: refetchDocentes } = useQuery(
    gql`
      query getUsuarios {
        usuarios(filter: { tipoUser: "Gestión Académica" }) {
          id
          nombre
          apellido
        }
      }
    `
  );

  const { data: sedes, refetch: refetchSedes } = useQuery(gql`
    query GetSedes {
      sedes(order: { field: "nombre", order: ASC }) {
        id
        nombre
      }
    }
  `);

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const handleSave = () => {
    saveAula({
      variables: {
        input: {
          id: aula?.id,
          codigo: aula?.codigo,
          nombre: aula?.nombre,
          capacidad: aula?.capacidad,
          direccion: aula?.direccion || null,
          telefono: aula?.telefono || null,
          idSede: aula?.idSede,
          idResponsable: aula?.idResponsable,
        },
      },
    })
      .then(() => {
        refetch();
        handleSnackbar("Aula guardada correctamente", "success");
        handleClose();
      })
      .catch((e) => {
        handleSnackbar("Error al guardar el aula", "error");
      });
  };

  useEffect(() => {
    if (selectedAula && open) {
      refetchDocentes();
      setAula({
        id: selectedAula.id,
        nombre: selectedAula.nombre,
        codigo: selectedAula.codigo,
        capacidad: selectedAula.capacidad,
        direccion: selectedAula.direccion || null,
        telefono: selectedAula.telefono || null,
        idResponsable: selectedAula.responsable?.id,
        idSede: selectedAula.sede?.id,
      });
    } else {
      setAula();
    }
  }, [selectedAula, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedAula ? `Editar aula` : `Agregar aula`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre del aula"
                    type="text"
                    name="nombre"
                    value={aula?.nombre || ""}
                    onChange={handleChanges}
                    error={aula?.nombre?.length > 0 && !validarName(aula?.nombre)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="codigo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Código
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="codigo"
                    placeholder="Ingrese el código del aula"
                    type="text"
                    name="codigo"
                    value={aula?.codigo || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="capacidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Capacidad
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInputNumber
                    label="capacidad"
                    placeholder="Ingrese la capacidad del aula"
                    type="text"
                    name="capacidad"
                    value={aula?.capacidad || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Responsable
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="idResponsable"
                    value={aula?.idResponsable || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1}>Seleccione uno</MenuItem>
                    {usuarios?.usuarios?.map((docente) => (
                      <MenuItem key={docente.id} value={docente.id}>
                        {`${docente.nombre} ${docente.apellido}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="sede">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Sede
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select name="idSede" value={aula?.idSede || -1} onChange={handleChanges}>
                    <MenuItem value={-1}>Seleccione una</MenuItem>
                    {sedes?.sedes?.map((sedes) => (
                      <MenuItem key={sedes.id} value={sedes.id}>
                        {sedes.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="direccion">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Dirección
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="direccion"
                    placeholder="Ingrese la dirección del aula"
                    type="text"
                    name="direccion"
                    value={aula?.direccion || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="telefono">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Teléfono
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="telefono"
                    placeholder="Ingrese el teléfono del aula"
                    type="text"
                    name="telefono"
                    value={aula?.telefono || ""}
                    error={aula?.telefono && !validatePhone(aula?.telefono)}
                    onChange={handleChanges}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loadingSave}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  disabled={
                    loadingSave ||
                    !aula?.nombre ||
                    !aula?.codigo ||
                    !aula?.capacidad ||
                    !aula?.idResponsable ||
                    !aula?.idSede ||
                    (aula?.telefono && !validatePhone(aula?.telefono)) ||
                    (aula?.nombre?.length > 0 && !validarName(aula?.nombre)) ||
                    aula?.capacidad < 0 ||
                    (selectedAula?.nombre === aula?.nombre &&
                        selectedAula?.codigo === aula?.codigo &&
                      selectedAula?.direccion === aula?.direccion &&
                      selectedAula?.telefono === aula?.telefono &&
                      selectedAula?.responsable?.id === aula?.idResponsable &&
                      selectedAula?.sede?.id === aula?.idSede && 
                        selectedAula?.capacidad === aula?.capacidad
                    )
                  }
                  onClick={handleSave}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  {loadingSave ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAula.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedAula: PropTypes.object,
};
