// @mui material components
import { Card, Grid, Icon, Tooltip, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";

// Kaizen Dashboard components
import { gql, useQuery } from "@apollo/client";
import VerticalBarChart from "components/Charts/BarCharts/VerticalBarChart";
import DoughnutChart from "components/Charts/DoughnutCharts/CustomDoughnutChart";
import PieChart from "components/Charts/PieChart";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor, uproColors } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import * as XLSX from "xlsx";
import SoftInput from "components/SoftInput";

function ReporteDemografico({ tipo, sedes, tipoCarrera }) {
  const [date, setDate] = useState({
    fechaInicio: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  });
  const [expandSelector, setExpandSelector] = useState(false);
  const [selectedSede, setSelectedSede] = useState();
  const [expandSedes, setExpandSedes] = useState(false);
  const { data: reportes } = useQuery(
    gql`
      query getReportes(
        $fechaInicio: String
        $fechaFin: String
        $tipo: [String]
        $idSede: ID
        $tipoCarrera: String
      ) {
        reporteDemografico(
          fechaInicio: $fechaInicio
          fechaFin: $fechaFin
          tipo: $tipo
          idSede: $idSede
          tipoCarrera: $tipoCarrera
        ) {
          totalInscripciones
          promedioEdad
          totalBeneficiosSociales
          totalDiscapacitados
          totalRangoEdades {
            cantidad
            rangoEdad
          }
          totalGeneros {
            cantidad
            genero
          }
        }
      }
    `,
    {
      variables: {
        fechaInicio: date.fechaInicio,
        fechaFin: date.fechaFin,
        tipo: tipo.split(","),
        idSede: selectedSede?.id || null,
        tipoCarrera,
      },
    }
  );
  const { menu } = useContext(MenuContext);

  return (
    <Card>
      <SoftBox p={3}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
          <SoftTypography variant="h6">{`Reporte demográfico de ${
            tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
          }`}</SoftTypography>
          <SoftBox display="flex" justifyContent="start" alignItems="center">
            <SoftBox mr={2}>
              <SoftButton
                color="uproGreen"
                circular
                onClick={(event) => {
                  setExpandSedes(!expandSedes);
                  menu({
                    open: event.currentTarget,
                    align: "right",
                    onClose: () => {
                      setExpandSedes(false);
                    },
                    options: [
                      {
                        name: "Todas las sedes",
                        icon: {
                          icon: !selectedSede ? "location_on" : "location_city",
                          color: !selectedSede
                            ? getUproColor("uproPink")
                            : getUproColor("uproGreen"),
                        },
                        onClick: () => {
                          setSelectedSede(null);
                        },
                      },
                      ...sedes.map((sede) => ({
                        name: sede.nombre,
                        icon: {
                          icon: selectedSede?.id === sede.id ? "location_on" : "location_city",
                          color:
                            selectedSede?.id === sede.id
                              ? getUproColor("uproPink")
                              : getUproColor("uproGreen"),
                        },
                        onClick: () => {
                          setSelectedSede(sede);
                        },
                      })),
                    ],
                    handleClose: () => {
                      setExpandSelector(false);
                    },
                  });
                }}
              >
                <Icon fontSize="small">apartment</Icon>
                &nbsp; {selectedSede?.nombre || "Todas las sedes"} &nbsp;
                <Icon
                  sx={{
                    rotate: expandSedes ? "180deg" : "0deg",
                    transition: "all 0.5s",
                  }}
                >
                  expand_more
                </Icon>
              </SoftButton>
            </SoftBox>
            <SoftBox display="flex" gap={2}>
              <Tooltip title="Fecha de inicio" placement="top">
                <SoftInput
                  value={dayjs(date.fechaInicio).format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setDate({
                      ...date,
                      fechaInicio: dayjs(event.target.value).startOf("day").format("YYYY-MM-DD"),
                    });
                  }}
                  type="date"
                  error={dayjs(date.fechaInicio).isAfter(dayjs(date.fechaFin))}
                  inputProps={{
                    max: dayjs(date.fechaFin).format("YYYY-MM-DD"),
                  }}
                />
              </Tooltip>
              <Tooltip title="Fecha de fin" placement="top">
                <SoftInput
                  value={dayjs(date.fechaFin).format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setDate({
                      ...date,
                      fechaFin: dayjs(event.target.value)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss"),
                    });
                  }}
                  type="date"
                  error={dayjs(date.fechaInicio).isAfter(dayjs(date.fechaFin))}
                  inputProps={{
                    min: dayjs(date.fechaInicio).format("YYYY-MM-DD"),
                  }}
                />
              </Tooltip>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{
                height: "100%",
              }}
            >
              <SoftBox p={3} pb={0}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">Resumen</SoftTypography>
                  <Icon fontSize="small" color="secondary">
                    checklist
                  </Icon>
                </SoftBox>
              </SoftBox>
              <SoftBox>
                <Grid
                  container
                  textAlign={"center"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  py={3}
                >
                  <Grid item xs={12}>
                    <Icon
                      sx={{
                        width: "50px",
                      }}
                    >
                      note_alt
                    </Icon>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6">Cantidad de inscriptos</SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h3" fontWeight="bold" color="primary" textGradient>
                      {formatMoneyPunto(reportes?.reporteDemografico?.totalInscripciones || 0)}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <Icon
                      sx={{
                        width: "50px",
                      }}
                    >
                      people
                    </Icon>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6">Promedio de edad</SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h3" fontWeight="bold" color="primary" textGradient>
                      {reportes?.reporteDemografico?.promedioEdad
                        ? `${reportes?.reporteDemografico?.promedioEdad} años`
                        : "Sin datos"}
                    </SoftTypography>
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Card>
              <SoftBox p={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">{`Cantidad de ${
                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                  } por genero`}</SoftTypography>
                  <SoftButton
                    size="small"
                    variant="gradient"
                    color="dark"
                    circular
                    iconOnly
                    onClick={(event) => {
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        options: [
                          {
                            name: "Descargar reporte",
                            icon: { icon: "download", color: getUproColor("uproGreen") },
                            onClick: () => {
                              let reporte = reportes?.reporteDemografico?.totalGeneros || [];
                              let reporteOrdenado = [...reporte].sort(
                                (a, b) => b.cantidad - a.cantidad
                              );

                              let reporteExportar = reporteOrdenado.map((item) => {
                                return tipo === "Preinscripto"
                                  ? {
                                      Genero: item.genero,
                                      "Cantidad de preinscriptos": item.cantidad,
                                    }
                                  : {
                                      Genero: item.genero,
                                      "Cantidad de inscriptos": item.cantidad,
                                    };
                              });
                              const cabecera = [
                                [
                                  `Cantidad de ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por genero - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte ${
                                  tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                } por genero - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            },
                          },
                        ],
                        handleClose: () => {},
                      });
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </SoftButton>
                </SoftBox>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      {reportes?.reporteDemografico?.totalGeneros?.map((item, index) => (
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          key={index}
                        >
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox mr={2}>
                              <SoftButton
                                size="small"
                                variant="color"
                                color={uproColors[index]}
                                circular
                                iconOnly
                              />
                            </SoftBox>
                            <SoftTypography variant="button" fontWeight="medium">
                              {`${item.genero} (${item.cantidad})`}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      ))}
                      <SoftBox pt={2}>
                        <SoftButton variant="gradient" color="uproGreen" circular>
                          {`Total ${formatMoneyPunto(
                            reportes?.reporteDemografico?.totalInscripciones || 0
                          )}`}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DoughnutChart
                      id={4}
                      title={`Cantidad de ${
                        tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                      } por genero`}
                      chart={{
                        labels: reportes?.reporteDemografico?.totalGeneros?.map(
                          (item) => item.genero
                        ),
                        datasets: {
                          backgroundColors: reportes?.reporteDemografico?.totalGeneros?.map(
                            (item, index) => uproColors[index]
                          ),
                          data: reportes?.reporteDemografico?.totalGeneros?.map(
                            (item) => item.cantidad
                          ),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <SoftBox p={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">{`${
                    tipo === "Preinscripto" ? "Preinscriptos" : "Inscriptos"
                  } por rango etario`}</SoftTypography>
                  <SoftButton
                    size="small"
                    variant="gradient"
                    color="dark"
                    circular
                    iconOnly
                    onClick={(event) => {
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        options: [
                          {
                            name: "Descargar reporte",
                            icon: { icon: "download", color: getUproColor("uproGreen") },
                            onClick: () => {
                              let reporte = reportes?.reporteDemografico?.totalRangoEdades || [];
                              let reporteOrdenado = [...reporte].sort(
                                (a, b) => b.cantidad - a.cantidad
                              );

                              let reporteExportar = reporteOrdenado.map((item) => {
                                return tipo === "Preinscripto"
                                  ? {
                                      "Rango etario": item.rangoEdad,
                                      "Cantidad de preinscriptos": item.cantidad,
                                    }
                                  : {
                                      "Rango etario": item.rangoEdad,
                                      "Cantidad de inscriptos": item.cantidad,
                                    };
                              });
                              const cabecera = [
                                [
                                  `Cantidad de ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por rango etario - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte ${
                                  tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                } por rango etario - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            },
                          },
                        ],
                        handleClose: () => {},
                      });
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </SoftButton>
                </SoftBox>
                <VerticalBarChart
                  chart={{
                    labels: reportes?.reporteDemografico?.totalRangoEdades?.map(
                      (item) => item.rangoEdad
                    ),
                    datasets: [
                      {
                        label: "Cantidad de inscriptos",
                        color: "uproGreen",
                        data: reportes?.reporteDemografico?.totalRangoEdades?.map(
                          (item) => item.cantidad
                        ),
                      },
                    ],
                  }}
                />
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <SoftBox p={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">{`${
                    tipo === "Preinscripto" ? "Preinscriptos" : "Inscriptos"
                  } según discapacidad`}</SoftTypography>
                  <SoftButton
                    size="small"
                    variant="gradient"
                    color="dark"
                    circular
                    iconOnly
                    onClick={(event) => {
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        options: [
                          {
                            name: "Descargar reporte",
                            icon: { icon: "download", color: getUproColor("uproGreen") },
                            onClick: () => {
                              let reporteExportar =
                                tipo === "Preinscripto"
                                  ? [
                                      {
                                        Tipo: "No presentan discapacidad",
                                        "Cantidad de preinscriptos":
                                          reportes?.reporteDemografico?.totalInscripciones -
                                          reportes?.reporteDemografico?.totalDiscapacitados,
                                      },
                                      {
                                        Tipo: "Presentan discapacidad",
                                        "Cantidad de preinscriptos":
                                          reportes?.reporteDemografico?.totalDiscapacitados,
                                      },
                                    ]
                                  : [
                                      {
                                        Tipo: "No presentan discapacidad",
                                        "Cantidad de inscriptos":
                                          reportes?.reporteDemografico?.totalInscripciones -
                                          reportes?.reporteDemografico?.totalDiscapacitados,
                                      },
                                      {
                                        Tipo: "Presentan discapacidad",
                                        "Cantidad de inscriptos":
                                          reportes?.reporteDemografico?.totalDiscapacitados,
                                      },
                                    ];

                              const cabecera = [
                                [
                                  `Cantidad de ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } según discapacidad - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte ${
                                  tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                } según discapacidad - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            },
                          },
                        ],
                        handleClose: () => {},
                      });
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </SoftButton>
                </SoftBox>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={2}>
                            <SoftButton
                              size="small"
                              variant="color"
                              color={uproColors[0]}
                              circular
                              iconOnly
                            />
                          </SoftBox>
                          <SoftTypography variant="button" fontWeight="medium">
                            {`Presentan discapacidad (${
                              reportes?.reporteDemografico?.totalDiscapacitados || 0
                            })`}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={2}>
                            <SoftButton
                              size="small"
                              variant="color"
                              color={uproColors[1]}
                              circular
                              iconOnly
                            />
                          </SoftBox>
                          <SoftTypography variant="button" fontWeight="medium">
                            {`No presentan discapacidad (${
                              reportes?.reporteDemografico?.totalInscripciones -
                                reportes?.reporteDemografico?.totalDiscapacitados || 0
                            })`}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox pt={2}>
                        <SoftButton variant="gradient" color="uproGreen" circular>
                          {`Total ${formatMoneyPunto(
                            reportes?.reporteDemografico?.totalInscripciones || 0
                          )}`}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PieChart
                      id={new Date().getTime()}
                      title={`${
                        tipo === "Preinscripto" ? "Preinscriptos" : "Inscriptos"
                      } según discapacidad`}
                      chart={{
                        labels: ["Presentan discapacidad", "No presentan discapacidad"],
                        datasets: {
                          backgroundColors: [uproColors[0], uproColors[1]],
                          data:
                            reportes?.reporteDemografico?.totalDiscapacitados !== undefined
                              ? [
                                  reportes?.reporteDemografico?.totalDiscapacitados,
                                  reportes?.reporteDemografico?.totalInscripciones -
                                    reportes?.reporteDemografico?.totalDiscapacitados || 0,
                                ]
                              : [0, 0],
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <SoftBox p={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">{`${
                    tipo === "Preinscripto" ? "Preinscriptos" : "Inscriptos"
                  } según beneficio social`}</SoftTypography>
                  <SoftButton
                    size="small"
                    variant="gradient"
                    color="dark"
                    circular
                    iconOnly
                    onClick={(event) => {
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        options: [
                          {
                            name: "Descargar reporte",
                            icon: { icon: "download", color: getUproColor("uproGreen") },
                            onClick: () => {
                              let reporteExportar =
                                tipo === "Preinscripto"
                                  ? [
                                      {
                                        Tipo: "No poseen beneficio social",
                                        "Cantidad de preinscriptos":
                                          reportes?.reporteDemografico?.totalInscripciones -
                                          reportes?.reporteDemografico?.totalBeneficiosSociales,
                                      },
                                      {
                                        Tipo: "Poseen beneficio social",
                                        "Cantidad de preinscriptos":
                                          reportes?.reporteDemografico?.totalBeneficiosSociales,
                                      },
                                    ]
                                  : [
                                      {
                                        Tipo: "No poseen beneficio social",
                                        "Cantidad de inscriptos":
                                          reportes?.reporteDemografico?.totalInscripciones -
                                          reportes?.reporteDemografico?.totalBeneficiosSociales,
                                      },
                                      {
                                        Tipo: "Poseen beneficio social",
                                        "Cantidad de inscriptos":
                                          reportes?.reporteDemografico?.totalBeneficiosSociales,
                                      },
                                    ];

                              const cabecera = [
                                [
                                  `Cantidad de ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } según beneficio social - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte ${
                                  tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                } según beneficio social - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            },
                          },
                        ],
                        handleClose: () => {},
                      });
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </SoftButton>
                </SoftBox>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={2}>
                            <SoftButton
                              size="small"
                              variant="color"
                              color={uproColors[0]}
                              circular
                              iconOnly
                            />
                          </SoftBox>
                          <SoftTypography variant="button" fontWeight="medium">
                            {`Poseen beneficio social (${
                              reportes?.reporteDemografico?.totalBeneficiosSociales || 0
                            })`}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={2}>
                            <SoftButton
                              size="small"
                              variant="color"
                              color={uproColors[1]}
                              circular
                              iconOnly
                            />
                          </SoftBox>
                          <SoftTypography variant="button" fontWeight="medium">
                            {`No poseen beneficio social (${
                              reportes?.reporteDemografico?.totalInscripciones -
                                reportes?.reporteDemografico?.totalBeneficiosSociales || 0
                            })`}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox pt={2}>
                        <SoftButton variant="gradient" color="uproGreen" circular>
                          {`Total ${formatMoneyPunto(
                            reportes?.reporteDemografico?.totalInscripciones || 0
                          )}`}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PieChart
                      id={new Date().getTime()}
                      title={`${
                        tipo === "Preinscripto" ? "Preinscriptos" : "Inscriptos"
                      } según beneficio social`}
                      chart={{
                        labels: ["Poseen beneficio social", "No poseen beneficio social"],
                        datasets: {
                          backgroundColors: [uproColors[0], uproColors[1]],
                          data:
                            reportes?.reporteDemografico?.totalBeneficiosSociales !== undefined
                              ? [
                                  reportes?.reporteDemografico?.totalBeneficiosSociales,
                                  reportes?.reporteDemografico?.totalInscripciones -
                                    reportes?.reporteDemografico?.totalBeneficiosSociales || 0,
                                ]
                              : [0, 0],
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

ReporteDemografico.defaultProps = {
  tipo: "Preinscripto",
  sedes: [],
  tipoCarrera: "Carrera",
};

ReporteDemografico.propTypes = {
  tipo: PropTypes.string,
  sedes: PropTypes.array,
  tipoCarrera: PropTypes.string,
};

export default ReporteDemografico;
