// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  Collapse,
  Fade,
  Grid,
  Icon,
  Modal,
  Slide,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";
import ModalComision from "../ModalComision";
import { makeNiceText } from "utils/formatters";
import { ConfirmarContext } from "context/ConfirmarContext";
import ModalSeleccionAlumno from "../ModalSeleccionAlumno";
import ModalInscribirExamen from "../ModalInscribirExamen";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalCopiarComision({ open, handleClose, comision, idMateria, idNivel }) {
  const [selectedMaterias, setSelectedMaterias] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);

  const [getNivel, { data: nivel, loading }] = useLazyQuery(
    gql`
      query getNivel($id: ID!) {
        nivel(id: $id) {
          id
          codigo
          nombre
          materias {
            id
            codigo
            nombre
            comisiones {
              id
              codigo
              nombre
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [copiarComision, { loading: loadingCopy }] = useMutation(
    gql`
      mutation copyComision($idComision: ID!, $idsMaterias: [ID!]!) {
        copyComision(idComision: $idComision, idsMaterias: $idsMaterias) {
          id
          codigo
          nombre
        }
      }
    `
  );

  useEffect(() => {
    setSelectedMaterias([]);
    if (open && comision?.nivel) {
      getNivel({
        variables: {
          id: idNivel,
        },
      });
    }
  }, [open, comision]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">{`Copiar comisión ${comision?.codigo} - ${comision?.nombre}`}</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pt={3}>
              <Collapse in={open}>
                <SoftBox>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproGreen"),
                            }}
                          >
                            book
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Seleccione las unidades de competencia donde copiar la comisión
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={open}>
                      <Grid container>
                        {loading ? (
                          <Grid item xs={12}>
                            <Loading />
                          </Grid>
                        ) : nivel?.nivel?.materias?.filter((materia) => materia.id !== idMateria)
                            .length === 0 ? (
                          <Grid item xs={12}>
                            <SinDatos />
                          </Grid>
                        ) : (
                          nivel?.nivel?.materias
                            ?.filter((materia) => materia.id !== idMateria)
                            .map((materia, index) => (
                              <Grid item xs={12} key={materia.id}>
                                <SoftBox
                                  px={2}
                                  py={1}
                                  sx={{
                                    borderBottom:
                                      nivel?.nivel?.materias?.length - 1 === index
                                        ? "none"
                                        : "1px solid rgba(0, 0, 0, 0.12)",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  <SoftBox display="flex" justifyContent="space-between">
                                    <SoftBox display="flex" alignItems="center" pl={2}>
                                      <SoftBox mr={1}>
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproYellow"),
                                          }}
                                        >
                                          subdirectory_arrow_right
                                        </Icon>
                                      </SoftBox>
                                      <SoftBox mr={1}>
                                        <Tooltip title="Unidad de competencia" placement="top">
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproGreen"),
                                            }}
                                          >
                                            book
                                          </Icon>
                                        </Tooltip>
                                      </SoftBox>
                                      <SoftTypography variant="h6">{`${materia.codigo} - ${materia.nombre}`}</SoftTypography>
                                      {materia.comisiones?.find(
                                        (c) =>
                                          c.codigo === comision.codigo &&
                                          c.nombre === comision.nombre
                                      ) && (
                                        <SoftBox ml={1}>
                                          <SoftBadge
                                            color="primary"
                                            badgeContent="La comisión ya existe"
                                          />
                                        </SoftBox>
                                      )}
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      justifyContent="flex-end"
                                      alignItems="center"
                                    >
                                      <SoftBox>
                                        <Tooltip
                                          title={
                                            selectedMaterias.find((m) => m.id === materia.id)
                                              ? "Deseleccionar"
                                              : "Seleccionar"
                                          }
                                          placement="top"
                                        >
                                          <SoftButton
                                            color={
                                              selectedMaterias.find((m) => m.id === materia.id)
                                                ? "uproGreen"
                                                : "primary"
                                            }
                                            circular
                                            iconOnly
                                            disabled={materia.comisiones?.find(
                                              (c) =>
                                                c.codigo === comision.codigo &&
                                                c.nombre === comision.nombre
                                            ) || loadingCopy || loading}
                                            onClick={() => {
                                              if (
                                                selectedMaterias.find((m) => m.id === materia.id)
                                              ) {
                                                setSelectedMaterias((prev) =>
                                                  prev.filter((m) => m.id !== materia.id)
                                                );
                                              } else {
                                                setSelectedMaterias((prev) => [...prev, materia]);
                                              }
                                            }}
                                          >
                                            <Icon>
                                              {selectedMaterias.find((m) => m.id === materia.id)
                                                ? "check"
                                                : "close"}
                                            </Icon>
                                          </SoftButton>
                                        </Tooltip>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                            ))
                        )}
                      </Grid>
                    </Collapse>
                  </Card>
                </SoftBox>
                <SoftBox display="flex" justifyContent="flex-end" pt={2} gap={2}>
                  <SoftButton
                    color="primary"
                    onClick={() => {
                      handleClose();
                    }}
                    circular
                  >
                    <Icon>cancel</Icon>
                    &nbsp; Cancelar
                  </SoftButton>
                  <Slide
                    direction="left"
                    in={selectedMaterias.length > 0}
                    mountOnEnter
                    unmountOnExit
                  >
                    <SoftButton
                      color="uproGreen"
                      onClick={() => {
                        copiarComision({
                          variables: {
                            idComision: comision.id,
                            idsMaterias: selectedMaterias.map((m) => m.id),
                          },
                        })
                          .then((res) => {
                            handleSnackbar(
                              `Comisión ${comision.codigo} - ${comision.nombre} copiada a ${res.data.copyComision.length} unidades de competencia`,
                              "success"
                            );
                            handleClose();
                          })
                          .catch((err) => {
                            handleSnackbar(err.message || "Error al copiar comisión", "error");
                          });
                      }}
                      circular
                    >
                      Copiar comisión a {selectedMaterias.length} unidades de competencia&nbsp;
                      <Icon>arrow_forward</Icon>
                    </SoftButton>
                  </Slide>
                </SoftBox>
              </Collapse>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCopiarComision.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  comision: PropTypes.object,
  idMateria: PropTypes.string,
  idNivel: PropTypes.string,
};
