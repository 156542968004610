// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Collapse, Tab, Tabs } from "@mui/material";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import { validatePhone } from "utils/validations";
import Loading from "components/Carteles/Loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalCuestionario({ open, handleClose, refetch, selectedCuestionario }) {
  const [cuestionario, setCuestionario] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const [getCuestionario, { loading: loadingCuestionario }] = useLazyQuery(
    gql`
      query getCuestionario($id: ID!) {
        cuestionario(id: $id) {
          id
          nombre
          descripcion
          fechaLimite
          obligatorio
          preguntas {
            id
            titulo
            tipo
            opciones
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const handleChanges = (e) => {
    setCuestionario({
      ...cuestionario,
      [e.target.name]: e.target.value,
    });
  };

  const [saveCuestionario, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveCuestionario($input: CuestionarioInput!) {
        saveCuestionario(input: $input) {
          id
        }
      }
    `
  );

  useEffect(() => {
    if (selectedCuestionario && open) {
      getCuestionario({
        variables: {
          id: selectedCuestionario?.id,
        },
      })
        .then((res) => {
          setCuestionario({
            ...res.data.cuestionario,
            obligatorio: res.data.cuestionario?.obligatorio ? 1 : 0,
            preguntas: res.data.cuestionario?.preguntas.map((pregunta) => ({
              ...pregunta,
              opciones: pregunta.opciones.map((opcion) => ({
                opcion: opcion.opcion,
              })),
            })),
          });
        })
        .catch((e) => {
          handleSnackbar("Error al obtener el cuestionario", "error");
        });
    } else {
      setCuestionario({
        preguntas: [],
      });
    }
  }, [selectedCuestionario, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedCuestionario ? `Editar cuestionario` : `Agregar cuestionario`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  {loadingCuestionario ? (
                    <Skeleton variant="text" width="100%" height={40} />
                  ) : (
                    <SoftInput
                      label="nombre"
                      placeholder="Ingrese el nombre del cuestionario"
                      type="text"
                      name="nombre"
                      value={cuestionario?.nombre || ""}
                      onChange={handleChanges}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="descripcion">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Descripción
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  {loadingCuestionario ? (
                    <Skeleton variant="text" width="100%" height={40} />
                  ) : (
                    <SoftInput
                      label="descripcion"
                      placeholder="Ingrese una descripción"
                      multiline
                      rows={4}
                      type="text"
                      name="descripcion"
                      value={cuestionario?.descripcion || ""}
                      onChange={handleChanges}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fechaLimite">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha límite
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  {loadingCuestionario ? (
                    <Skeleton variant="text" width="100%" height={40} />
                  ) : (
                    <SoftInput
                      label="fechaLimite"
                      type="date"
                      name="fechaLimite"
                      value={cuestionario?.fechaLimite || ""}
                      onChange={handleChanges}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="obligatorio">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Es obligatorio?
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  {loadingCuestionario ? (
                    <Skeleton variant="text" width="100%" height={40} />
                  ) : (
                    <Tabs
                      value={cuestionario?.obligatorio || 0}
                      name="obligatorio"
                      onChange={(e, value) => {
                        setCuestionario({
                          ...cuestionario,
                          obligatorio: value,
                        });
                      }}
                    >
                      <Tab
                        label={
                          <SoftTypography
                            variant="h6"
                            fontWeight={cuestionario?.obligatorio !== 1 ? "bold" : "regular"}
                          >
                            No
                          </SoftTypography>
                        }
                        value={0}
                      />
                      <Tab
                        label={
                          <SoftTypography
                            variant="h6"
                            fontWeight={cuestionario?.obligatorio === 1 ? "bold" : "regular"}
                          >
                            Sí
                          </SoftTypography>
                        }
                        value={1}
                      />
                    </Tabs>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6">Preguntas</SoftTypography>
                  </SoftBox>
                  <Collapse in={!loadingCuestionario}>
                    <SoftBox>
                      {cuestionario?.preguntas?.length > 0 ? (
                        cuestionario?.preguntas?.map((pregunta, index) => (
                          <SoftBox key={index} mb={2}>
                            <Grid item xs={12}>
                              <Card sx={{ border: "0.0625rem solid #d2d6da" }}>
                                <SoftBox>
                                  <SoftBox display="flex" justifyContent="space-between">
                                    <Tooltip title="Mantener para arrastrar" placement="top">
                                      <SoftBox
                                        display="flex"
                                        alignItems="center"
                                        p={2}
                                        sx={{
                                          backgroundColor: getUproColor("uproPink"),
                                          cursor: "pointer",
                                        }}
                                      >
                                        <SoftTypography variant="h6" color="white">
                                          {index + 1}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Tooltip>
                                    <Grid container spacing={2} p={3}>
                                      <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <SoftBox sx={{ width: "100%" }} mr={2}>
                                          <SoftInput
                                            label="titulo"
                                            placeholder="Ingrese una pregunta"
                                            type="text"
                                            name="titulo"
                                            icon={{
                                              component: "question_mark",
                                              direction: "left",
                                            }}
                                            value={pregunta.titulo || ""}
                                            onChange={(e) => {
                                              const updatedPreguntas = [...cuestionario.preguntas];
                                              updatedPreguntas[index].titulo = e.target.value;
                                              setCuestionario({
                                                ...cuestionario,
                                                preguntas: updatedPreguntas,
                                              });
                                            }}
                                          />
                                        </SoftBox>
                                        <SoftBox mr={2}>
                                          <Select
                                            label="tipoPregunta"
                                            value={pregunta.tipo || ""}
                                            onChange={(e) => {
                                              const updatedPreguntas = [...cuestionario.preguntas];
                                              if (e.target.value === "multipleChoice") {
                                                updatedPreguntas[index].opciones = [
                                                  {
                                                    opcion: "",
                                                  },
                                                ];
                                              } else {
                                                updatedPreguntas[index].opciones = [];
                                              }
                                              updatedPreguntas[index].tipo = e.target.value;
                                              setCuestionario({
                                                ...cuestionario,
                                                preguntas: updatedPreguntas,
                                              });
                                            }}
                                          >
                                            <MenuItem value="multipleChoice">
                                              Multiple choice
                                            </MenuItem>
                                            <MenuItem value="desarrollar">A desarrollar</MenuItem>
                                          </Select>
                                        </SoftBox>
                                        <SoftBox display="flex" justifyContent="flex-end" gap={1}>
                                          <Tooltip placement="top" title="Agregar opción">
                                            <SoftButton
                                              color="uproYellow"
                                              circular
                                              iconOnly
                                              disabled={pregunta.tipo === "desarrollar"}
                                              onClick={() => {
                                                const updatedPreguntas = [
                                                  ...cuestionario.preguntas,
                                                ];
                                                updatedPreguntas[index].opciones.push({
                                                  opcion: "",
                                                });
                                                setCuestionario({
                                                  ...cuestionario,
                                                  preguntas: updatedPreguntas,
                                                });
                                              }}
                                            >
                                              <Icon>add</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                          <Tooltip
                                            placement="top"
                                            title="Eliminar pregunta"
                                            onClick={() => {
                                              const updatedPreguntas = [...cuestionario.preguntas];
                                              updatedPreguntas.splice(index, 1);
                                              setCuestionario({
                                                ...cuestionario,
                                                preguntas: updatedPreguntas,
                                              });
                                            }}
                                          >
                                            <SoftButton color="primary" circular iconOnly>
                                              <Icon>delete</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      </Grid>
                                      {pregunta.tipo === "multipleChoice" &&
                                        pregunta.opciones.map((opcion, opcionIndex) => (
                                          <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            key={opcionIndex}
                                          >
                                            <SoftBox mr={1}>
                                              <Icon>subdirectory_arrow_right</Icon>
                                            </SoftBox>
                                            <SoftBox sx={{ width: "100%" }} mr={2}>
                                              <SoftInput
                                                label="opcion"
                                                placeholder="Ingrese una opcion"
                                                type="text"
                                                name="opcion"
                                                value={opcion.opcion || ""}
                                                onChange={(e) => {
                                                  const updatedPreguntas = [
                                                    ...cuestionario.preguntas,
                                                  ];
                                                  updatedPreguntas[index].opciones[
                                                    opcionIndex
                                                  ].opcion = e.target.value;
                                                  setCuestionario({
                                                    ...cuestionario,
                                                    preguntas: updatedPreguntas,
                                                  });
                                                }}
                                              />
                                            </SoftBox>
                                            <SoftBox
                                              display="flex"
                                              justifyContent="flex-end"
                                              gap={1}
                                            >
                                              <Tooltip placement="top" title="Eliminar opción">
                                                <SoftButton
                                                  color="primary"
                                                  circular
                                                  iconOnly
                                                  onClick={() => {
                                                    const updatedPreguntas = [
                                                      ...cuestionario.preguntas,
                                                    ];
                                                    updatedPreguntas[index].opciones.splice(
                                                      opcionIndex,
                                                      1
                                                    );
                                                    setCuestionario({
                                                      ...cuestionario,
                                                      preguntas: updatedPreguntas,
                                                    });
                                                  }}
                                                  disabled={pregunta.opciones.length === 1}
                                                >
                                                  <Icon>delete</Icon>
                                                </SoftButton>
                                              </Tooltip>
                                            </SoftBox>
                                          </Grid>
                                        ))}
                                    </Grid>
                                  </SoftBox>
                                </SoftBox>
                              </Card>
                            </Grid>
                          </SoftBox>
                        ))
                      ) : (
                        <SoftBox display="flex" flexDirection="column" alignItems="center">
                          <SinDatos />
                          <SoftButton
                            color="uproGreen"
                            circular
                            onClick={() => {
                              setCuestionario({
                                ...cuestionario,
                                preguntas: [
                                  ...cuestionario.preguntas,
                                  {
                                    titulo: "",
                                    tipo: "multipleChoice",
                                    opciones: [
                                      {
                                        opcion: "",
                                      },
                                    ],
                                  },
                                ],
                              });
                            }}
                          >
                            Agregar pregunta
                          </SoftButton>
                        </SoftBox>
                      )}
                      <Collapse in={cuestionario?.preguntas?.length > 0}>
                        <SoftButton
                          color="uproGreen"
                          circular
                          fullWidth
                          onClick={() => {
                            setCuestionario({
                              ...cuestionario,
                              preguntas: [
                                ...cuestionario.preguntas,
                                {
                                  titulo: "",
                                  tipo: "multipleChoice",
                                  opciones: [
                                    {
                                      opcion: "",
                                    },
                                  ],
                                },
                              ],
                            });
                          }}
                        >
                          Agregar pregunta
                          <Icon>add</Icon>
                        </SoftButton>
                      </Collapse>
                    </SoftBox>
                  </Collapse>
                  <Collapse in={loadingCuestionario}>
                    <Loading />
                  </Collapse>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loadingSave}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  disabled={
                    loadingSave ||
                    !cuestionario?.nombre ||
                    cuestionario?.nombre === "" ||
                    cuestionario?.preguntas?.length === 0 ||
                    !cuestionario?.descripcion ||
                    cuestionario?.descripcion === "" ||
                    !cuestionario?.fechaLimite ||
                    cuestionario?.fechaLimite === "" ||
                    cuestionario?.preguntas?.some(
                      (pregunta) =>
                        pregunta.tipo === "multipleChoice" &&
                        pregunta.opciones?.some((opcion) => opcion.opcion === "")
                    ) ||
                    cuestionario?.preguntas?.some(
                      (pregunta) => pregunta.titulo === "" || pregunta.titulo === null
                    )
                  }
                  onClick={async () => {
                    saveCuestionario({
                      variables: {
                        input: {
                          id: cuestionario?.id,
                          nombre: cuestionario?.nombre,
                          descripcion: cuestionario?.descripcion,
                          fechaLimite: cuestionario?.fechaLimite,
                          obligatorio: cuestionario?.obligatorio === 1,
                          preguntas: cuestionario?.preguntas?.map((pregunta) => ({
                            id: pregunta.id,
                            titulo: pregunta.titulo,
                            tipo: pregunta.tipo,
                            opciones: pregunta.opciones.map((opcion) => ({
                              opcion: opcion.opcion,
                            })),
                          })),
                        },
                      },
                    })
                      .then(() => {
                        refetch();
                        handleSnackbar("Cuestionario guardado correctamente", "success");
                        handleClose();
                      })
                      .catch((e) => {
                        handleSnackbar("Error al guardar el cuestionario", "error");
                      });
                  }}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  {loadingSave ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCuestionario.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedCuestionario: PropTypes.object,
};
