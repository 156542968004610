import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  Card,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Modal,
  Skeleton,
  Tooltip,
} from "@mui/material";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import ModalImage from "components/Modals/ModalImage";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import JSZip from "jszip";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflowY: "auto",
};

export default function ModalRevision({ open, handleClose, idInscripcion, refetch, readOnly }) {
  const [inscripcion, setInscripcion] = useState(null);
  const [scores, setScores] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  const [files, setFiles] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);
  const { menu } = useContext(MenuContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const { confirmar } = useContext(ConfirmarContext);
  const [getInscripcion, { loading }] = useLazyQuery(
    gql`
      query getInscripcion($id: ID!) {
        inscripcionAlumno(id: $id) {
          id
          fechaInicio
          fechaFin
          documentos {
            id
            nombre
            file
            createdAt
          }
          examen {
            id
            titulo
            preguntas(idInscripcion: $id) {
              id
              titulo
              tipo
              puntaje
              archivo
              opciones {
                opcion
                correcta
              }
            }
          }
          alumno {
            id
            nombre
            apellido
          }
          respuestas {
            id
            puntajeDocente
            pregunta {
              id
            }
            respuesta
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [saveCorreccionDocente, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveCorreccionDocente($input: CorreccionInput!) {
        saveCorreccionDocente(input: $input)
      }
    `
  );

  useEffect(() => {
    if (idInscripcion && open) {
      getInscripcion({ variables: { id: idInscripcion } })
        .then((res) => {
          setInscripcion(res.data.inscripcionAlumno);
          setFiles(res.data.inscripcionAlumno.documentos);
          const initialScores = {};
          let initialTotalScore = 0;
          res.data.inscripcionAlumno.examen.preguntas.forEach((pregunta) => {
            if (pregunta.tipo !== "multipleChoice") {
              initialScores[pregunta.id] = 0;
              const respuesta = res.data.inscripcionAlumno.respuestas.find(
                (r) => r.pregunta.id === pregunta.id
              );
              if (respuesta?.puntajeDocente) {
                initialScores[pregunta.id] = respuesta.puntajeDocente;
                initialTotalScore += respuesta.puntajeDocente;
              }
            } else {
              const respuesta = res.data.inscripcionAlumno.respuestas.find(
                (r) => r.pregunta.id === pregunta.id
              );
              const correcta = pregunta.opciones.find((o) => o.correcta);
              const score = respuesta?.puntajeDocente
                ? respuesta.puntajeDocente
                : respuesta?.respuesta === correcta?.opcion
                ? pregunta.puntaje
                : 0;
              initialScores[pregunta.id] = score;
              initialTotalScore += score;
            }
          });
          setScores(initialScores);
          setTotalScore(Math.min(initialTotalScore, 100));
        })
        .catch((err) => {
          handleSnackbar(err.message || "Error al obtener la inscripción", "error");
        });
    } else {
      setScores({});
      setTotalScore(0);
    }
  }, [idInscripcion, open, getInscripcion, handleSnackbar]);

  const handleScoreChange = (preguntaId, value) => {
    const pregunta = inscripcion.examen.preguntas.find((p) => p.id === preguntaId);
    const maxScore = pregunta.puntaje;
    const newValue = Math.max(0, Math.min(value, maxScore));

    setScores((prevScores) => {
      const newScores = {
        ...prevScores,
        [preguntaId]: newValue,
      };

      const total = Object.values(newScores).reduce(
        (acc, score) => acc + parseFloat(score || 0),
        0
      );
      const boundedTotal = Math.max(0, Math.min(total, 100));
      setTotalScore(boundedTotal);

      return newScores;
    });
  };

  const maxScore =
    inscripcion?.examen?.preguntas?.reduce((acc, pregunta) => acc + pregunta.puntaje, 0) || 0;
  const scorePercentage = (totalScore / maxScore) * 100;

  const handleDownloadAll = async () => {
    const zip = new JSZip();

    for (let file of files) {
      const res = await fetch(`${API_URL}/${file.file}`);
      let filename = file.nombre + " - " + dayjs(file.createdAt).format("DD-MM-YYYY") + ".pdf";
      const blob = await res.blob();
      zip.file(filename, blob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(
        content,
        `Documentación - ${inscripcion?.alumno?.apellido} ${
          inscripcion?.alumno?.nombre
        } - ${dayjs().format("DD-MM-YYYY")}.zip`
      );
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftTypography variant="h6">{`Revisar examen de ${makeNiceText(
                      `${inscripcion?.alumno?.apellido} ${inscripcion?.alumno?.nombre}`,
                      "nombre"
                    )}`}</SoftTypography>
                    <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Tooltip title="Cerrar" placement="top">
                          <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                            close
                          </Icon>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  container
                  spacing={2}
                  sx={{
                    height: "fit-content",
                  }}
                >
                  <Grid item xs={12}>
                    <Card>
                      <SoftBox p={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={8} container spacing={2}>
                            <Grid item xs={12}>
                              <InputLabel htmlFor="calificacion">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Calificación
                                </SoftTypography>
                              </InputLabel>
                              <SoftBadge
                                color={scorePercentage >= 70 ? "uproGreen" : "primary"}
                                badgeContent={
                                  scorePercentage >= 70
                                    ? "Aprobado"
                                    : inscripcion?.fechaInicio
                                    ? "Desaprobado"
                                    : "Ausente"
                                }
                                container
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <InputLabel htmlFor="alumno">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Alumno
                                </SoftTypography>
                              </InputLabel>
                              <SoftTypography variant="h6">
                                {`${inscripcion?.alumno?.apellido} ${inscripcion?.alumno?.nombre}`}
                              </SoftTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <InputLabel htmlFor="nombre">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Examen
                                </SoftTypography>
                              </InputLabel>
                              <SoftTypography variant="h6">
                                {inscripcion?.examen?.titulo}
                              </SoftTypography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={4} justifyContent="center">
                            <SoftBox
                              sx={{
                                width: "100%",
                              }}
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <SoftBox position="relative" display="flex" justifyContent="center">
                                <CircularProgress
                                  variant="determinate"
                                  sx={(theme) => ({
                                    color: theme.palette.grey[200],
                                    ...theme.applyStyles("dark", {
                                      color: theme.palette.grey[800],
                                    }),
                                  })}
                                  size={100}
                                  thickness={4}
                                  value={100}
                                />
                                <CircularProgress
                                  variant="determinate"
                                  disableShrink
                                  sx={(theme) => ({
                                    color:
                                      scorePercentage >= 70
                                        ? getUproColor("uproGreen")
                                        : getUproColor("uproPink"),
                                    animationDuration: "550ms",
                                    position: "absolute",
                                    [`& .${circularProgressClasses.circle}`]: {
                                      strokeLinecap: "round",
                                    },
                                  })}
                                  value={scorePercentage}
                                  size={100}
                                  thickness={4}
                                />
                                <SoftBox
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <SoftTypography variant="h6">
                                    {loading ? (
                                      <Skeleton variant="circular" width={20} height={20} />
                                    ) : (
                                      `${Math.round(scorePercentage)}`
                                    )}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="inicio">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Fecha de inicio
                              </SoftTypography>
                            </InputLabel>
                            <SoftTypography variant="h6">
                              {inscripcion?.fechaInicio
                                ? dayjs(inscripcion?.fechaInicio).format("DD/MM/YYYY HH:mm")
                                : "Sin comenzar"}
                            </SoftTypography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="fin">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Fecha de fin
                              </SoftTypography>
                            </InputLabel>
                            <SoftTypography variant="h6">
                              {inscripcion?.fechaFin
                                ? dayjs(inscripcion?.fechaFin).format("DD/MM/YYYY HH:mm")
                                : "Sin finalizar"}
                            </SoftTypography>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              border: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                          >
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              px={2}
                              py={1}
                              sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={1} display="flex" alignItems="center">
                                  <Icon color="primary">description</Icon>
                                </SoftBox>
                                <SoftTypography variant="h6" fontWeight="bold">
                                  {`Documentación adjunta`}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                                <SoftBox mr={readOnly ? 0 : 1}>
                                  <Tooltip placement="top" title="Descargar todos">
                                    <SoftButton
                                      color="primary"
                                      iconOnly
                                      circular
                                      onClick={handleDownloadAll}
                                      disabled={files.length === 0}
                                    >
                                      <Icon sx={{ fontWeight: "regular" }}>download</Icon>
                                    </SoftButton>
                                  </Tooltip>
                                </SoftBox>
                                {!readOnly && (
                                  <SoftBox>
                                    <Tooltip placement="top" title="Agregar nuevo">
                                      <SoftButton
                                        color="uproGreen"
                                        iconOnly
                                        circular
                                        onClick={() => {
                                          setOpenModalAddDocumentacion(true);
                                        }}
                                      >
                                        <Icon sx={{ fontWeight: "regular" }}>add</Icon>
                                      </SoftButton>
                                    </Tooltip>
                                  </SoftBox>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <Collapse in={true}>
                              {loading ? (
                                <Loading />
                              ) : files.length > 0 ? (
                                files.map((documento, index) => (
                                  <SoftBox key={documento.id}>
                                    <SoftBox
                                      px={2}
                                      py={1}
                                      sx={{
                                        borderBottom:
                                          files.length - 1 === index
                                            ? "none"
                                            : "1px solid rgba(0, 0, 0, 0.12)",
                                        cursor: "pointer",
                                        "&:hover": {
                                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                                        },
                                      }}
                                    >
                                      <SoftBox display="flex" justifyContent="space-between">
                                        <SoftBox display="flex" alignItems="center" pl={2}>
                                          <SoftBox mr={1}>
                                            <Icon
                                              sx={{
                                                color: getUproColor("uproPink"),
                                              }}
                                            >
                                              subdirectory_arrow_right
                                            </Icon>
                                          </SoftBox>
                                          <SoftBox mr={1}>
                                            <Tooltip title={documento?.nombre} placement="top">
                                              <Icon
                                                sx={{
                                                  color: getUproColor("uproYellow"),
                                                }}
                                              >
                                                file_present
                                              </Icon>
                                            </Tooltip>
                                          </SoftBox>
                                          <SoftTypography variant="h6">
                                            {documento.nombre}
                                          </SoftTypography>
                                        </SoftBox>
                                        <SoftBox
                                          display="flex"
                                          justifyContent="flex-end"
                                          alignItems="center"
                                        >
                                          <SoftBox>
                                            <Tooltip title={"Opciones"} placement="top">
                                              <SoftButton
                                                color="uproYellow"
                                                circular
                                                iconOnly
                                                onClick={(event) => {
                                                  menu({
                                                    open: event.currentTarget,
                                                    align: "right",
                                                    options: [
                                                      {
                                                        name: "Descargar",
                                                        icon: {
                                                          icon: "file_download",
                                                          color: "info",
                                                        },
                                                        onClick: async () => {
                                                          const file = await fetch(
                                                            `${API_URL}/${documento.file}`
                                                          );
                                                          const blob = await file.blob();
                                                          saveAs(blob, documento.nombre);
                                                        },
                                                      },
                                                    ],
                                                    handleClose: () => {},
                                                  });
                                                }}
                                              >
                                                <Icon>more_vert</Icon>
                                              </SoftButton>
                                            </Tooltip>
                                          </SoftBox>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                ))
                              ) : (
                                <Grid container spacing={2} p={2}>
                                  <SinDatos />
                                </Grid>
                              )}
                            </Collapse>
                          </Card>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Card
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                    }}
                  >
                    <Grid container spacing={3} p={3}>
                      {inscripcion?.examen?.preguntas?.map((pregunta, index) => (
                        <Grid item xs={12} key={index}>
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <SoftTypography variant="h6" color="primary">{`Pregunta Nº${
                              index + 1
                            }`}</SoftTypography>
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox display="flex" alignItems="center">
                                {!readOnly &&
                                  !inscripcion?.respuestas?.find(
                                    (respuesta) => respuesta?.pregunta?.id === pregunta?.id
                                  ) && (
                                    <SoftBox>
                                      <Icon>grade</Icon>
                                    </SoftBox>
                                  )}
                                {!readOnly &&
                                  inscripcion?.respuestas?.find(
                                    (respuesta) => respuesta?.pregunta?.id === pregunta?.id
                                  ) && (
                                    <SoftInputNumber
                                      label="puntaje"
                                      placeholder="Puntaje"
                                      name="puntaje"
                                      icon={{
                                        component: "grade",
                                        direction: "left",
                                      }}
                                      sx={{ width: "100px !important" }}
                                      value={scores[pregunta.id] || ""}
                                      onChange={(e) =>
                                        handleScoreChange(pregunta.id, e.target.value)
                                      }
                                      error={scores[pregunta.id] > pregunta.puntaje}
                                    />
                                  )}

                                <SoftTypography variant="h6" ml={1}>
                                  {(() => {
                                    const respuesta = inscripcion?.respuestas?.find(
                                      (respuesta) => respuesta?.pregunta?.id === pregunta?.id
                                    );
                                    if (!respuesta) return "0";
                                    if (respuesta?.puntajeDocente) return respuesta.puntajeDocente;
                                    const esCorrecta =
                                      respuesta?.respuesta ===
                                      pregunta?.opciones?.find((opcion) => opcion.correcta)?.opcion;
                                    return esCorrecta ? pregunta?.puntaje : 0;
                                  })()}
                                  / {pregunta?.puntaje} pts
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                          {pregunta?.archivo && (
                            <SoftBox mt={2} display="flex" alignItems="center">
                              <Tooltip title="Click para ver en pantalla completa" placement="top">
                                <SoftBox
                                  component="img"
                                  src={`${API_URL}/${pregunta?.archivo}`}
                                  alt={pregunta?.titulo}
                                  borderRadius="lg"
                                  onClick={() =>
                                    setSelectedImage(`${API_URL}/${pregunta?.archivo}`)
                                  }
                                  sx={{
                                    width: "100%",
                                    "&:hover": {
                                      cursor: "pointer",
                                      opacity: 0.8,
                                    },
                                  }}
                                />
                              </Tooltip>
                            </SoftBox>
                          )}
                          <SoftBox mt={2} display="flex" alignItems="center">
                            <SoftTypography variant="h5">{pregunta?.titulo}</SoftTypography>
                          </SoftBox>
                          <SoftBox mt={2}>
                            {pregunta?.tipo === "multipleChoice" ? (
                              pregunta?.opciones?.map((opcion, index) => (
                                <SoftBox mt={2} key={index}>
                                  <Card>
                                    <SoftBox
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      p={2}
                                    >
                                      <SoftBox display="flex" alignItems="center">
                                        <SoftTypography variant="h6" mr={1}>
                                          {opcion.opcion}
                                        </SoftTypography>

                                        {opcion.correcta && (
                                          <Tooltip title="Respuesta correcta" placement="top">
                                            <Icon
                                              sx={{
                                                color: getUproColor("uproGreen"),
                                              }}
                                            >
                                              check_circle
                                            </Icon>
                                          </Tooltip>
                                        )}
                                      </SoftBox>
                                      {inscripcion?.respuestas?.find(
                                        (respuesta) => respuesta?.pregunta?.id === pregunta?.id
                                      )?.respuesta === opcion.opcion && (
                                        <Tooltip title="Respuesta seleccionada" placement="top">
                                          <SoftButton
                                            color={opcion.correcta ? "uproGreen" : "primary"}
                                            iconOnly
                                            circular
                                          >
                                            <Icon>{opcion.correcta ? "done" : "close"}</Icon>
                                          </SoftButton>
                                        </Tooltip>
                                      )}
                                    </SoftBox>
                                  </Card>
                                </SoftBox>
                              ))
                            ) : (
                              <SoftBox>
                                <InputLabel htmlFor="respuesta">
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Respuesta
                                  </SoftTypography>
                                </InputLabel>
                                <SoftTypography variant="h6">
                                  {inscripcion?.respuestas?.find(
                                    (respuesta) => respuesta?.pregunta?.id === pregunta?.id
                                  )?.respuesta || "Sin respuesta"}
                                </SoftTypography>
                              </SoftBox>
                            )}
                          </SoftBox>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                  <SoftBox display="flex" justifyContent="flex-end" mt={2}>
                    <SoftBox mr={readOnly ? 0 : 2}>
                      <SoftButton color="primary" onClick={handleClose} circular>
                        <Icon>close</Icon>
                        &nbsp; Cancelar
                      </SoftButton>
                    </SoftBox>
                    {!readOnly && (
                      <SoftBox>
                        <SoftButton
                          color="uproGreen"
                          disabled={loadingSave}
                          onClick={() => {
                            confirmar({
                              title: "Guardar corrección",
                              message: `¿Está seguro que desea guardar la corrección del examen de ${makeNiceText(
                                `${inscripcion?.alumno?.apellido} ${inscripcion?.alumno?.nombre}`,
                                "nombre"
                              )}?`,
                              icon: "warning",
                              func: () => {
                                saveCorreccionDocente({
                                  variables: {
                                    input: {
                                      respuestas: inscripcion?.respuestas.map((respuesta) => ({
                                        idRespuesta: respuesta.id,
                                        puntajeDocente: scores[respuesta.pregunta.id],
                                      })),
                                    },
                                  },
                                })
                                  .then(() => {
                                    handleSnackbar("Corrección guardada con éxito", "success");
                                    refetch();
                                    handleClose();
                                  })
                                  .catch((err) => {
                                    handleSnackbar(
                                      err.message || "Error al guardar la corrección",
                                      "error"
                                    );
                                  });
                              },
                            });
                          }}
                          circular
                        >
                          <Icon>save</Icon>
                          &nbsp; Guardar
                        </SoftButton>
                      </SoftBox>
                    )}
                  </SoftBox>
                </Grid>
              </Grid>
            </Card>
          </SoftBox>
          <ModalImage
            open={selectedImage !== null}
            handleClose={() => setSelectedImage(null)}
            src={selectedImage}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalRevision.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idInscripcion: PropTypes.number,
  refetch: PropTypes.func,
  readOnly: PropTypes.bool,
};
