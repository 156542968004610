import PropTypes from "prop-types";
import {
  Card,
  Divider,
  Icon,
  Skeleton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material/";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { API_URL } from "services/config";
import SoftButton from "components/SoftButton";
import FolderIcon from "@mui/icons-material/Folder";
import { getUproColor } from "utils/colors";

function ProfileCard({ color, usuario, loading, handleAddPedido, deshabilitar }) {
  return (
    <Card>
      <SoftBox p={2} display="flex" justifyContent="center">
        {loading ? (
          <Skeleton>
            <SoftBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor={color}
              color="white"
              width="8rem"
              height="8rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            ></SoftBox>
          </Skeleton>
        ) : (
          <SoftBox display="flex" justifyContent="center" alignItems="center" py={2}>
            <List dense={false}>
              <ListItem sx={{ mb: 1 }}>
                <ListItemIcon>
                  <Icon
                    sx={{
                      color: getUproColor("uproYellow"),
                    }}
                  >
                    stars
                  </Icon>
                </ListItemIcon>
                <SoftTypography variant="button" fontWeight="medium">
                  Solo se pueden realizar{" "}
                  <SoftTypography variant="button" fontWeight="bold" color="primary">
                    dos (2) pedidos por dia, uno (1) por la mañana y uno (1) por la tarde
                  </SoftTypography>
                </SoftTypography>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ mb: 1 }}>
                <ListItemIcon>
                  <Icon
                    sx={{
                      color: getUproColor("uproYellow"),
                    }}
                  >
                    stars
                  </Icon>
                </ListItemIcon>
                <SoftTypography variant="button" fontWeight="medium">
                  El horario de pedidos es hasta las{" "}
                  <SoftTypography variant="button" fontWeight="bold" color="primary">
                    13:30hs para el turno mañana y 21:00hs para el turno tarde.
                  </SoftTypography>{" "}
                  Hay un cupo limitado de productos, por lo que se recomienda realizar el pedido con
                  anticipación.
                </SoftTypography>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ mb: 1 }}>
                <ListItemIcon>
                  <Icon
                    sx={{
                      color: getUproColor("uproYellow"),
                    }}
                  >
                    stars
                  </Icon>
                </ListItemIcon>
                <SoftTypography variant="button" fontWeight="medium">
                  Si selecciona la opción de Pago por transferencia{" "}
                  <SoftTypography variant="button" fontWeight="bold" color="primary">
                    deberá subir el comprobante de la transferencia realizada
                  </SoftTypography>
                </SoftTypography>
              </ListItem>
            </List>
          </SoftBox>
        )}
      </SoftBox>
      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        {/* <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
            </SoftBox>
          ) : (
            usuario?.nombre + " " + usuario?.apellido
          )}
        </SoftTypography>
        <SoftTypography variant="h7" color="text" fontWeight="regular">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
            </SoftBox>
          ) : (
            usuario?.tipoUser
          )}
        </SoftTypography> */}
        <Divider />
        {/* <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftBox display="flex" alignItems="center" mr={1}>
            <Icon color="primary">badge</Icon>
          </SoftBox>
          <SoftTypography variant="h7" color="text" fontWeight="regular">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
              </SoftBox>
            ) : (
              `DNI: ${usuario?.dni || "Sin cargar"}`
            )}
          </SoftTypography>
        </SoftBox> */}
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftButton
            color="primary"
            fullWidth
            circular
            onClick={handleAddPedido}
            disabled={deshabilitar}
          >
            <Icon>add_shopping_cart</Icon>
            &nbsp; Nuevo pedido
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ProfileCard
ProfileCard.defaultProps = {
  color: "primary",
  usuario: {
    sucursal: {},
    puesto: {},
    user: {},
    localidad: { provincia: {} },
    sucursales: [{}],
  },
  loading: true,
  handleClick: () => {},
};

// Typechecking props for the ProfileCard
ProfileCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "terraPrimary",
    "terraSecondary",
  ]),
  usuario: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  handleAddPedido: PropTypes.func,
  deshabilitar: PropTypes.bool,
};

export default ProfileCard;
