/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { getColors } from "utils/colors";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import { formatDate, formatMoneyPunto } from "utils/formatters";

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color:
                    item?.estado === "ABIERTO"
                      ? getUproColor("uproPink")
                      : item?.estado === "CERRADO"
                      ? getUproColor("uproGreen")
                      : item?.estado === "RESERVADO" &&
                        item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                        item?.venta?.pagos[0]?.estado == "Pendiente"
                      ? getUproColor("error")
                      : item?.estado === "RESERVADO" &&
                        item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                        item?.venta?.pagos[0]?.estado == "Revision"
                      ? getUproColor("uproBlue")
                      : getUproColor("uproYellow"),
                }}
                fontSize="lg"
              >
                {item?.estado === "ABIERTO"
                  ? "assignment"
                  : item?.estado === "CERRADO"
                  ? "assignment_turned_in"
                  : item?.estado === "RESERVADO" &&
                    item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                    item?.venta?.pagos[0]?.estado == "Pendiente"
                  ? "notification_important"
                  : item?.estado === "RESERVADO" &&
                    item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                    item?.venta?.pagos[0]?.estado == "Revision"
                  ? "hourglass_empty"
                  : "receipt"}
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip
                title={
                  item?.venta?.idAnulada
                    ? `Factura ${item.venta.tipoFactura} (Anulada)`
                    : item?.venta?.tipoFactura && item?.estado !== "RESERVADO"
                    ? `Factura ${item.venta.tipoFactura}`
                    : `Pedido ${makeNiceText(item?.estado)}`
                }
                placement="top"
              >
                {item?.venta?.idAnulada ? (
                  <Icon
                    sx={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: getUproColor("uproBlue"),
                    }}
                    fontSize="lg"
                  >
                    assignment_late
                  </Icon>
                ) : item?.venta && item?.estado !== "RESERVADO" ? (
                  <SoftButton color="uproGreen" circular iconOnly size="small">
                    {item?.venta?.tipoFactura}
                  </SoftButton>
                ) : (
                  <Icon
                    sx={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color:
                        item?.estado === "ABIERTO"
                          ? getUproColor("uproPink")
                          : item?.estado === "CERRADO"
                          ? getUproColor("uproGreen")
                          : item?.estado === "RESERVADO" &&
                            item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                            item?.venta?.pagos[0]?.estado == "Pendiente"
                          ? getUproColor("error")
                          : item?.estado === "RESERVADO" &&
                            item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                            item?.venta?.pagos[0]?.estado == "Revision"
                          ? getUproColor("uproBlue")
                          : getUproColor("uproYellow"),
                    }}
                    fontSize="lg"
                  >
                    {item?.estado === "ABIERTO"
                      ? "assignment"
                      : item?.estado === "CERRADO"
                      ? "assignment_turned_in"
                      : item?.estado === "RESERVADO" &&
                        item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                        item?.venta?.pagos[0]?.estado == "Pendiente"
                      ? "notification_important"
                      : item?.estado === "RESERVADO" &&
                        item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                        item?.venta?.pagos[0]?.estado == "Revision"
                      ? "hourglass_empty"
                      : "receipt"}
                  </Icon>
                )}
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.estado === "RESERVADO" &&
              item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
              item?.venta?.pagos[0]?.estado == "Pendiente" ? (
                <SoftButton
                  color="primary"
                  size="small"
                  circular
                  sx={{ cursor: "pointer", padding: "0.2rem 0.5rem", fontSize: "0.6rem" }}
                  onClick={(e) => handleOpenMenu(e, item)}
                >
                  <SoftTypography variant="caption" color="white" fontSize="0.6rem">
                    Cargar comprobante
                  </SoftTypography>
                </SoftButton>
              ) : (
                `Nº ${item?.id}`
              )
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {`Nº ${item?.id}`}
            </SoftTypography>
          </SoftBox>
        ),
        estado: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              <SoftTypography variant="caption" fontWeight="medium">
                {item?.estado === "RESERVADO" &&
                dayjs(item?.fechaPedido).isBefore(dayjs().startOf("day"))
                  ? "Reserva vencida"
                  : item?.estado === "RESERVADO" &&
                    item?.venta?.pagos[0]?.medioDePago?.nombre != "Transferencia"
                  ? `Reservado para ${
                      dayjs(item?.fechaPedido).isAfter(dayjs().subtract(2, "day"))
                        ? formatDate(item?.fechaPedido, "XXXX")
                        : formatDate(item?.fechaPedido, "dd/MM/yyyy")
                    }`
                  : item?.estado === "RESERVADO" &&
                    item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                    item?.venta?.pagos[0]?.estado == "Pendiente"
                  ? `PENDIENTE DE PAGO`
                  : item?.estado === "RESERVADO" &&
                    item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                    item?.venta?.pagos[0]?.estado == "Revision"
                  ? `PAGO EN REVISION`
                  : item?.estado}
              </SoftTypography>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              <SoftBadge
                variant="color"
                color={
                  item?.estado === "ABIERTO"
                    ? getUproColor("primary")
                    : item?.estado === "CERRADO"
                    ? getUproColor("uproGreen")
                    : item?.estado === "ANULADO"
                    ? getUproColor("uproBlue")
                    : item?.estado === "RESERVADO" &&
                      item?.venta?.pagos[0]?.medioDePago?.nombre != "Transferencia"
                    ? getUproColor("uproYellow")
                    : item?.estado === "RESERVADO" &&
                      item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                      item?.venta?.pagos[0]?.estado == "Pendiente"
                    ? getUproColor("error")
                    : item?.estado === "RESERVADO" &&
                      item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                      item?.venta?.pagos[0]?.estado == "Revision"
                    ? getUproColor("uproBlue")
                    : getUproColor("uproYellow")
                }
                badgeContent={
                  item?.estado === "RESERVADO" &&
                  dayjs(item?.fechaPedido).isBefore(dayjs().startOf("day"))
                    ? "Reserva vencida"
                    : item?.estado === "RESERVADO" &&
                      item?.venta?.pagos[0]?.medioDePago?.nombre != "Transferencia"
                    ? `Reservado para ${
                        dayjs(item?.fechaPedido).isAfter(dayjs().subtract(2, "day"))
                          ? formatDate(item?.fechaPedido, "XXXX")
                          : formatDate(item?.fechaPedido, "dd/MM/yyyy")
                      }`
                    : item?.estado === "RESERVADO" &&
                      item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                      item?.venta?.pagos[0]?.estado == "Pendiente"
                    ? `Pendiente de pago`
                    : item?.estado === "RESERVADO" &&
                      item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia" &&
                      item?.venta?.pagos[0]?.estado == "Revision"
                    ? `Pago en revisión`
                    : item?.estado
                }
              />
            </SoftTypography>
          </SoftBox>
        ),
        total: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={
              item?.total
                ? `$ ${formatMoneyPunto(item?.total)}`
                : `
            $ ${formatMoneyPunto(
              item?.detalles.reduce((acc, detalle) => acc + detalle.cantidad * detalle.precio, 0)
            )}
            `
            }
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.total
                ? `$ ${formatMoneyPunto(item?.total)}`
                : `
              $ ${formatMoneyPunto(
                item?.detalles.reduce((acc, detalle) => acc + detalle.cantidad * detalle.precio, 0)
              )}
              `}
            </SoftTypography>
            <Tooltip title={item?.total ? "Pagado" : "Pendiente de pago"} placement="top">
              <Icon
                sx={{
                  color: item?.total ? getUproColor("uproGreen") : getUproColor("uproYellow"),
                  cursor: "pointer",
                  ml: 0.5,
                }}
              >
                {item?.total ? "done" : "hourglass_empty"}
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
        turno: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.turnoReserva ? item?.turnoReserva : "Sin turno"}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.turnoReserva ? item?.turnoReserva : "Sin turno"}
            </SoftTypography>
          </SoftBox>
        ),
        creado: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={
              dayjs(item?.createdAt).isAfter(dayjs().subtract(2, "day"))
                ? formatDate(item?.createdAt, "XXXX a las hh:mm")
                : formatDate(item?.createdAt, "dd/MM/yyyy a las hh:mm")
            }
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {dayjs(item?.createdAt).isAfter(dayjs().subtract(2, "day"))
                ? formatDate(item?.createdAt, "XXXX a las hh:mm")
                : formatDate(item?.createdAt, "dd/MM/yyyy a las hh:mm")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("uproGreen"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "Nº", width: "1%", align: "left" },
  { name: "estado", desc: "Estado", align: "left" },
  { name: "total", desc: "Total", align: "left" },
  { name: "creado", desc: "Creado", align: "left" },
  { name: "turno", desc: "Turno", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
