// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { validatePhone } from "utils/validations";
import { validateCUIL } from "utils/validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalEmpresa({ open, handleClose, refetch, selectedEmpresa }) {
  const [empresa, setEmpresa] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const handleChanges = (e) => {
    setEmpresa({
      ...empresa,
      [e.target.name]: e.target.value,
    });
  };

  const [saveEmpresa, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveEmpresa($input: EmpresaInput!) {
        saveEmpresa(input: $input) {
          id
        }
      }
    `
  );

  const { data: docentes, refetch: refetchDocentes } = useQuery(
    gql`
      query getUsuarios {
        usuarios(filter: { tipoUser: ["Docente", "Gestión Académica"] }) {
          id
          nombre
          apellido
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const handleSave = () => {
    saveEmpresa({
      variables: {
        input: {
          id: empresa?.id,
          cuit: empresa?.cuit,
          nombre: empresa?.nombre,
          direccion: empresa?.direccion,
          telefono: empresa?.telefono,
          idLocalidad: empresa?.idLocalidad,
        },
      },
    })
      .then(() => {
        refetch();
        handleSnackbar("Empresa guardada correctamente", "success");
        handleClose();
      })
      .catch((e) => {
        handleSnackbar("Error al guardar la empresa", "error");
      });
  };

  useEffect(() => {
    if (selectedEmpresa && open) {
      refetchDocentes();
      setEmpresa({
        id: selectedEmpresa.id,
        nombre: selectedEmpresa.nombre,
        cuit: selectedEmpresa.cuit,
        direccion: selectedEmpresa.direccion,
        telefono: selectedEmpresa.telefono,
        idLocalidad: selectedEmpresa.localidad.id,
        idProvincia: selectedEmpresa.localidad?.provincia?.id,
      });
    } else {
      setEmpresa();
    }
  }, [selectedEmpresa, open]);

  useEffect(() => {
    if (empresa?.idProvincia) {
      getLocalidades({ variables: { provincia: empresa?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === empresa?.idLocalidad)) {
          let empresaNew = { ...empresa };
          delete empresaNew.idLocalidad;
          setEmpresa(empresaNew);
        }
      });
    }
  }, [empresa?.idProvincia]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedEmpresa ? `Editar empresa` : `Agregar empresa`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre de la empresa"
                    type="text"
                    name="nombre"
                    value={empresa?.nombre || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="cuit">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      CUIT
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="cuit"
                    placeholder="Ingrese el CUIT de la empresa"
                    type="text"
                    name="cuit"
                    value={empresa?.cuit || ""}
                    error={empresa?.cuit?.length > 0 && !validateCUIL(empresa?.cuit)}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="direccion">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Dirección
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="direccion"
                    placeholder="Ingrese la dirección de la empresa"
                    type="text"
                    name="direccion"
                    value={empresa?.direccion || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="telefono">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Teléfono
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="telefono"
                    placeholder="Ingrese el teléfono de la empresa"
                    type="text"
                    name="telefono"
                    value={empresa?.telefono || ""}
                    error={empresa?.telefono && !validatePhone(empresa?.telefono)}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="provincia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Provincia
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="provincia"
                    value={empresa?.idProvincia || -1}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        idProvincia: e.target.value,
                        provincia: provincias?.provincias.find(
                          (provincia) => provincia.id === e.target.value
                        ),
                      })
                    }
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {provincias?.provincias?.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.id}>
                        {provincia.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="localidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Localidad
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="localidad"
                    value={empresa?.idLocalidad || -1}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        idLocalidad: e.target.value,
                        localidad: localidades?.localidades.find(
                          (localidad) => localidad.id === e.target.value
                        ),
                      })
                    }
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {localidades?.localidades?.map((localidad) => (
                      <MenuItem key={localidad.id} value={localidad.id}>
                        {localidad.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loadingSave}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  disabled={
                    loadingSave ||
                    !empresa?.nombre ||
                    !empresa?.cuit ||
                    !empresa?.direccion ||
                    !empresa?.telefono ||
                    !empresa?.idProvincia ||
                    !empresa?.idLocalidad ||
                    (empresa?.telefono && !validatePhone(empresa?.telefono)) ||
                    (selectedEmpresa?.nombre === empresa?.nombre &&
                      selectedEmpresa?.cuit === empresa?.cuit &&
                      selectedEmpresa?.direccion === empresa?.direccion &&
                      selectedEmpresa?.telefono === empresa?.telefono &&
                      selectedEmpresa?.localidad?.provincia?.id === empresa?.idProvincia &&
                      selectedEmpresa?.localidad?.id === empresa?.idLocalidad)
                  }
                  onClick={handleSave}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  {loadingSave ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalEmpresa.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedEmpresa: PropTypes.object,
};
