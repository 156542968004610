import { gql, useQuery, useMutation } from "@apollo/client";
import { Card, Collapse, Grid, Icon, Tooltip, Select, MenuItem } from "@mui/material";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { useApolloClient } from "context/apolloClientContext";
import { MenuContext } from "context/menuContext";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import ModalDetallesPedido from "./components/ModalDetallesPedido";
import ModalNuevoPedido from "./components/ModalNuevoPedido";
import ProfileCard from "./components/ProfileCard";
import dataPedidos from "./data/dataPedidos";
import dayjs from "dayjs";
import { ConfirmarContext } from "context/ConfirmarContext";
import { MessageManager } from "context";
import ModalCargarComprobante from "./components/ModalCargarComprobante";

function Comedor() {
  const { confirmar } = useContext(ConfirmarContext);
  const { handleSnackbar } = useContext(MessageManager);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openModalPedidoDetalle, setOpenModalPedidoDetalle] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "id",
    orden: "DESC",
  });
  const { menu } = useContext(MenuContext);
  const [expanded, setExpanded] = useState(["Pedidos"]);
  const [openModalNuevoPedido, setOpenModalNuevoPedido] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const client = useApolloClient("clientComedor");
  const [filter, setFilter] = useState("RESERVADO");

  const { columns, getRows } = dataPedidos;

  const [anular, { loading: loadingAnular }] = useMutation(
    gql`
      mutation cancelarVenta($id: ID!) {
        cancelarVenta(id: $id)
      }
    `,
    {
      client: client,
    }
  );

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const { loading, data, refetch } = useQuery(
    gql`
      query misPedidos($filter: JSON, $search: String, $page: Int, $order: Order, $app: Apps) {
        paginationInfo {
          pages
          total
        }
        misPedidos(
          filter: $filter
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          app: $app
        ) {
          id
          estado
          total
          createdAt
          updatedAt
          fechaPedido
          cuentaCorriente
          pagado
          horaReserva
          turnoReserva
          cliente {
            nombre
            apellido
          }
          detalles {
            id
            numeroComanda
            cantidad
            precio
          }
          mesa {
            numero
          }
          venta {
            id
            tipoFactura
            idAnulada
            pagos {
              totalPago
              estado
              medioDePago {
                nombre
              }
            }
          }
          mozo {
            nombre
            apellido
          }
        }
      }
    `,
    {
      client,
      variables: {
        filter: {
          estado: filter,
        },
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
        app: "Alumnos",
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { data: usuario } = useQuery(gql`
    query getUser {
      currentUser {
        id
        tipoUser
        username
        nombre
        apellido
        email
      }
    }
  `);

  const [usuarioVip, setUsuarioVip] = useState(null);
  const { data: usuariosVip } = useQuery(
    gql`
      query usuariosVip($filter: JSON) {
        usuariosVip(filter: $filter) {
          id
        }
      }
    `,
    {
      client,
      variables: {
        filter: {
          idUsuariosVip: usuario?.currentUser?.id,
          sistema: "Alumnos",
        },
      },
    }
  );
  useEffect(() => {
    if (usuariosVip) {
      setUsuarioVip(usuariosVip.usuariosVip[0]);
    }
  }, [usuariosVip]);
  const [openModalCargarComprobante, setOpenModalCargarComprobante] = useState(false);
  const handleOpenCargarComprobante = () => {
    setOpenModalCargarComprobante(true);
  };
  const handleCloseCargarComprobante = () => {
    setOpenModalCargarComprobante(false);
  };

  useEffect(() => {
    if (data) {
      setRows(
        getRows(data.misPedidos, (event, item) => {
          menu({
            open: event.currentTarget,
            align: "right",
            options: [
              {
                name: "Ver detalle",
                icon: { icon: "visibility", color: getUproColor("uproGreen") },
                onClick: () => {
                  setSelectedPedido(item);
                  setOpenModalPedidoDetalle(true);
                },
              },
              ...(item?.estado === "RESERVADO" &&
              item?.venta?.pagos[0]?.estado == "Pendiente" &&
              item?.venta?.pagos[0]?.medioDePago?.nombre == "Transferencia"
                ? [
                    {
                      name: "Cargar comprobante",
                      icon: { icon: "payment", color: "primary" },
                      onClick: () => {
                        setSelectedPedido(item);
                        handleOpenCargarComprobante();
                      },
                    },
                  ]
                : []),
              ...(dayjs().format("YYYY-MM-DD") < dayjs(item?.fechaPedido).format("YYYY-MM-DD") &&
              item?.estado == "RESERVADO"
                ? [
                    {
                      name: "Anular pedido",
                      icon: { icon: "cancel", color: "error" },
                      onClick: () => {
                        confirmar({
                          icon: "warning",
                          title: "Anular pedido",
                          message: `¿Está seguro que desea anular el pedido #${item?.id}?`,
                          pedirMotivo: false,
                          func: async (motivo) => {
                            try {
                              await anular({
                                variables: {
                                  id: item.id,
                                },
                              });
                              handleSnackbar("Pedido anulado correctamente", "success");
                              refetch();
                            } catch (error) {
                              handleSnackbar(
                                "Error al anular el pedido, intente más tarde",
                                "error"
                              );
                            }
                          },
                        });
                      },
                    },
                  ]
                : []),
            ],
            handleClose: () => {},
          });
        })
      );
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);
  const [pedidosHoy, setPedidosHoy] = useState([]);
  //Guardar en setPedidosHoy los pedidos del día, pero solo el campo id y turnoReserva
  useEffect(() => {
    if (data) {
      setPedidosHoy(
        data.misPedidos
          .filter(
            (pedido) =>
              dayjs(pedido.fechaPedido).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD") &&
              pedido.estado !== "ANULADO"
          )
          .map((pedido) => {
            return {
              id: pedido.id,
              turnoReserva: pedido.turnoReserva,
            };
          })
      );
    }
  }, [data]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <ProfileCard
                        usuario={usuario?.currentUser}
                        loading={loading}
                        handleClick={() => setTab(2)}
                        handleAddPedido={() => setOpenModalNuevoPedido(true)}
                        deshabilitar={pedidosHoy.length >= 2 && !usuarioVip}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Card>
                        <SoftBox
                          p={{
                            xs: 0,
                            sm: 2,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Card
                                sx={{
                                  border: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                              >
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  px={2}
                                  py={1}
                                  pt={{
                                    xs: 2,
                                    sm: 1,
                                  }}
                                  gap={2}
                                  flexDirection={{
                                    xs: "column",
                                    sm: "row",
                                  }}
                                  sx={{
                                    borderBottom: !expanded.includes("Pedidos")
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center">
                                    <SoftBox mr={1} display="flex" alignItems="center">
                                      <Icon color="primary">receipt</Icon>
                                    </SoftBox>
                                    <SoftTypography variant="h6" fontWeight="bold">
                                      Pedidos realizados
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    gap={2}
                                  >
                                    <SoftBox
                                      sx={{
                                        width: {
                                          xs: "100%",
                                          sm: "auto",
                                        },
                                      }}
                                    >
                                      <Select
                                        label="Estado"
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                      >
                                        <MenuItem value="RESERVADO">
                                          <SoftBox display="flex" alignItems="center">
                                            <Icon
                                              sx={{ color: getUproColor("uproYellow") }}
                                              fontSize="small"
                                            >
                                              receipt
                                            </Icon>{" "}
                                            &nbsp; Reservados
                                          </SoftBox>
                                        </MenuItem>
                                        <MenuItem value="CERRADO">
                                          <SoftBox display="flex" alignItems="center">
                                            <Icon
                                              sx={{ color: getUproColor("uproGreen") }}
                                              fontSize="small"
                                            >
                                              done
                                            </Icon>{" "}
                                            &nbsp; Cerrados
                                          </SoftBox>
                                        </MenuItem>
                                        <MenuItem value="ANULADO">
                                          <SoftBox display="flex" alignItems="center">
                                            <Icon
                                              sx={{ color: getUproColor("uproBlue") }}
                                              fontSize="small"
                                            >
                                              cancel
                                            </Icon>{" "}
                                            &nbsp; Anulados
                                          </SoftBox>
                                        </MenuItem>
                                      </Select>
                                    </SoftBox>
                                    <SoftBox
                                      display={{
                                        xs: "none",
                                        sm: "flex",
                                      }}
                                    >
                                      <Tooltip title="Nuevo pedido" placement="top">
                                        <SoftButton
                                          color="uproGreen"
                                          circular
                                          onClick={() => setOpenModalNuevoPedido(true)}
                                          disabled={pedidosHoy.length >= 2 && !usuarioVip}
                                        >
                                          <Icon>add_shopping_cart</Icon>
                                          &nbsp; Nuevo pedido
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftBox
                                      display={{
                                        xs: "flex",
                                        sm: "none",
                                      }}
                                    >
                                      <Tooltip title="Nuevo pedido" placement="top">
                                        <SoftButton
                                          color="uproGreen"
                                          circular
                                          iconOnly
                                          onClick={() => setOpenModalNuevoPedido(true)}
                                          disabled={pedidosHoy.length >= 2 && !usuarioVip}
                                        >
                                          <Icon>add_shopping_cart</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                                <Collapse in={expanded.includes("Pedidos")}>
                                  <SoftBox
                                    sx={{
                                      "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                          borderBottom: ({
                                            borders: { borderWidth, borderColor },
                                          }) => `${borderWidth[1]} solid ${borderColor}`,
                                        },
                                      },
                                    }}
                                  >
                                    {loading ? (
                                      <Loading />
                                    ) : rows?.length > 0 ? (
                                      <DataTable
                                        columns={columns}
                                        rows={rows}
                                        order={orderBy}
                                        setOrder={handleOrderBy}
                                      />
                                    ) : (
                                      <SinDatos />
                                    )}
                                  </SoftBox>
                                  <CustomPagination
                                    loading={loading}
                                    length={data?.misPedidos.length}
                                    total={pagesInfo?.total}
                                    pages={pagesInfo?.pages}
                                    page={page}
                                    setPage={setPage}
                                    noCard
                                  />
                                </Collapse>
                              </Card>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </Card>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>
          </Card>
          <ModalNuevoPedido
            open={openModalNuevoPedido}
            handleClose={() => setOpenModalNuevoPedido(false)}
            refetch={refetch}
            deshabilitar={usuarioVip ? false : true}
            pedidosHoy={pedidosHoy}
          />
          <ModalDetallesPedido
            open={openModalPedidoDetalle}
            handleClose={() => {
              setOpenModalPedidoDetalle(false);
              setSelectedPedido(null);
            }}
            idPedido={selectedPedido?.id}
          />
          <ModalCargarComprobante
            open={openModalCargarComprobante}
            handleClose={handleCloseCargarComprobante}
            refetch={refetch}
            pedido={selectedPedido}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Comedor;
