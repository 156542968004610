import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

// @mui material components
import { gql, useMutation } from "@apollo/client";
import { Card, Collapse, Divider, Icon, Skeleton, Tab, Tabs, Tooltip } from "@mui/material/";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";

function ProfileCard({ color, usuario, carrera, loading, handleClick, refetch }) {
  const [inscripciones, setInscripciones] = useState([]);
  const [token, setToken] = useState(null);
  const [timer, setTimer] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const [progress, setProgress] = useState(100);
  const [generateToken, { loading: loadingToken }] = useMutation(gql`
    mutation generateToken {
      generateToken
    }
  `);
  const { menu } = useContext(MenuContext);
  const { handleSnackbar } = useContext(MessageManager);
  const inputRef = useRef(null);

  useEffect(() => {
    if (usuario?.inscripciones) {
      setInscripciones(
        usuario.inscripciones
          ?.filter(
            (inscripcion) =>
              inscripcion?.tipo === "Inscripto" ||
              inscripcion?.tipo === "Inscripto Condicional" ||
              inscripcion?.tipo === "Aprobado"
          )
          ?.sort((a, b) => a.id - b.id)
      );
    }
  }, [usuario]);

  const handleGenerateToken = async () => {
    const res = await generateToken();
    setToken(res.data.generateToken?.token);

    if (intervalId) {
      clearInterval(intervalId);
    }

    setProgress(100);
    setTimer(60);

    const newIntervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(newIntervalId);
          setToken(null);
          setProgress(0);
          return 60;
        }
        setProgress((prevTimer - 1) * (100 / 60));
        return prevTimer - 1;
      });
    }, 1000);

    setIntervalId(newIntervalId);
  };

  const [saveUsuario, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveUser($input: UserInput!) {
        saveUser(input: $input) {
          id
        }
      }
    `
  );

  const [saveCurriculumUser, { loading: loadingCurriculum }] = useMutation(
    gql`
      mutation saveCurriculumUser($idUser: ID!, $file: Upload!) {
        saveCurriculumUser(idUser: $idUser, file: $file)
      }
    `
  );

  return (
    <Card id="profileCard">
      <SoftBox p={2} display="flex" justifyContent="center">
        {loading ? (
          <Skeleton>
            <SoftBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor={color}
              color="white"
              width="8rem"
              height="8rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            ></SoftBox>
          </Skeleton>
        ) : (
          <SoftBox display="flex" justifyContent="center" alignItems="center" py={2}>
            <SoftAvatar
              display="grid"
              bgColor={color}
              color="white"
              width="8rem"
              height="8rem"
              shadow="md"
              variant="rounded"
              src={usuario?.foto ? `${API_URL}/${usuario.foto}` : null}
              size="xxl"
            />
          </SoftBox>
        )}
      </SoftBox>
      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
            </SoftBox>
          ) : (
            usuario?.nombre + " " + usuario?.apellido
          )}
        </SoftTypography>
        <SoftTypography variant="h7" color="text" fontWeight="regular">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
            </SoftBox>
          ) : (
            usuario?.username
          )}
        </SoftTypography>
        <SoftBox mt={1} display="flex" alignItems="center" justifyContent="center">
          {loading ? (
            <Skeleton variant="text" width={100} sx={{ fontSize: "2rem" }} />
          ) : (
            <SoftBadge
              color={
                inscripciones?.find((inscripcion) => inscripcion?.carrera?.id === carrera?.id)
                  ?.tipo === "Inscripto" ||
                inscripciones?.find((inscripcion) => inscripcion?.carrera?.id === carrera?.id)
                  ?.tipo === "Inscripto Condicional"
                  ? "uproGreen"
                  : inscripciones?.find((inscripcion) => inscripcion?.carrera?.id === carrera?.id)
                      ?.tipo === "Aprobado"
                  ? "uproGreen"
                  : "uproYellow"
              }
              badgeContent={
                inscripciones?.find((inscripcion) => inscripcion?.carrera?.id === carrera?.id)
                  ?.tipo || "Aspirante"
              }
            />
          )}
        </SoftBox>
        <Divider />
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftBox display="flex" alignItems="center" mr={1}>
            <Icon color="primary">description</Icon>
          </SoftBox>
          <SoftTypography variant="h7" color="text" fontWeight="regular">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
              </SoftBox>
            ) : usuario?.nroLegajo ? (
              `Legajo Nº: ${usuario?.nroLegajo}`
            ) : (
              "Sin legajo"
            )}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftBox display="flex" alignItems="center" mr={1}>
            <Icon color="primary">badge</Icon>
          </SoftBox>
          <SoftTypography variant="h7" color="text" fontWeight="regular">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
              </SoftBox>
            ) : (
              `DNI: ${usuario?.dni}`
            )}
          </SoftTypography>
        </SoftBox>
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={1}
          id="atajoEditarPerfil"
        >
          <SoftButton color="uproYellow" fullWidth circular onClick={handleClick}>
            <Icon>edit</Icon>
            &nbsp; Editar Perfil
          </SoftButton>
        </SoftBox>
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={1}
          id="generarToken"
        >
          <SoftButton
            color="primary"
            fullWidth
            circular
            onClick={handleGenerateToken}
            sx={{
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: `${progress}%`,
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                transition: "width 1s ease-in-out",
                zIndex: 1,
              },
              "& > *": {
                position: "relative",
                zIndex: 2,
              },
            }}
          >
            <Icon>{loadingToken ? "hourglass_empty" : token ? "refresh" : "vpn_key"}</Icon>
            &nbsp;<p>{loadingToken ? "Generando" : token ? token : "Generar token"}</p>
          </SoftButton>
        </SoftBox>

        <SoftBox id="pasantias">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton width={"100%"} />
            </SoftBox>
          ) : (
            <SoftBox pt={1}>
              <SoftBox mb={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  ¿Desea postularse para una pasantía?
                </SoftTypography>
              </SoftBox>
              <Tabs
                value={usuario?.aceptaPasantia ? 1 : 0}
                name="aceptaPasantia"
                onChange={(e, value) => {
                  saveUsuario({
                    variables: {
                      input: {
                        id: usuario.id,
                        aceptaPasantia: value === 1,
                      },
                    },
                  })
                    .then(() => {
                      refetch();
                      handleSnackbar(
                        usuario?.aceptaPasantia
                          ? "Se ha eliminado correctamente su postulación para pasantías"
                          : usuario?.curriculum
                          ? "Se ha postulado correctamente para las pasantías"
                          : "Para completar la postulación, suba su curriculum",
                        !usuario?.curriculum && !usuario?.aceptaPasantia ? "info" : "success"
                      );
                    })
                    .catch((e) => {
                      handleSnackbar(
                        "Ocurrió un error, por favor intente nuevamente más tarde",
                        "error"
                      );
                    });
                }}
              >
                <Tab
                  label={
                    <SoftTypography
                      variant="h6"
                      fontWeight={!usuario?.aceptaPasantia ? "bold" : "regular"}
                    >
                      No
                    </SoftTypography>
                  }
                  value={0}
                />
                <Tab
                  label={
                    <SoftTypography
                      variant="h6"
                      fontWeight={usuario?.aceptaPasantia ? "bold" : "regular"}
                    >
                      Sí
                    </SoftTypography>
                  }
                  value={1}
                />
              </Tabs>
            </SoftBox>
          )}
        </SoftBox>
        <Collapse in={usuario?.aceptaPasantia}>
          <SoftBox pt={2}>
            <Tooltip
              title={usuario?.curriculum ? "Ver opciones" : "Subir curriculum"}
              placement="top"
            >
              <SoftButton
                circular
                color={usuario?.curriculum ? "uproGreen" : "uproYellow"}
                fullWidth
                onClick={
                  usuario?.curriculum
                    ? (event) => {
                        menu({
                          open: event.currentTarget,
                          align: "center",
                          options: [
                            {
                              name: "Descargar",
                              icon: {
                                icon: "file_download",
                                color: getUproColor("uproGreen"),
                              },
                              onClick: async () => {
                                const file = await fetch(`${API_URL}/${usuario.curriculum}`);
                                const blob = await file.blob();
                                saveAs(
                                  blob,
                                  `Curriculum - ${usuario.nombre} ${usuario.apellido}.pdf`
                                );
                              },
                            },
                            {
                              name: "Cambiar documento",
                              icon: {
                                icon: "refresh",
                                color: getUproColor("uproYellow"),
                              },
                              onClick: async () => {
                                inputRef.current.click();
                              },
                            },
                          ],
                          handleClose: () => {},
                        });
                      }
                    : () => {
                        inputRef.current.click();
                      }
                }
              >
                <Icon>{usuario?.curriculum ? "cloud_done" : "cloud_upload"}</Icon>
                &nbsp; {usuario?.curriculum ? "Curriculum subido" : "Subir curriculum"}
              </SoftButton>
            </Tooltip>
            <input
              ref={inputRef}
              type="file"
              style={{ display: "none" }}
              accept=".pdf"
              onChange={(e) => {
                if (e.target.files[0] && e.target.files[0].size < 20000000) {
                  saveCurriculumUser({
                    variables: {
                      idUser: usuario.id,
                      file: e.target.files[0],
                    },
                  })
                    .then(() => {
                      refetch();
                      handleSnackbar("Curriculum subido correctamente", "success");
                    })
                    .catch((e) => {
                      handleSnackbar(
                        "Ocurrió un error, por favor intente nuevamente más tarde",
                        "error"
                      );
                    });
                } else {
                  handleSnackbar("El archivo debe ser menor a 20MB", "error");
                }
              }}
            />
          </SoftBox>
        </Collapse>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ProfileCard
ProfileCard.defaultProps = {
  color: "primary",
  usuario: {
    sucursal: {},
    puesto: {},
    user: {},
    localidad: { provincia: {} },
    sucursales: [{}],
  },
  loading: true,
  handleClick: () => {},
};

// Typechecking props for the ProfileCard
ProfileCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "terraPrimary",
    "terraSecondary",
  ]),
  usuario: PropTypes.object.isRequired,
  carrera: PropTypes.object,
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
  refetch: PropTypes.func,
};

export default ProfileCard;
