// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";
import SoftInput from "components/SoftInput";
import Loading from "components/Carteles/Loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "30%", xxl: "30%" },
  overflow: "auto",
};

export default function ModalCuestionario({ cuestionario, handleClose, refetch }) {
  const [respuestas, setRespuestas] = useState([]);
  const { user } = useContext(UserContext);
  const { handleSnackbar } = useContext(MessageManager);

  const [getCuestionario, { data, loading }] = useLazyQuery(
    gql`
      query getCuestionario($id: ID!) {
        cuestionario(id: $id) {
          nombre
          descripcion
          fechaLimite
          obligatorio
          preguntas {
            id
            titulo
            tipo
            opciones
          }
        }
      }
    `
  );

  const [saveRespuestasCuestionario, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveRespuestasCuestionario($input: CuestionarioRespuestaInput!) {
        saveRespuestasCuestionario(input: $input)
      }
    `
  );

  useEffect(() => {
    if (cuestionario) {
      getCuestionario({ variables: { id: cuestionario.id } });
    }
  }, [cuestionario]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Boolean(cuestionario)}
      closeAfterTransition
    >
      <Fade in={Boolean(cuestionario)}>
        <Card sx={style}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
            {loading ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <SoftTypography variant="h6">
                {data?.cuestionario?.nombre || "Cuestionario"}
              </SoftTypography>
            )}
            <SoftBox>
              <Tooltip title="Cerrar" placement="top">
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" p={3} pt={1} pb={0}>
            {loading ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <SoftTypography variant="h7" fontWeight="regular">
                {data?.cuestionario?.descripcion || "Sin descripción"}
              </SoftTypography>
            )}
          </SoftBox>
          {loading ? (
            <Loading />
          ) : (
            <SoftBox p={3} pt={1} display="flex" flexDirection="column" gap={2}>
              <SoftBox>
                {data?.cuestionario?.preguntas?.map((pregunta) => (
                  <SoftBox key={pregunta.id}>
                    <InputLabel htmlFor="talleCalzado">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        {pregunta.titulo}
                      </SoftTypography>
                    </InputLabel>
                    {pregunta?.tipo === "multipleChoice" ? (
                      <Select
                        value={
                          respuestas.find((res) => res.idPregunta === pregunta.id)?.respuesta || -1
                        }
                        onChange={(e) => {
                          const respuesta = { idPregunta: pregunta.id, respuesta: e.target.value };
                          setRespuestas((prev) =>
                            prev.filter((res) => res.idPregunta !== pregunta.id).concat(respuesta)
                          );
                        }}
                      >
                        <MenuItem value={-1} disabled>
                          Seleccionar
                        </MenuItem>
                        {pregunta.opciones.map((opcion) => (
                          <MenuItem key={opcion.opcion} value={opcion.opcion}>
                            {opcion.opcion}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <SoftInput
                        type="text"
                        placeholder="Escribe tu respuesta"
                        value={
                          respuestas.find((res) => res.idPregunta === pregunta.id)?.respuesta || ""
                        }
                        onChange={(e) => {
                          const respuesta = { idPregunta: pregunta.id, respuesta: e.target.value };
                          setRespuestas((prev) =>
                            prev.filter((res) => res.idPregunta !== pregunta.id).concat(respuesta)
                          );
                        }}
                      />
                    )}
                  </SoftBox>
                ))}
              </SoftBox>
              <SoftBox>
                <Tooltip title="Guardar talles" placement="top">
                  <SoftButton
                    color="uproGreen"
                    circular
                    fullWidth
                    disabled={
                      loading ||
                      loadingSave ||
                      respuestas.length === 0 ||
                      respuestas.some((res) => res.respuesta === "") ||
                      respuestas.length !== data?.cuestionario?.preguntas?.length
                    }
                    onClick={async () => {
                      saveRespuestasCuestionario({
                        variables: {
                          input: {
                            idCuestionario: cuestionario.id,
                            respuestas: respuestas,
                          },
                        },
                      })
                        .then(() => {
                          handleSnackbar("Respuestas guardadas correctamente", "success");
                          refetch();
                          handleClose();
                        })
                        .catch((error) => {
                          handleSnackbar(
                            error.message || "Error al guardar las respuestas",
                            "error"
                          );
                        });
                    }}
                  >
                    Guardar&nbsp;
                    {loadingSave ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <Icon>save</Icon>
                    )}
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            </SoftBox>
          )}
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCuestionario.propTypes = {
  cuestionario: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};
