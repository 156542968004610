// @mui material components
import { Card, Collapse, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useLazyQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useEffect, useState } from "react";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "40%" },
  overflow: "auto",
};

export default function ModalCertificados({ open, handleClose, idAlumno, idCarrera }) {
  const [certificados, setCertificados] = useState([]);

  const [getCertificados, { loading }] = useLazyQuery(
    gql`
      query getCertificados($filter: JSON, $order: Order) {
        certificados(filter: $filter, order: $order) {
          id
          tipo
          createdAt
          url
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getUser, { data: alumno, loading: loadingAlumno }] = useLazyQuery(gql`
    query getUser($id: ID!) {
      usuario(id: $id) {
        nombre
        apellido
        dni
      }
    }
  `);

  const [getCarrera, { data: carrera, loading: loadingCarrera }] = useLazyQuery(gql`
    query getCarrera($id: ID!) {
      carrera(id: $id) {
        nombre
        codigo
      }
    }
  `);

  useEffect(() => {
    if (idAlumno && open) {
      getUser({ variables: { id: idAlumno } });
      getCarrera({ variables: { id: idCarrera } });
      getCertificados({
        variables: {
          filter: {
            idUser: idAlumno,
            idCarrera: idCarrera,
          },
          order: { field: "id", order: "DESC" },
        },
      }).then((data) => {
        setCertificados(data.data.certificados || []);
      });
    } else {
      setCertificados([]);
    }
  }, [idAlumno, open]);

  const handleDownloadAll = async () => {
    const zip = new JSZip();

    for (let certificado of certificados) {
      const res = await fetch(`${API_URL}/${certificado.url}`);
      let filename = `${certificado.id} - ${certificado.tipo} - ${alumno.usuario.dni}.pdf`;
      const blob = await res.blob();
      zip.file(filename, blob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(
        content,
        `Certificados ${carrera?.carrera?.codigo} - ${makeNiceText(
          `${carrera?.carrera?.nombre}`,
          "nombre"
        )} - ${alumno.usuario.nombre} ${alumno.usuario.apellido} - ${dayjs().format(
          "DD-MM-YYYY"
        )}.zip`
      );
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
              <SoftTypography variant="h6">{`Certificados de ${makeNiceText(
                `${alumno?.usuario?.apellido} ${alumno?.usuario?.nombre}`,
                "nombre"
              )}
                `}</SoftTypography>
              <SoftBox display="flex" justifyContent="end" alignItems="center">
                <SoftBox display="flex" justifyContent="end" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">description</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {`Certificados de ${carrera?.carrera?.codigo} - ${makeNiceText(
                            `${carrera?.carrera?.nombre}`,
                            "nombre"
                          )}`}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip placement="top" title="Descargar todos">
                            <SoftButton
                              color="primary"
                              iconOnly
                              circular
                              onClick={handleDownloadAll}
                              disabled={certificados.length === 0}
                            >
                              <Icon sx={{ fontWeight: "regular" }}>download</Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={open}>
                      {loading || loadingAlumno || loadingCarrera ? (
                        <Loading />
                      ) : certificados?.length > 0 ? (
                        certificados?.map((certificado, index) => (
                          <SoftBox key={certificado.id}>
                            <SoftBox
                              px={2}
                              py={1}
                              sx={{
                                borderBottom:
                                  certificados?.length - 1 === index
                                    ? "none"
                                    : "1px solid rgba(0, 0, 0, 0.12)",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                            >
                              <SoftBox display="flex" justifyContent="space-between">
                                <SoftBox display="flex" alignItems="center" pl={2}>
                                  <SoftBox mr={1}>
                                    <Icon
                                      sx={{
                                        color: getUproColor("uproPink"),
                                      }}
                                    >
                                      subdirectory_arrow_right
                                    </Icon>
                                  </SoftBox>
                                  <SoftBox mr={1}>
                                    <Tooltip title={certificado?.tipo} placement="top">
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproYellow"),
                                        }}
                                      >
                                        file_present
                                      </Icon>
                                    </Tooltip>
                                  </SoftBox>
                                  <SoftTypography variant="h6">{certificado.tipo}</SoftTypography>
                                  <SoftBox ml={1} display="flex" alignItems="center">
                                    <SoftBadge
                                      color="uproYellow"
                                      badgeContent={dayjs(certificado.createdAt).format(
                                        "DD-MM-YYYY HH:mm"
                                      )}
                                    />
                                  </SoftBox>
                                </SoftBox>
                                <SoftBox
                                  display="flex"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <SoftBox>
                                    <Tooltip title={"Descargar"} placement="top">
                                      <SoftButton
                                        color="uproGreen"
                                        circular
                                        iconOnly
                                        onClick={async () => {
                                          const file = await fetch(`${API_URL}/${certificado.url}`);
                                          const name = `${certificado.id} - ${certificado.tipo} - ${alumno.usuario.dni}.pdf`;
                                          const blob = await file.blob();
                                          const url = URL.createObjectURL(blob);

                                          const a = document.createElement("a");
                                          a.href = url;
                                          a.download = name;
                                          a.click();
                                        }}
                                      >
                                        <Icon>download</Icon>
                                      </SoftButton>
                                    </Tooltip>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ))
                      ) : (
                        <Grid container spacing={2} p={2}>
                          <SinDatos />
                        </Grid>
                      )}
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalCertificados.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idAlumno: PropTypes.string,
  idCarrera: PropTypes.string,
};
