import { gql, useMutation } from "@apollo/client";
import { Card, CircularProgress, Fade, Icon, Input, Modal, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalVerificarExamen({ open, handleClose, inscripcion, refetch }) {
  const inputRefs = useRef([]);
  const [code, setCode] = useState(Array(6).fill(""));
  const [isConfirmDisabled, setConfirmDisabled] = useState(true);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const { handleSnackbar } = useContext(MessageManager);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
      }, 100);
    }
  }, [open]);

  useEffect(() => {
    const isCodeComplete = code.every((digit) => digit !== "");
    setConfirmDisabled(!isCodeComplete);
  }, [code]);

  useEffect(() => {
    let timer;
    if (isResendDisabled && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setResendDisabled(false);
      setResendTimer(60);
    }
    return () => clearInterval(timer);
  }, [isResendDisabled, resendTimer]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      e.target.value = "";
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    if (e.key === "Enter" && index === 5) {
      handleVerificarCodigo();
    }
  };

  const [
    habilitarExamen,
    { data: dataVerificar, loading: loadingVerificar, error: errorVerificar },
  ] = useMutation(gql`
    mutation habilitarExamen($idInscripcion: ID!, $token: String) {
      habilitarExamen(idInscripcion: $idInscripcion, token: $token)
    }
  `);

  const handleVerificarCodigo = async () => {
    await habilitarExamen({
      variables: {
        idInscripcion: inscripcion.id,
        token: code.join(""),
      },
    })
      .then((res) => {
        if (res.data.habilitarExamen) {
          refetch();
          handleSnackbar("Alumno habilitado para el examen correctamente", "success");
          handleClose();
        }
      })
      .catch((err) => {
        handleSnackbar(err.message || "Ha ocurrido un error al habilitar el examen", "error");
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  Habilitar examen para {inscripcion?.alumno?.apellido}{" "}
                  {inscripcion?.alumno?.nombre}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <SoftBox
                sx={{
                  lineHeight: "normal",
                }}
              >
                <SoftTypography variant="h7" fontWeight="regular">
                  Para habilitar el examen, solicite al alumno un código de verificación. Este
                  código puede ser generado a través de la autogestión del mismo.
                </SoftTypography>
              </SoftBox>
              <SoftBox py={3}>
                <Card>
                  <SoftBox p={3} textAlign="center">
                    <SoftTypography variant="h6" color="primary">
                      Código de verificación
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        display: {
                          xs: "none",
                          sm: "flex",
                        },
                      }}
                      my={2}
                      display="flex"
                      justifyContent="center"
                      gap={2}
                    >
                      {[...Array(6)].map((_, index) => (
                        <SoftBox key={index} sx={{ width: "50px" }}>
                          <Input
                            inputRef={(el) => (inputRefs.current[index] = el)}
                            sx={{
                              textAlign: "center",
                              border: "none",
                              fontSize: "1.5rem",
                              padding: "15px 0 15px 0 !important",
                              input: { fontSize: "1.5rem", textAlign: "center" },
                            }}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            inputProps={{ maxLength: 1 }} // Limita a un solo carácter
                          />
                        </SoftBox>
                      ))}
                    </SoftBox>
                    <SoftBox
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                        },
                      }}
                      my={2}
                      display="flex"
                      justifyContent="center"
                      gap={2}
                    >
                      <SoftInput
                        value={code.join("")}
                        onChange={(e) => {
                          const value = e.target.value;
                          setCode(value.split(""));
                        }}
                        inputProps={{ maxLength: 6 }}
                        fullWidth
                        sx={{
                          "& input": {
                            textAlign: "center",
                            fontSize: "1.5rem",
                          },
                        }}
                      />
                    </SoftBox>
                    <SoftBox mt={2}>
                      <SoftTypography variant="h7" color="error" fontWeight="regular">
                        {errorVerificar ? errorVerificar.message : ""}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
              <SoftBox>
                <SoftBox>
                  <SoftButton
                    color="uproGreen"
                    fullWidth
                    circular
                    disabled={isConfirmDisabled || loadingVerificar || code.join("").length < 6}
                    onClick={handleVerificarCodigo}
                  >
                    {loadingVerificar ? (
                      <CircularProgress
                        size={15}
                        sx={{
                          marginRight: 0.5,
                        }}
                        color="inherit"
                      />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>lock_open</Icon>
                    )}
                    &nbsp; Habilitar examen
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalVerificarExamen.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  inscripcion: PropTypes.object,
  refetch: PropTypes.func,
};
