/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto, makeNiceText } from "utils/formatters";

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      let sedes = [];
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color:
                    item?.tipoUser === "Alumno"
                      ? getUproColor("uproYellow")
                      : item?.tipoUser === "Docente"
                      ? getUproColor("uproGreen")
                      : getUproColor("uproPink"),
                }}
                fontSize="lg"
              >
                {item?.tipoUser === "Alumno"
                  ? "school"
                  : item?.tipoUser === "Docente"
                  ? "supervisor_account"
                  : "manage_accounts"}
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={item?.tipoUser} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color:
                      item?.tipoUser === "Alumno"
                        ? getUproColor("uproYellow")
                        : item?.tipoUser === "Docente"
                        ? getUproColor("uproGreen")
                        : getUproColor("uproPink"),
                  }}
                  fontSize="lg"
                >
                  {item?.tipoUser === "Alumno"
                    ? "school"
                    : item?.tipoUser === "Docente"
                    ? "supervisor_account"
                    : "manage_accounts"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.apellido
                ? makeNiceText(item?.apellido + " " + item?.nombre, "nombre")
                : item?.username
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.apellido + " " + item?.nombre, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        estadoCurso: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.curso ? `${item?.curso?.tipo} en ${item?.curso?.sede?.nombre}` : "Sin estado"
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox display="flex" alignItems="center" gap={1}>
              <SoftBadge
                badgeContent={`${item?.curso?.tipo || "Sin inscripción"}`}
                variant={item?.curso?.tipo ? "color" : "gradient"}
                color={
                  item?.curso?.tipo === "Inscripto"
                    ? getUproColor("uproGreen")
                    : item?.curso?.tipo === "Inscripto Condicional"
                    ? getUproColor("uproGreen")
                    : item?.curso?.tipo === "Aprobado"
                    ? getUproColor("uproGreen")
                    : item?.curso?.tipo === "Desaprobado"
                    ? getUproColor("uproPink")
                    : item?.curso?.tipo === "Libre"
                    ? getUproColor("uproPink")
                    : item?.curso?.tipo === "Baja"
                    ? getUproColor("uproBlue")
                    : item?.curso?.tipo === "Preinscripto"
                    ? getUproColor("uproYellow")
                    : "error"
                }
              />
              {item?.curso?.sede?.nombre && (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                  en {item?.curso?.sede?.nombre}
                </SoftTypography>
              )}
            </SoftBox>
          </SoftBox>
        ),
        estadoCarrera: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.estadoInscripcionCarrera
                ? `${item?.estadoInscripcionCarrera} - ${item?.sedeCarrera?.nombre}`
                : "Sin inscripción"
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox display="flex" alignItems="center" gap={1}>
              <SoftBadge
                badgeContent={
                  item?.inscripcionActual ? `${item?.inscripcionActual?.tipo}` : "Sin inscripción"
                }
                variant={item?.inscripcionActual?.tipo ? "color" : "gradient"}
                color={
                  item?.inscripcionActual?.tipo === "Inscripto"
                    ? getUproColor("uproGreen")
                    : item?.inscripcionActual?.tipo === "Inscripto Condicional"
                    ? getUproColor("uproGreen")
                    : item?.inscripcionActual?.tipo === "Aprobado"
                    ? getUproColor("uproGreen")
                    : item?.inscripcionActual?.tipo === "Desaprobado"
                    ? getUproColor("uproPink")
                    : item?.inscripcionActual?.tipo === "Libre"
                    ? getUproColor("uproPink")
                    : item?.inscripcionActual?.tipo === "Baja"
                    ? getUproColor("uproBlue")
                    : item?.inscripcionActual?.tipo === "Preinscripto"
                    ? getUproColor("uproYellow")
                    : "error"
                }
              />
              {item?.inscripcionActual?.sede?.nombre && (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                  en {item?.inscripcionActual?.sede?.nombre}
                </SoftTypography>
              )}
            </SoftBox>
          </SoftBox>
        ),
        dni: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={formatMoneyPunto(item?.dni)}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {formatMoneyPunto(item?.dni)}
            </SoftTypography>
          </SoftBox>
        ),
        carrera: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {makeNiceText(item?.inscripcionActual?.carrera?.nombre, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("uproGreen"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left", orderField: "nombreCompleto" },
  {
    name: "estadoCurso",
    desc: "Estado curso",
    align: "left",
    noOrder: true,
  },
  {
    name: "estadoCarrera",
    desc: "Estado carrera",
    align: "left",
    noOrder: true,
  },
  { name: "dni", desc: "DNI", align: "left" },
  { name: "carrera", desc: "Carrera", align: "left", noOrder: true },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
