// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// Components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  Modal,
  Skeleton,
  Tooltip,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Data
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import ModalDelete from "components/Modals/Delete";
import SoftBadge from "components/SoftBadge";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import ModalCertificado from "layouts/autogestion/components/ModalCertificado";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import ModalCertificados from "../ModalCertificados";
import ModalCupos from "../ModalCupos";
import ModalInscripcionComision from "../ModalInscripcionComision";
import ModalNewInscripcion from "../ModalNewInscripcion";
import { ConfirmarContext } from "context/ConfirmarContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
};

export default function ModalInscripciones({
  open,
  handleClose,
  idUsuario,
  openModalDocumentacion,
  handleOpenDocumentacion,
  refetch,
}) {
  const [expanded, setExpanded] = useState(["Inscripciones Actuales"]);
  const [selectedInscripcion, setSelectedInscripcion] = useState(null);
  const [openModalCertificados, setOpenModalCertificados] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [tipoInscripcion, setTipoInscripcion] = useState("Carrera");
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalCupos, setOpenModalCupos] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const [openModalCertificado, setOpenModalCertificado] = useState();
  const documentacionRequerida = ["DNI Frente", "DNI Dorso", "Declaración Jurada"];
  const [openModalInscripcionComision, setOpenModalInscripcionComision] = useState(false);
  const [certificados, setCertificados] = useState([]);

  // const documentacionRequeridaAnaliticos = [
  //   "Constancia de Finalización de Estudios Secundarios",
  //   "Certificado Analítico",
  //   "Constancia de estudios en progreso",
  // ];

  const { menu } = useContext(MenuContext);
  const { confirmar } = useContext(ConfirmarContext);

  const [getUsuario, { data: usuario, refetch: refetchUsuario }] = useLazyQuery(
    gql`
      query getUsuario($id: ID!) {
        usuario(id: $id) {
          id
          nombre
          apellido
          dni
          escalaMatematicas
          escalaComprensionTextos
          fechaNacimiento
          inscripciones {
            id
            carrera {
              id
              nombre
              codigo
              tipo
            }
            sede {
              id
              nombre
            }
            tipo
            createdAt
            cupo {
              nombre
              sede {
                nombre
              }
            }
          }
          documentos {
            id
            nombre
            file
            createdAt
            estado
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getCertificados] = useLazyQuery(
    gql`
      query getCertificados($filter: JSON) {
        certificados(filter: $filter) {
          id
          tipo
          createdAt
          url
          carrera {
            id
          }
          usuario {
            id
            nombre
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [bajaInscripcion, { loading: loadingBaja }] = useMutation(gql`
    mutation bajaInscripcion($idInscripcion: ID!) {
      bajaInscripcion(idInscripcion: $idInscripcion) {
        id
      }
    }
  `);

  useEffect(() => {
    setCertificados([]);
    if (idUsuario && open) {
      getUsuario({ variables: { id: idUsuario } });
      getCertificados({
        variables: {
          filter: {
            idUser: idUsuario,
          },
        },
      }).then((data) => {
        setCertificados(data.data.certificados || []);
      });
    }
  }, [idUsuario, open]);

  useEffect(() => {
    refetchUsuario();
    refetch();
  }, [openModalDocumentacion]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3} pb={0}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftTypography variant="h6">
                        {usuario?.usuario?.nombre ? (
                          `Inscripciones de ${makeNiceText(
                            `${usuario?.usuario?.nombre} ${usuario?.usuario?.apellido}`,
                            "nombre"
                          )}`
                        ) : (
                          <Skeleton variant="text" width={200} />
                        )}
                      </SoftTypography>
                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                      >
                        <Tooltip title="Agregar nueva inscripción" placement="top">
                          <SoftButton
                            color="primary"
                            circular
                            iconOnly
                            onClick={(event) => {
                              menu({
                                open: event.currentTarget,
                                align: "right",
                                options: [
                                  {
                                    name: "Inscripción a Carrera",
                                    icon: {
                                      icon: "history_edu",
                                      color: getUproColor("uproYellow"),
                                    },
                                    onClick: () => {
                                      setTipoInscripcion("Carrera");
                                      setOpenModalAdd(true);
                                    },
                                  },
                                  {
                                    name: "Inscripción a Curso",
                                    icon: {
                                      icon: "auto_stories",
                                      color: getUproColor("uproYellow"),
                                    },
                                    onClick: () => {
                                      setTipoInscripcion("Curso");
                                      setOpenModalAdd(true);
                                    },
                                  },
                                ],
                              });
                            }}
                          >
                            <Icon>add</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftBox
                        display={{
                          xs: "none",
                          sm: "block",
                        }}
                        mr={2}
                      >
                        <Tooltip title="Administrar documentación" placement="top">
                          <SoftButton color="uproYellow" circular onClick={handleOpenDocumentacion}>
                            <Icon>description</Icon>
                            &nbsp;Documentación
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "none",
                          sm: "block",
                        }}
                      >
                        <Tooltip title="Agregar nueva inscripción" placement="top">
                          <SoftButton
                            color="primary"
                            circular
                            onClick={(event) => {
                              menu({
                                open: event.currentTarget,
                                align: "right",
                                options: [
                                  {
                                    name: "Inscripción a Carrera",
                                    icon: {
                                      icon: "history_edu",
                                      color: getUproColor("uproYellow"),
                                    },
                                    onClick: () => {
                                      setTipoInscripcion("Carrera");
                                      setOpenModalAdd(true);
                                    },
                                  },
                                  {
                                    name: "Inscripción a Curso",
                                    icon: {
                                      icon: "auto_stories",
                                      color: getUproColor("uproYellow"),
                                    },
                                    onClick: () => {
                                      setTipoInscripcion("Curso de Ingreso");
                                      setOpenModalAdd(true);
                                    },
                                  },
                                ],
                              });
                            }}
                          >
                            <Icon>add</Icon>
                            &nbsp;Agregar
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        px={2}
                        py={1}
                        sx={{
                          borderBottom: !expanded.includes("Inscripciones Actuales")
                            ? "none"
                            : "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                        onClick={(e) => {
                          setExpanded(
                            expanded.includes("Inscripciones Actuales")
                              ? expanded.filter((i) => i !== "Inscripciones Actuales")
                              : [...expanded, "Inscripciones Actuales"]
                          );
                        }}
                      >
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">assignment_turned_in</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            Inscripciones Actuales
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                          <SoftBox>
                            <Tooltip
                              title={
                                expanded.includes("Inscripciones Actuales") ? "Ocultar" : "Mostrar"
                              }
                              placement="top"
                            >
                              <SoftButton
                                color="primary"
                                circular
                                iconOnly
                                onClick={() =>
                                  setExpanded(
                                    expanded.includes("Inscripciones Actuales")
                                      ? expanded.filter((i) => i !== "Inscripciones Actuales")
                                      : [...expanded, "Inscripciones Actuales"]
                                  )
                                }
                              >
                                <Icon
                                  sx={{
                                    rotate: expanded.includes("Inscripciones Actuales")
                                      ? "180deg"
                                      : "0deg",
                                    transition: "all 0.5s",
                                  }}
                                >
                                  expand_more
                                </Icon>
                              </SoftButton>
                            </Tooltip>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      <Collapse in={expanded.includes("Inscripciones Actuales")}>
                        {usuario?.usuario?.inscripciones?.filter((i) => i.tipo !== "Baja").length >
                        0 ? (
                          usuario?.usuario?.inscripciones
                            ?.filter((i) => i.tipo !== "Baja")
                            .map((inscripcion, index) => (
                              <SoftBox key={inscripcion.id}>
                                <SoftBox
                                  px={2}
                                  py={1}
                                  sx={{
                                    borderBottom:
                                      usuario?.usuario?.inscripciones?.filter(
                                        (i) => i.tipo !== "Baja"
                                      ).length -
                                        1 ===
                                      index
                                        ? "none"
                                        : "1px solid rgba(0, 0, 0, 0.12)",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  <SoftBox display="flex" justifyContent="space-between">
                                    <SoftBox
                                      display="flex"
                                      alignItems="center"
                                      pl={{
                                        xs: 0,
                                        sm: 2,
                                      }}
                                    >
                                      <SoftBox
                                        mr={1}
                                        sx={{
                                          display: {
                                            xs: "none",
                                            sm: "block",
                                          },
                                        }}
                                      >
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproPink"),
                                          }}
                                        >
                                          subdirectory_arrow_right
                                        </Icon>
                                      </SoftBox>
                                      <SoftBox mr={1}>
                                        <Tooltip title={inscripcion?.carrera?.tipo} placement="top">
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproYellow"),
                                            }}
                                          >
                                            {inscripcion?.carrera?.tipo === "Carrera"
                                              ? "history_edu"
                                              : "auto_stories"}
                                          </Icon>
                                        </Tooltip>
                                      </SoftBox>
                                      <SoftTypography variant="h6">{`${inscripcion?.carrera?.codigo} - ${inscripcion?.carrera?.nombre} (${inscripcion?.sede?.nombre})`}</SoftTypography>
                                      <SoftBox ml={1} display="flex" alignItems="center">
                                        <SoftBadge
                                          color={
                                            inscripcion?.tipo === "Preinscripto"
                                              ? "uproYellow"
                                              : inscripcion?.tipo === "Inscripto" ||
                                                inscripcion?.tipo === "Inscripto Condicional"
                                              ? "uproGreen"
                                              : inscripcion?.tipo === "Aprobado"
                                              ? "uproGreen"
                                              : "primary"
                                          }
                                          badgeContent={`${inscripcion?.tipo}`}
                                        />
                                      </SoftBox>
                                    </SoftBox>

                                    <SoftBox
                                      display="flex"
                                      justifyContent="flex-end"
                                      alignItems="center"
                                      ml={1}
                                      gap={1}
                                    >
                                      {inscripcion?.tipo === "Preinscripto" &&
                                        inscripcion?.carrera?.tipo === "Carrera" && (
                                          <SoftBox>
                                            <Tooltip
                                              title={"Generar certificado de preinscripción"}
                                              placement="top"
                                            >
                                              <SoftButton
                                                color="uproGreen"
                                                circular
                                                iconOnly
                                                onClick={() => {
                                                  setSelectedInscripcion(inscripcion);
                                                  setOpenModalCertificado(
                                                    "Certificado de Preinscripción"
                                                  );
                                                }}
                                                disabled={loadingBaja}
                                              >
                                                <Icon>description</Icon>
                                              </SoftButton>
                                            </Tooltip>
                                          </SoftBox>
                                        )}
                                      {(inscripcion?.tipo === "Inscripto" ||
                                        inscripcion?.tipo === "Inscripto Condicional") &&
                                        inscripcion?.carrera?.tipo === "Carrera" && (
                                          <SoftBox>
                                            <Tooltip
                                              title={"Generar certificado de inscripción"}
                                              placement="top"
                                            >
                                              <SoftButton
                                                color="uproGreen"
                                                circular
                                                iconOnly
                                                onClick={() => {
                                                  setSelectedInscripcion(inscripcion);
                                                  setOpenModalCertificado(
                                                    "Certificado de Inscripción"
                                                  );
                                                }}
                                                disabled={loadingBaja}
                                              >
                                                <Icon>description</Icon>
                                              </SoftButton>
                                            </Tooltip>
                                          </SoftBox>
                                        )}
                                      {certificados.find(
                                        (c) => c.carrera?.id === inscripcion.carrera.id
                                      ) && (
                                        <SoftBox>
                                          <Tooltip title={"Ver certificados"} placement="top">
                                            <SoftButton
                                              color="uproBlue"
                                              circular
                                              iconOnly
                                              onClick={() => {
                                                setSelectedInscripcion(inscripcion);
                                                setOpenModalCertificados(true);
                                              }}
                                            >
                                              <Icon>folder_shared</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      )}
                                      {inscripcion?.tipo !== "Aprobado" && (
                                        <SoftBox>
                                          <Tooltip title={"Dar de baja"} placement="top">
                                            <SoftButton
                                              color="error"
                                              circular
                                              iconOnly
                                              onClick={() => {
                                                setSelectedInscripcion(inscripcion);
                                                setOpenModalDelete(true);
                                              }}
                                              disabled={loadingBaja}
                                            >
                                              {loadingBaja &&
                                              selectedInscripcion?.id === inscripcion.id ? (
                                                <CircularProgress
                                                  size={15}
                                                  sx={{
                                                    marginRight: 0.5,
                                                  }}
                                                  color="white"
                                                />
                                              ) : (
                                                <Icon>arrow_downward</Icon>
                                              )}
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      )}
                                      {(inscripcion?.tipo === "Preinscripto" ||
                                        inscripcion?.tipo === "Respaldo" ||
                                        inscripcion?.tipo === "Desaprobado") &&
                                        !inscripcion?.carrera?.nombre?.includes("2016") &&
                                        !inscripcion?.carrera?.nombre?.includes("2020") && (
                                          <Tooltip
                                            title={
                                              inscripcion?.carrera?.tipo === "Carrera"
                                                ? !usuario?.usuario?.inscripciones.find(
                                                    (i) =>
                                                      (i.tipo === "Aprobado" ||
                                                        i.tipo === "Inscripto" ||
                                                        i.tipo === "Inscripto Condicional") &&
                                                      i.carrera.id === "1"
                                                  )
                                                  ? "Debe tener el curso de ingreso aprobado o estar cursando el curso para inscribirse"
                                                  : usuario?.usuario?.inscripciones.find(
                                                      (i) =>
                                                        (i.tipo === "Inscripto" ||
                                                          i.tipo === "Inscripto Condicional") &&
                                                        i.carrera.tipo === "Carrera"
                                                    )
                                                  ? "Ya se encuentra inscripto a una carrera"
                                                  : !documentacionRequerida.every((doc) =>
                                                      usuario?.usuario?.documentos.find(
                                                        (d) =>
                                                          d.nombre === doc &&
                                                          d.estado === "Aprobado"
                                                      )
                                                    )
                                                  ? `Documentación incompleta, se requiere: 
                                            ${documentacionRequerida
                                              .filter(
                                                (doc) =>
                                                  !usuario?.usuario?.documentos.find(
                                                    (d) =>
                                                      d.nombre === doc && d.estado === "Aprobado"
                                                  )
                                              )
                                              .join(", ")}
                                        `
                                                  : usuario?.usuario?.inscripciones.find(
                                                      (i) =>
                                                        i.tipo === "Aprobado" &&
                                                        i.carrera.id === "1"
                                                    )
                                                  ? "Inscribir a la carrera"
                                                  : "Inscribir condicional"
                                                : !documentacionRequerida.every((doc) =>
                                                    usuario?.usuario?.documentos.find(
                                                      (d) =>
                                                        d.nombre === doc && d.estado === "Aprobado"
                                                    )
                                                  )
                                                ? `Documentación incompleta, se requiere: 
                                            ${documentacionRequerida
                                              .filter(
                                                (doc) =>
                                                  !usuario?.usuario?.documentos.find(
                                                    (d) =>
                                                      d.nombre === doc && d.estado === "Aprobado"
                                                  )
                                              )
                                              .join(", ")}
                                        `
                                                : "Inscribir"
                                            }
                                            placement="top"
                                          >
                                            <SoftBox>
                                              <SoftButton
                                                color="uproGreen"
                                                circular
                                                iconOnly
                                                disabled={
                                                  (!usuario?.usuario?.inscripciones.find(
                                                    (i) =>
                                                      (i.tipo === "Aprobado" ||
                                                        i.tipo === "Inscripto" ||
                                                        i.tipo === "Inscripto Condicional") &&
                                                      i.carrera.id === "1"
                                                  ) &&
                                                    inscripcion.carrera.tipo !==
                                                      "Curso de Ingreso") ||
                                                  !documentacionRequerida.every((doc) =>
                                                    usuario?.usuario?.documentos.find(
                                                      (d) =>
                                                        d.nombre === doc && d.estado === "Aprobado"
                                                    )
                                                  ) ||
                                                  usuario?.usuario?.inscripciones.find(
                                                    (i) =>
                                                      (i.tipo === "Inscripto" ||
                                                        i.tipo === "Inscripto Condicional") &&
                                                      i.carrera.tipo === "Carrera"
                                                  )
                                                }
                                                onClick={
                                                  inscripcion?.carrera?.tipo === "Carrera"
                                                    ? usuario?.usuario?.inscripciones.find(
                                                        (i) =>
                                                          i.tipo === "Aprobado" &&
                                                          i.carrera.id === "1"
                                                      )
                                                      ? () => {
                                                          setSelectedInscripcion(inscripcion);
                                                          setOpenModalCupos(true);
                                                        }
                                                      : () => {
                                                          confirmar({
                                                            icon: "warning",
                                                            title: "Inscribir condicional",
                                                            message: `El alumno no ha aprobado el curso de ingreso ¿Desea inscribir al alumno ${makeNiceText(
                                                              ` ${usuario?.usuario?.apellido} ${usuario?.usuario?.nombre}`,
                                                              "nombre"
                                                            )} a la carrera ${makeNiceText(
                                                              inscripcion?.carrera?.nombre,
                                                              "nombre"
                                                            )}?`,
                                                            pedirMotivo: false,
                                                            buttonText: "Continuar",
                                                            func: () => {
                                                              setSelectedInscripcion(inscripcion);
                                                              setOpenModalCupos(true);
                                                            },
                                                          });
                                                        }
                                                    : () => {
                                                        setSelectedInscripcion(inscripcion);
                                                        setOpenModalInscripcionComision(true);
                                                      }
                                                }
                                              >
                                                <Icon>done</Icon>
                                              </SoftButton>
                                            </SoftBox>
                                          </Tooltip>
                                        )}

                                      {(inscripcion?.carrera?.nombre?.includes("2016") ||
                                        inscripcion?.carrera?.nombre?.includes("2020")) &&
                                        inscripcion?.tipo === "Preinscripto" && (
                                          <Tooltip title="Inscribir a la carrera" placement="top">
                                            <SoftBox>
                                              <SoftButton
                                                color="uproGreen"
                                                circular
                                                iconOnly
                                                onClick={() => {
                                                  setSelectedInscripcion(inscripcion);
                                                  setOpenModalCupos(true);
                                                }}
                                              >
                                                <Icon>done</Icon>
                                              </SoftButton>
                                            </SoftBox>
                                          </Tooltip>
                                        )}
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            ))
                        ) : (
                          <Grid container spacing={2} p={2}>
                            <SinDatos />
                          </Grid>
                        )}
                      </Collapse>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        px={2}
                        py={1}
                        sx={{
                          borderBottom: !expanded.includes("Inscripciones Históricas")
                            ? "none"
                            : "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                        onClick={(e) => {
                          setExpanded(
                            expanded.includes("Inscripciones Históricas")
                              ? expanded.filter((i) => i !== "Inscripciones Históricas")
                              : [...expanded, "Inscripciones Históricas"]
                          );
                        }}
                      >
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon
                              sx={{
                                color: getUproColor("uproGreen"),
                              }}
                            >
                              history
                            </Icon>
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            Inscripciones Históricas
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                          <SoftBox>
                            <Tooltip
                              title={
                                expanded.includes("Inscripciones Históricas")
                                  ? "Ocultar"
                                  : "Mostrar"
                              }
                              placement="top"
                            >
                              <SoftButton
                                color="primary"
                                circular
                                iconOnly
                                onClick={() =>
                                  setExpanded(
                                    expanded.includes("Inscripciones Históricas")
                                      ? expanded.filter((i) => i !== "Inscripciones Históricas")
                                      : [...expanded, "Inscripciones Históricas"]
                                  )
                                }
                              >
                                <Icon
                                  sx={{
                                    rotate: expanded.includes("Inscripciones Históricas")
                                      ? "180deg"
                                      : "0deg",
                                    transition: "all 0.5s",
                                  }}
                                >
                                  expand_more
                                </Icon>
                              </SoftButton>
                            </Tooltip>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      <Collapse in={expanded.includes("Inscripciones Históricas")}>
                        {usuario?.usuario?.inscripciones?.filter((i) => i.tipo === "Baja").length >
                        0 ? (
                          usuario?.usuario?.inscripciones
                            ?.filter((i) => i.tipo === "Baja")
                            .map((inscripcion, index) => (
                              <SoftBox key={inscripcion.id}>
                                <SoftBox
                                  px={2}
                                  py={1}
                                  sx={{
                                    borderBottom:
                                      usuario?.usuario?.inscripciones?.filter(
                                        (i) => i.tipo === "Baja"
                                      ).length -
                                        1 ===
                                      index
                                        ? "none"
                                        : "1px solid rgba(0, 0, 0, 0.12)",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  <SoftBox display="flex" justifyContent="space-between">
                                    <SoftBox display="flex" alignItems="center" pl={2}>
                                      <SoftBox mr={1}>
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproPink"),
                                          }}
                                        >
                                          subdirectory_arrow_right
                                        </Icon>
                                      </SoftBox>
                                      <SoftBox mr={1}>
                                        <Tooltip title={inscripcion?.carrera?.tipo} placement="top">
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproYellow"),
                                            }}
                                          >
                                            {inscripcion?.carrera?.tipo === "Carrera"
                                              ? "history_edu"
                                              : "auto_stories"}
                                          </Icon>
                                        </Tooltip>
                                      </SoftBox>
                                      <SoftTypography variant="h6">{`${inscripcion?.carrera?.codigo} - ${inscripcion?.carrera?.nombre}`}</SoftTypography>
                                      <SoftBox ml={1} display="flex" alignItems="center">
                                        <SoftBadge
                                          color={
                                            inscripcion?.tipo === "Preinscripto"
                                              ? "uproYellow"
                                              : inscripcion?.tipo === "Inscripto" ||
                                                inscripcion?.tipo === "Inscripto Condicional"
                                              ? "uproGreen"
                                              : "primary"
                                          }
                                          badgeContent={`${inscripcion?.tipo}`}
                                        />
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            ))
                        ) : (
                          <Grid container spacing={2} p={2}>
                            <SinDatos />
                          </Grid>
                        )}
                      </Collapse>
                    </Card>
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
            <ModalDelete
              open={openModalDelete}
              handleClose={() => setOpenModalDelete(false)}
              accion="dar de baja"
              texto="Presione dar de baja para confrimar"
              nombre={`la inscripción a ${selectedInscripcion?.carrera?.codigo} - ${selectedInscripcion?.carrera?.nombre}`}
              functionDelete={() => {
                bajaInscripcion({ variables: { idInscripcion: selectedInscripcion.id } })
                  .then(() => {
                    handleSnackbar("Inscripción dada de baja correctamente", "success");
                    refetchUsuario();
                    refetch();
                    setSelectedInscripcion(null);
                    setOpenModalDelete(false);
                  })
                  .catch(() => {
                    handleSnackbar("Error al dar de baja la inscripción", "error");
                    setOpenModalDelete(false);
                  });
              }}
            />
          </SoftBox>
          <ModalNewInscripcion
            open={openModalAdd}
            handleClose={() => setOpenModalAdd(false)}
            idAlumno={idUsuario}
            refetch={() => {
              refetchUsuario();
              refetch();
            }}
            tipo={tipoInscripcion}
            inscripciones={usuario?.usuario?.inscripciones}
          />
          <ModalCertificado
            open={openModalCertificado}
            handleClose={() => {
              setOpenModalCertificado();
              getCertificados({
                variables: {
                  filter: {
                    idUser: idUsuario,
                  },
                },
              }).then((data) => {
                setCertificados(data.data.certificados || []);
              });
            }}
            usuario={usuario?.usuario}
            carrera={
              usuario?.usuario?.inscripciones?.find((i) => i.id === selectedInscripcion?.id)
                ?.carrera
            }
            cupo={
              usuario?.usuario?.inscripciones?.find((i) => i.id === selectedInscripcion?.id)?.cupo
            }
            tipo={openModalCertificado}
          />
          <ModalInscripcionComision
            open={openModalInscripcionComision}
            handleClose={() => {
              setOpenModalInscripcionComision(false);
              setSelectedInscripcion(null);
            }}
            alumno={usuario?.usuario}
            carrera={selectedInscripcion?.carrera}
            idInscripcion={selectedInscripcion?.id}
            refetch={() => {
              refetchUsuario();
              refetch();
              getCertificados({
                variables: {
                  filter: {
                    idUser: idUsuario,
                  },
                },
              }).then((data) => {
                setCertificados(data.data.certificados || []);
              });
            }}
          />
          <ModalCupos
            open={openModalCupos}
            handleClose={() => {
              setOpenModalCupos(false);
              setSelectedInscripcion(null);
              refetchUsuario();
              refetch();
            }}
            inscripcion={selectedInscripcion}
          />
          <ModalCertificados
            open={openModalCertificados}
            handleClose={() => {
              setOpenModalCertificados(false);
              setSelectedInscripcion(null);
            }}
            idAlumno={idUsuario}
            idCarrera={selectedInscripcion?.carrera?.id}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalInscripciones.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idUsuario: PropTypes.string,
  openModalDocumentacion: PropTypes.bool,
  handleOpenDocumentacion: PropTypes.func,
  refetch: PropTypes.func,
};
