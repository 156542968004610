// @mui material components
import { Card, CircularProgress, Collapse, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import ModalCertificadoMateria from "layouts/autogestion/components/ModalCertificado/Materia";
import ModalComisionDetalles from "layouts/autogestion/components/ModalComisionDetalles";
import ModalInscripcionNivel from "layouts/autogestion/components/ModalInscripcionNivel";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import Loading from "components/Carteles/Loading";
import { UserContext } from "context/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "60%" },
  overflow: "auto",
};

export default function ModalInscripcionesMaterias({ open, handleClose, idAlumno }) {
  const [selectedInscripcion, setSelectedInscripcion] = useState(null);
  const [expandCarreras, setExpandCarreras] = useState(false);
  const [expanded, setExpanded] = useState(["Unidades de Competencia Inscriptas"]);
  const [openModalComisionDetalles, setOpenModalComisionDetalles] = useState(false);
  const [openModalCertificadoMateria, setOpenModalCertificadoMateria] = useState(false);
  const [openModalInscripcionNivel, setOpenModalInscripcionNivel] = useState(false);
  const [selectedCarrera, setSelectedCarrera] = useState(null);
  const [selectedNivel, setSelectedNivel] = useState(null);
  const { menu } = useContext(MenuContext);
  const [selectedComision, setSelectedComision] = useState(null);
  const [expandedInscripcion, setExpandedInscripcion] = useState(null);
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);

  const [updateInscripcionComisionEstado, { loading: loadingUpdate }] = useMutation(
    gql`
      mutation updateInscripcionComisionEstado($idInscripcion: ID!, $estado: String!) {
        updateInscripcionComisionEstado(idInscripcion: $idInscripcion, estado: $estado) {
          id
          estado
        }
      }
    `
  );

  const [getUser, { data, loading }] = useLazyQuery(
    gql`
      query getUser($id: ID!) {
        usuario(id: $id) {
          id
          tipoUser
          username
          nombre
          apellido
          dni
          inscripciones {
            id
            sede {
              id
              codigo
              nombre
            }
            carrera {
              id
              tipo
              nombre
              codigo
              tipo
              planes {
                id
                codigo
                nombre
                niveles {
                  id
                  codigo
                  nombre
                  materias {
                    id
                    codigo
                    nombre
                    comisiones {
                      id
                      codigo
                      nombre
                    }
                  }
                }
              }
            }
            tipo
            createdAt
            cupo {
              nombre
              sede {
                nombre
              }
            }
          }
          inscripcionesComisiones {
            id
            estado
            comision {
              id
              nombre
              codigo
              color
              nivel
              fechaInicio
              fechaFin
              sede {
                id
                nombre
                aulas {
                  id
                  nombre
                  capacidad
                }
              }
              docente {
                id
                nombre
                apellido
              }
              horarios {
                dia
                aula {
                  id
                  nombre
                }
                capacidad
                horarioInicio
                horarioFin
              }
              materia {
                id
                nombre
                codigo
                nivel {
                  id
                  planEstudio {
                    carrera {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (idAlumno && open) {
      getUser({ variables: { id: idAlumno } });
    }
  }, [idAlumno, open]);

  useEffect(() => {
    if (data?.usuario?.inscripciones.length > 0) {
      setSelectedCarrera(
        data?.usuario.inscripciones.find(
          (inscripcion) =>
            inscripcion.tipo === "Inscripto" && inscripcion.carrera.tipo === "Carrera"
        )?.carrera ||
          data?.usuario.inscripciones.find(
            (inscripcion) =>
              inscripcion.tipo === "Inscripto Condicional" && inscripcion.carrera.tipo === "Carrera"
          )?.carrera ||
          data?.usuario.inscripciones.find((inscripcion) => inscripcion.tipo === "Inscripto")
            ?.carrera ||
          data?.usuario.inscripciones.find(
            (inscripcion) => inscripcion.tipo === "Inscripto Condicional"
          )?.carrera ||
          data?.usuario.inscripciones.find((inscripcion) => inscripcion.tipo === "Aprobado")
            ?.carrera ||
          data?.usuario.inscripciones.find((inscripcion) => inscripcion.tipo === "Libre")
            ?.carrera ||
          null
      );
      setSelectedInscripcion(
        data?.usuario.inscripciones.find(
          (inscripcion) =>
            inscripcion.tipo === "Inscripto" && inscripcion.carrera.tipo === "Carrera"
        ) ||
          data?.usuario.inscripciones.find(
            (inscripcion) =>
              inscripcion.tipo === "Inscripto Condicional" && inscripcion.carrera.tipo === "Carrera"
          ) ||
          data?.usuario.inscripciones.find((inscripcion) => inscripcion.tipo === "Inscripto") ||
          data?.usuario.inscripciones.find(
            (inscripcion) => inscripcion.tipo === "Inscripto Condicional"
          ) ||
          data?.usuario.inscripciones.find((inscripcion) => inscripcion.tipo === "Aprobado") ||
          data?.usuario.inscripciones.find((inscripcion) => inscripcion.tipo === "Libre") ||
          null
      );
    }
  }, [data]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox
              display={{
                xs: "none",
                sm: "flex",
              }}
              justifyContent="space-between"
              alignItems="center"
              p={3}
              pb={0}
            >
              <SoftTypography variant="h6">Gestionar inscripciones a unidades</SoftTypography>
              <SoftBox display="flex" justifyContent="end" alignItems="center" gap={2}>
                <SoftButton
                  id="botonCarrera"
                  color="uproYellow"
                  circular
                  sx={{
                    textWrap: "nowrap",
                  }}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={(event) => {
                    setExpandCarreras(true);
                    menu({
                      open: event.currentTarget,
                      align: "right",
                      onClose: () => {
                        setExpandCarreras(false);
                      },
                      options: data?.usuario?.inscripciones
                        .filter(
                          (inscripcion) =>
                            inscripcion.tipo === "Inscripto" ||
                            inscripcion.tipo === "Inscripto Condicional" ||
                            inscripcion.tipo === "Preinscripto" ||
                            inscripcion.tipo === "Libre" ||
                            inscripcion.tipo === "Aprobado"
                        )
                        .map((inscripcion) => ({
                          name: makeNiceText(inscripcion.carrera.nombre, "nombre"),
                          disabled: inscripcion.tipo === "Preinscripto",
                          icon: {
                            icon: inscripcion.carrera.tipo === "Carrera" ? "school" : "menu_book",
                            color:
                              selectedCarrera?.id === inscripcion.carrera.id
                                ? getUproColor("uproYellow")
                                : getUproColor("uproGreen"),
                          },
                          onClick: () => {
                            setSelectedCarrera(inscripcion.carrera);
                            setSelectedInscripcion(inscripcion);
                          },
                        })),
                    });
                  }}
                >
                  <Icon>school</Icon>
                  &nbsp;{selectedCarrera?.nombre || "Seleccione una carrera"}&nbsp;
                  <Icon
                    sx={{
                      rotate: expandCarreras ? "180deg" : "0deg",
                      transition: "all 0.5s",
                    }}
                  >
                    expand_more
                  </Icon>
                </SoftButton>
                <Tooltip title="Cerrar" placement="top">
                  <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                    close
                  </Icon>
                </Tooltip>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex",
                sm: "none",
              }}
              flexDirection="column"
              p={3}
              pb={0}
              gap={2}
            >
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="h6">Gestionar inscripciones a unidades</SoftTypography>
                <Tooltip title="Cerrar" placement="top">
                  <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                    close
                  </Icon>
                </Tooltip>
              </SoftBox>
              <SoftButton
                id="botonCarrera"
                color="uproYellow"
                circular
                sx={{
                  textWrap: "nowrap",
                }}
                fullWidth
                onClick={(event) => {
                  setExpandCarreras(true);
                  menu({
                    open: event.currentTarget,
                    align: "right",
                    onClose: () => {
                      setExpandCarreras(false);
                    },
                    options: data?.usuario?.inscripciones
                      .filter(
                        (inscripcion) =>
                          inscripcion.tipo === "Inscripto" ||
                          inscripcion.tipo === "Inscripto Condicional" ||
                          inscripcion.tipo === "Preinscripto" ||
                          inscripcion.tipo === "Libre" ||
                          inscripcion.tipo === "Aprobado"
                      )
                      .map((inscripcion) => ({
                        name: makeNiceText(inscripcion.carrera.nombre, "nombre"),
                        disabled: inscripcion.tipo === "Preinscripto",
                        icon: {
                          icon: inscripcion.carrera.tipo === "Carrera" ? "school" : "menu_book",
                          color:
                            selectedCarrera?.id === inscripcion.carrera.id
                              ? getUproColor("uproYellow")
                              : getUproColor("uproGreen"),
                        },
                        onClick: () => {
                          setSelectedCarrera(inscripcion.carrera);
                          setSelectedInscripcion(inscripcion);
                        },
                      })),
                  });
                }}
              >
                <Icon>school</Icon>
                &nbsp;{selectedCarrera?.nombre || "Seleccione una carrera"}&nbsp;
                <Icon
                  sx={{
                    rotate: expandCarreras ? "180deg" : "0deg",
                    transition: "all 0.5s",
                  }}
                >
                  expand_more
                </Icon>
              </SoftButton>
            </SoftBox>
            <SoftBox p={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    id="unidadesInscriptas"
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Unidades de Competencia Inscriptas")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Unidades de Competencia Inscriptas")
                            ? expanded.filter((i) => i !== "Unidades de Competencia Inscriptas")
                            : [...expanded, "Unidades de Competencia Inscriptas"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">assignment_turned_in</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Unidades de Competencia inscriptas
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={
                              expanded.includes("Unidades de Competencia Inscriptas")
                                ? "Ocultar"
                                : "Mostrar"
                            }
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Unidades de Competencia Inscriptas")
                                    ? expanded.filter(
                                        (i) => i !== "Unidades de Competencia Inscriptas"
                                      )
                                    : [...expanded, "Unidades de Competencia Inscriptas"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Unidades de Competencia Inscriptas")
                                    ? "180deg"
                                    : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Unidades de Competencia Inscriptas")}>
                      {loading || loadingUpdate ? (
                        <Loading />
                      ) : data?.usuario?.inscripcionesComisiones?.filter(
                          (item) =>
                            item?.comision?.materia?.nivel?.planEstudio?.carrera?.id ===
                            selectedInscripcion?.carrera?.id
                        )?.length > 0 ? (
                        data?.usuario?.inscripcionesComisiones
                          ?.filter(
                            (item) =>
                              item?.comision?.materia?.nivel?.planEstudio?.carrera?.id ===
                              selectedInscripcion?.carrera?.id
                          )
                          ?.map((item, index) => (
                            <SoftBox key={item.id}>
                              <SoftBox
                                px={2}
                                py={1}
                                sx={{
                                  borderBottom:
                                    data?.usuario?.inscripcionesComisiones?.filter(
                                      (item) =>
                                        item?.comision?.materia?.nivel?.planEstudio?.carrera?.id ===
                                        selectedInscripcion?.carrera?.id
                                    )?.length -
                                      1 ===
                                    index
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  },
                                }}
                              >
                                <SoftBox
                                  display={{
                                    xs: "flex-row",
                                    sm: "flex",
                                  }}
                                  justifyContent="space-between"
                                  gap={2}
                                >
                                  <SoftBox
                                    display={{
                                      xs: "flex-row",
                                      sm: "flex",
                                    }}
                                    alignItems="center"
                                    pl={{
                                      xs: 0,
                                      sm: 2,
                                    }}
                                  >
                                    <SoftBox
                                      mr={1}
                                      sx={{
                                        display: {
                                          xs: "none",
                                          sm: "block",
                                        },
                                      }}
                                    >
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproPink"),
                                        }}
                                      >
                                        subdirectory_arrow_right
                                      </Icon>
                                    </SoftBox>
                                    <SoftBox
                                      mr={1}
                                      sx={{
                                        display: {
                                          xs: "none",
                                          sm: "block",
                                        },
                                      }}
                                    >
                                      <Tooltip title="Nivel" placement="top">
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproGreen"),
                                          }}
                                        >
                                          book
                                        </Icon>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftTypography variant="h6">{`${item.comision.materia.codigo} - ${item.comision.materia.nombre}`}</SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    display={{
                                      xs: "none",
                                      sm: "flex",
                                    }}
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Tooltip title="Estado de la inscripción" placement="top">
                                      <SoftBox>
                                        <SoftButton
                                          circular
                                          color={
                                            item.estado === "Inscripto"
                                              ? "uproYellow"
                                              : item.estado === "Aprobado"
                                              ? "uproGreen"
                                              : item.estado === "Desaprobado"
                                              ? "uproPink"
                                              : item.estado === "Libre"
                                              ? "uproBlue"
                                              : "uproPink"
                                          }
                                          disabled={loadingUpdate}
                                          onClick={(event) => {
                                            if (verificarAcceso(31)) {
                                              setExpandedInscripcion(
                                                expandedInscripcion === item.id ? null : item.id
                                              );
                                              menu({
                                                open: event.currentTarget,
                                                align: "right",
                                                onClose: () => {
                                                  setExpandedInscripcion(null);
                                                },
                                                options: [
                                                  {
                                                    name: "Inscripto",
                                                    disabled: item.estado === "Inscripto",
                                                    icon: {
                                                      icon: "done",
                                                      color: getUproColor("uproYellow"),
                                                    },
                                                    onClick: () => {
                                                      updateInscripcionComisionEstado({
                                                        variables: {
                                                          idInscripcion: item.id,
                                                          estado: "Inscripto",
                                                        },
                                                      })
                                                        .then(() => {
                                                          handleSnackbar(
                                                            "Inscripción actualizada",
                                                            "success"
                                                          );
                                                          getUser({ variables: { id: idAlumno } });
                                                        })
                                                        .catch((error) => {
                                                          handleSnackbar(
                                                            error.message ||
                                                              "Ha ocurrido un error al actualizar la inscripción",
                                                            "error"
                                                          );
                                                        });
                                                    },
                                                  },
                                                  {
                                                    name: "Aprobado",
                                                    disabled: item.estado === "Aprobado",
                                                    icon: {
                                                      icon: "thumb_up",
                                                      color: getUproColor("uproGreen"),
                                                    },
                                                    onClick: () => {
                                                      updateInscripcionComisionEstado({
                                                        variables: {
                                                          idInscripcion: item.id,
                                                          estado: "Aprobado",
                                                        },
                                                      })
                                                        .then(() => {
                                                          handleSnackbar(
                                                            "Inscripción actualizada",
                                                            "success"
                                                          );
                                                          getUser({ variables: { id: idAlumno } });
                                                        })
                                                        .catch((error) => {
                                                          handleSnackbar(
                                                            error.message ||
                                                              "Ha ocurrido un error al actualizar la inscripción",
                                                            "error"
                                                          );
                                                        });
                                                    },
                                                  },
                                                  {
                                                    name: "Desaprobado",
                                                    disabled: item.estado === "Desaprobado",
                                                    icon: {
                                                      icon: "thumb_down",
                                                      color: getUproColor("uproPink"),
                                                    },
                                                    onClick: () => {
                                                      updateInscripcionComisionEstado({
                                                        variables: {
                                                          idInscripcion: item.id,
                                                          estado: "Desaprobado",
                                                        },
                                                      })
                                                        .then(() => {
                                                          handleSnackbar(
                                                            "Inscripción actualizada",
                                                            "success"
                                                          );
                                                          getUser({ variables: { id: idAlumno } });
                                                        })
                                                        .catch((error) => {
                                                          handleSnackbar(
                                                            error.message ||
                                                              "Ha ocurrido un error al actualizar la inscripción",
                                                            "error"
                                                          );
                                                        });
                                                    },
                                                  },
                                                  {
                                                    name: "Libre",
                                                    disabled: item.estado === "Libre",
                                                    icon: {
                                                      icon: "lock_open",
                                                      color: getUproColor("uproBlue"),
                                                    },
                                                    onClick: () => {
                                                      updateInscripcionComisionEstado({
                                                        variables: {
                                                          idInscripcion: item.id,
                                                          estado: "Libre",
                                                        },
                                                      })
                                                        .then(() => {
                                                          handleSnackbar(
                                                            "Inscripción actualizada",
                                                            "success"
                                                          );
                                                          getUser({ variables: { id: idAlumno } });
                                                        })
                                                        .catch((error) => {
                                                          handleSnackbar(
                                                            error.message ||
                                                              "Ha ocurrido un error al actualizar la inscripción",
                                                            "error"
                                                          );
                                                        });
                                                    },
                                                  },
                                                  {
                                                    name: "Eliminar",
                                                    icon: {
                                                      icon: "delete",
                                                      color: getUproColor("uproPink"),
                                                    },
                                                    onClick: () => {
                                                      updateInscripcionComisionEstado({
                                                        variables: {
                                                          idInscripcion: item.id,
                                                          estado: "Eliminado",
                                                        },
                                                      })
                                                        .then(() => {
                                                          handleSnackbar(
                                                            "Inscripción eliminada con éxito",
                                                            "success"
                                                          );
                                                          getUser({ variables: { id: idAlumno } });
                                                        })
                                                        .catch((error) => {
                                                          handleSnackbar(
                                                            error.message ||
                                                              "Ha ocurrido un error al eliminar la inscripción",
                                                            "error"
                                                          );
                                                        });
                                                    },
                                                  },
                                                ],
                                              });
                                            } else {
                                              handleSnackbar(
                                                "No tienes permisos para realizar esta acción",
                                                "error"
                                              );
                                            }
                                          }}
                                        >
                                          {item.estado}
                                          <Icon
                                            sx={{
                                              rotate:
                                                expandedInscripcion === item.id ? "180deg" : "0deg",
                                              transition: "all 0.5s",
                                            }}
                                          >
                                            expand_more
                                          </Icon>
                                        </SoftButton>
                                      </SoftBox>
                                    </Tooltip>
                                    <Tooltip title="Generar comprobante" placement="top">
                                      <SoftBox>
                                        <SoftButton
                                          circular
                                          iconOnly
                                          color="uproYellow"
                                          onClick={() => {
                                            setSelectedComision(item.comision);
                                            setOpenModalCertificadoMateria(true);
                                          }}
                                        >
                                          <Icon>download</Icon>
                                        </SoftButton>
                                      </SoftBox>
                                    </Tooltip>
                                    <Tooltip title="Ver detalles" placement="top">
                                      <SoftBox>
                                        <SoftButton
                                          circular
                                          iconOnly
                                          color="uproGreen"
                                          onClick={() => {
                                            setSelectedComision(item.comision);
                                            setOpenModalComisionDetalles(true);
                                          }}
                                        >
                                          <Icon>visibility</Icon>
                                        </SoftButton>
                                      </SoftBox>
                                    </Tooltip>
                                  </SoftBox>
                                  <SoftBox
                                    display={{
                                      xs: "flex",
                                      sm: "none",
                                    }}
                                    justifyContent="center"
                                    alignItems="center"
                                    my={2}
                                  >
                                    <Tooltip title="Ver detalles" placement="top">
                                      <SoftBox
                                        sx={{
                                          width: "100%",
                                        }}
                                      >
                                        <SoftButton
                                          circular
                                          fullWidth
                                          color="uproGreen"
                                          onClick={() => {
                                            setSelectedComision(item.comision);
                                            setOpenModalComisionDetalles(true);
                                          }}
                                        >
                                          <Icon>visibility</Icon>
                                          &nbsp;Ver detalles
                                        </SoftButton>
                                      </SoftBox>
                                    </Tooltip>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          ))
                      ) : (
                        <Grid container spacing={2} p={2}>
                          <SinDatos />
                        </Grid>
                      )}
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    id="unidadesDisponibles"
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Unidad de Competencia Disponibles")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Unidad de Competencia Disponibles")
                            ? expanded.filter((i) => i !== "Unidad de Competencia Disponibles")
                            : [...expanded, "Unidad de Competencia Disponibles"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproGreen"),
                            }}
                          >
                            assignment
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Niveles y unidades disponibles para inscripción
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={
                              expanded.includes("Unidad de Competencia Disponibles")
                                ? "Ocultar"
                                : "Mostrar"
                            }
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Unidad de Competencia Disponibles")
                                    ? expanded.filter(
                                        (i) => i !== "Unidad de Competencia Disponibles"
                                      )
                                    : [...expanded, "Unidad de Competencia Disponibles"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Unidad de Competencia Disponibles")
                                    ? "180deg"
                                    : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Unidad de Competencia Disponibles")}>
                      {selectedInscripcion?.carrera?.planes[
                        selectedInscripcion?.carrera?.planes?.length - 1
                      ]?.niveles.length > 0 && selectedInscripcion?.carrera?.id != 1 ? (
                        selectedInscripcion?.carrera?.planes[
                          selectedInscripcion?.carrera?.planes?.length - 1
                        ]?.niveles.map((nivel, index) => (
                          <SoftBox key={nivel.id}>
                            <SoftBox
                              px={2}
                              py={1}
                              sx={{
                                borderBottom:
                                  selectedInscripcion?.carrera?.planes[0]?.niveles.length - 1 ===
                                  index
                                    ? "none"
                                    : "1px solid rgba(0, 0, 0, 0.12)",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                            >
                              <SoftBox
                                display={{
                                  xs: "flex-row",
                                  sm: "flex",
                                }}
                                justifyContent="space-between"
                              >
                                <SoftBox
                                  display={{
                                    xs: "flex-row",
                                    sm: "flex",
                                  }}
                                  alignItems="center"
                                  pl={{
                                    xs: 0,
                                    sm: 2,
                                  }}
                                >
                                  <SoftBox
                                    mr={1}
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "block",
                                      },
                                    }}
                                  >
                                    <Icon
                                      sx={{
                                        color: getUproColor("uproPink"),
                                      }}
                                    >
                                      subdirectory_arrow_right
                                    </Icon>
                                  </SoftBox>
                                  <SoftBox
                                    mr={1}
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "block",
                                      },
                                    }}
                                  >
                                    <Tooltip title="Nivel" placement="top">
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproYellow"),
                                        }}
                                      >
                                        article
                                      </Icon>
                                    </Tooltip>
                                  </SoftBox>
                                  <SoftTypography variant="h6">{`${nivel.codigo} - ${nivel.nombre}`}</SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  display={{
                                    xs: "none",
                                    sm: "flex",
                                  }}
                                  justifyContent="flex-end"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <Tooltip
                                    title={
                                      data?.usuario?.inscripcionesComisiones?.filter(
                                        (item) => item?.comision?.materia?.nivel?.id === nivel.id
                                      ).length === nivel.materias.length
                                        ? "Ya se encuentra inscripto"
                                        : "Inscribirse"
                                    }
                                    placement="top"
                                  >
                                    <SoftBox>
                                      <SoftButton
                                        circular
                                        color="uproGreen"
                                        onClick={() => {
                                          setSelectedNivel(nivel);
                                          setOpenModalInscripcionNivel(true);
                                        }}
                                        disabled={
                                          data?.usuario?.inscripcionesComisiones?.filter(
                                            (item) =>
                                              item?.comision?.materia?.nivel?.id === nivel.id
                                          ).length === nivel.materias.length
                                        }
                                      >
                                        {data?.usuario?.inscripcionesComisiones?.filter(
                                          (item) => item?.comision?.materia?.nivel?.id === nivel.id
                                        ).length === nivel.materias.length
                                          ? "Inscripto"
                                          : "Inscribirse"}
                                        &nbsp;
                                        <Icon>
                                          {data?.usuario?.inscripcionesComisiones?.filter(
                                            (item) =>
                                              item?.comision?.materia?.nivel?.id === nivel.id
                                          ).length === nivel.materias.length
                                            ? "done"
                                            : "arrow_forward"}
                                        </Icon>
                                      </SoftButton>
                                    </SoftBox>
                                  </Tooltip>
                                </SoftBox>
                                <SoftBox
                                  display={{
                                    xs: "flex",
                                    sm: "none",
                                  }}
                                  justifyContent="center"
                                  alignItems="center"
                                  my={2}
                                >
                                  <Tooltip
                                    title={
                                      data?.usuario?.inscripcionesComisiones?.filter(
                                        (item) => item?.comision?.materia?.nivel?.id === nivel.id
                                      ).length === nivel.materias.length
                                        ? "Ya se encuentra inscripto"
                                        : "Inscribirse"
                                    }
                                    placement="top"
                                  >
                                    <SoftBox
                                      sx={{
                                        width: "100%",
                                      }}
                                    >
                                      <SoftButton
                                        circular
                                        fullWidth
                                        color="uproGreen"
                                        onClick={() => {
                                          setSelectedNivel(nivel);
                                          setOpenModalInscripcionNivel(true);
                                        }}
                                        disabled={
                                          data?.usuario?.inscripcionesComisiones?.filter(
                                            (item) =>
                                              item?.comision?.materia?.nivel?.id === nivel.id
                                          ).length === nivel.materias.length
                                        }
                                      >
                                        {data?.usuario?.inscripcionesComisiones?.filter(
                                          (item) => item?.comision?.materia?.nivel?.id === nivel.id
                                        ).length === nivel.materias.length
                                          ? "Inscripto"
                                          : "Inscribirse"}
                                        &nbsp;
                                        <Icon>
                                          {data?.usuario?.inscripcionesComisiones?.filter(
                                            (item) =>
                                              item?.comision?.materia?.nivel?.id === nivel.id
                                          ).length === nivel.materias.length
                                            ? "done"
                                            : "arrow_forward"}
                                        </Icon>
                                      </SoftButton>
                                    </SoftBox>
                                  </Tooltip>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ))
                      ) : (
                        <Grid container spacing={2} p={2}>
                          <SinDatos />
                        </Grid>
                      )}
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
            <ModalComisionDetalles
              open={openModalComisionDetalles}
              handleClose={() => setOpenModalComisionDetalles(false)}
              sede={selectedComision?.sede}
              comisiones={selectedComision ? [selectedComision] : []}
              alumno={data?.usuario}
              carrera={selectedComision?.carrera}
            />
            <ModalCertificadoMateria
              open={openModalCertificadoMateria}
              handleClose={() => setOpenModalCertificadoMateria(false)}
              comision={selectedComision}
              usuario={data?.usuario}
            />
            <ModalInscripcionNivel
              open={openModalInscripcionNivel}
              handleClose={() => {
                setOpenModalInscripcionNivel(false);
                getUser({ variables: { id: idAlumno } });
              }}
              idNivel={selectedNivel?.id}
              inscripcion={selectedInscripcion}
              alumno={data?.usuario}
            />
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalInscripcionesMaterias.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idAlumno: PropTypes.string,
};
