import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalInscripcionNivel({
  open,
  handleClose,
  idNivel,
  inscripcion,
  alumno,
  readOnly,
}) {
  const { confirmar } = useContext(ConfirmarContext);
  const { handleSnackbar } = useContext(MessageManager);
  const [getNivel, { data: nivel, loading }] = useLazyQuery(
    gql`
      query getNivel($id: ID!) {
        nivel(id: $id) {
          id
          codigo
          nombre
          materias {
            id
            codigo
            nombre
            horasTotales
            comisiones {
              id
              codigo
              nombre
              capacidad
              cantidadInscriptos
              sede {
                id
                nombre
              }
              horarios {
                dia
                aula {
                  id
                  nombre
                }
                capacidad
                horarioInicio
                horarioFin
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );
  const [comisiones, setComisiones] = useState([]);
  const [selectedComision, setSelectedComision] = useState(null);

  const [inscribirComisionesNivel, { loading: loadingInscripcion }] = useMutation(gql`
    mutation inscribirComisionesNivel($idsComisiones: [ID!]!, $idAlumno: ID!) {
      inscribirComisionesNivel(idsComisiones: $idsComisiones, idAlumno: $idAlumno) {
        id
      }
    }
  `);

  useEffect(() => {
    if (open && idNivel) {
      getNivel({ variables: { id: idNivel } });
    }
    setComisiones([]);
    setSelectedComision(null);
  }, [open, idNivel, loadingInscripcion]);

  useEffect(() => {
    if (nivel) {
      const comisionesUnicas = nivel?.nivel?.materias
        ?.map((item) =>
          item.comisiones?.filter((comision) => comision.sede?.id === inscripcion.sede?.id)
        )
        ?.flat()
        ?.filter(
          (comision, index, self) => index === self.findIndex((t) => t.codigo === comision.codigo)
        );
      setComisiones(comisionesUnicas);
    }
  }, [nivel, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox display="flex" flexDirection="column" gap={2}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {readOnly ? "Ver unidades del nivel" : "Inscribirse al nivel"}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <Card
                id="unidadesInscriptas"
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  px={2}
                  py={1}
                  sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={1} display="flex" alignItems="center">
                      <Icon
                        sx={{
                          color: getUproColor("uproYellow"),
                        }}
                      >
                        assignment
                      </Icon>
                    </SoftBox>
                    <SoftTypography variant="h6" fontWeight="bold">
                      Unidades de Competencia del Nivel
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <Collapse in={open}>
                  {loading ? (
                    <SoftBox>
                      <Loading />
                    </SoftBox>
                  ) : nivel?.nivel?.materias?.length > 0 ? (
                    nivel?.nivel?.materias?.map((item, index) => (
                      <SoftBox key={item.id}>
                        <SoftBox
                          px={2}
                          py={1}
                          sx={{
                            borderBottom:
                              nivel?.nivel?.materias?.length - 1 === index
                                ? "none"
                                : "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                          }}
                        >
                          <SoftBox
                            display={{
                              xs: "flex-row",
                              sm: "flex",
                            }}
                            justifyContent="space-between"
                          >
                            <SoftBox
                              display={{
                                xs: "flex-row",
                                sm: "flex",
                              }}
                              alignItems="center"
                              pl={{
                                xs: 0,
                                sm: 2,
                              }}
                            >
                              <SoftBox
                                mr={1}
                                sx={{
                                  display: {
                                    xs: "none",
                                    sm: "block",
                                  },
                                }}
                              >
                                <Icon
                                  sx={{
                                    color: getUproColor("uproPink"),
                                  }}
                                >
                                  subdirectory_arrow_right
                                </Icon>
                              </SoftBox>
                              <SoftBox
                                mr={1}
                                sx={{
                                  display: {
                                    xs: "none",
                                    sm: "block",
                                  },
                                }}
                              >
                                <Tooltip title="Unidad de competencia" placement="top">
                                  <Icon
                                    sx={{
                                      color: getUproColor("uproGreen"),
                                    }}
                                  >
                                    book
                                  </Icon>
                                </Tooltip>
                              </SoftBox>
                              <SoftTypography variant="h6">{`${item.codigo} - ${item.nombre}`}</SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    ))
                  ) : (
                    <Grid container spacing={2} p={2}>
                      <SinDatos />
                    </Grid>
                  )}
                </Collapse>
              </Card>
            </SoftBox>
            {!readOnly && (
              <SoftBox>
                <Select
                  fullWidth
                  variant="outlined"
                  value={selectedComision || -1}
                  onChange={(e) => {
                    setSelectedComision(e.target.value);
                  }}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione uno
                  </MenuItem>
                  {comisiones.map((comision) => (
                    <MenuItem
                      key={comision.id}
                      value={comision.id}
                      disabled={comision.cantidadInscriptos >= comision.capacidad}
                    >
                      {`${comision.horarios.map((horario) => horario.dia).join(", ")}  de 
                            ${dayjs(comision.horarios[0].horarioInicio, "HH:mm").format(
                              "HH:mm"
                            )} a ${dayjs(comision.horarios[0].horarioFin, "HH:mm").format(
                        "HH:mm"
                      )} en ${comision.horarios[0].aula.nombre} (${comision.cantidadInscriptos}/${
                        comision.capacidad
                      })`}
                    </MenuItem>
                  ))}
                </Select>
              </SoftBox>
            )}
            {!readOnly && (
              <SoftBox>
                <SoftButton
                  color="uproGreen"
                  fullWidth
                  circular
                  disabled={
                    selectedComision === -1 ||
                    !selectedComision ||
                    comisiones.length === 0 ||
                    loadingInscripcion
                  }
                  onClick={() => {
                    confirmar({
                      title: "Confirmar inscripción",
                      message: `¿Está seguro que desea inscribirse para cursar 
                    ${nivel?.nivel?.nombre} los días ${
                        comisiones.find((comision) => comision.id === selectedComision).horarios[0]
                          .dia
                      } a ${
                        comisiones.find((comision) => comision.id === selectedComision).horarios[
                          comisiones.find((comision) => comision.id === selectedComision).horarios
                            .length - 1
                        ].dia
                      } de
                    ${dayjs(
                      comisiones.find((comision) => comision.id === selectedComision).horarios[0]
                        .horarioInicio,
                      "HH:mm"
                    ).format("HH:mm")} a
                    ${dayjs(
                      comisiones.find((comision) => comision.id === selectedComision).horarios[0]
                        .horarioFin,
                      "HH:mm"
                    ).format("HH:mm")} en el aula ${
                        comisiones.find((comision) => comision.id === selectedComision).horarios[0]
                          .aula.nombre
                      }? Esta acción no se puede deshacer.`,
                      icon: "done_all",
                      func: () => {
                        const comision = comisiones.find(
                          (comision) => comision.id === selectedComision
                        );
                        const comisionesInscribir = nivel?.nivel?.materias
                          ?.map((item) =>
                            item.comisiones?.filter(
                              (c) =>
                                c.codigo === comision.codigo && c.sede?.id === inscripcion.sede?.id
                            )
                          )
                          ?.flat();
                        inscribirComisionesNivel({
                          variables: {
                            idsComisiones: comisionesInscribir.map((c) => c.id),
                            idAlumno: alumno.id,
                          },
                        })
                          .then(() => {
                            handleSnackbar("Inscripción exitosa", "success");
                            handleClose();
                          })
                          .catch((error) => {
                            handleSnackbar(error.message || "Error al inscribirse", "error");
                          });
                      },
                    });
                  }}
                >
                  Inscribirse&nbsp;
                  {loadingInscripcion ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <Icon>done</Icon>
                  )}
                </SoftButton>
              </SoftBox>
            )}
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalInscripcionNivel.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idNivel: PropTypes.number,
  inscripcion: PropTypes.object,
  alumno: PropTypes.object,
  readOnly: PropTypes.bool,
};
