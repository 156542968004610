/* eslint-disable react/prop-types */
import { useContext, useEffect, useRef } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import App from "App";
import { ErrorBoundary } from "react-error-boundary";

import { ApolloProvider, useMutation, gql } from "@apollo/client";
import { client, enableLog, enableConsole } from "./services/config";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

// Kaizen Dashboard Context Provider
import { SoftUIControllerProvider, MessageManagerProvider } from "context";
import { ChatProvider } from "context/chatContext";
import { NotificationProvider } from "context/notificationContext";
import { UserProvider } from "context/user";
import { UiProvider } from "context/ui";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { UserContext } from "context/user";
import { ApolloClientProvider } from "context/apolloClientContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if (!enableConsole) {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  console.info = function () {};
  console.debug = function () {};
}

function ErrorLog({ error }) {
  const [insertError, { data }] = useMutation(gql`
    mutation addErrorLog($errorLogInput: ErrorLogInput!) {
      addErrorLog(input: $errorLogInput) {
        id
      }
    }
  `);
  const { user } = useContext(UserContext);

  let errorObj = {
    error: error.message,
    errorInfo: {
      message: error.message,
      component: error.stack.toString().split("at ")[1].split(" (")[0],
    },
    descripcion: `Error generado automaticamente en ${window.location.href}`,
    idUser: user?.id || null,
    isAuto: true,
    data: user,
  };

  useEffect(() => {
    if (enableLog) {
      if (error && window.location.pathname !== "/error") {
        localStorage.setItem("error", JSON.stringify(errorObj));
        insertError({
          variables: {
            errorLogInput: {
              descripcion: `Error generado automaticamente en ${window.location.href}`,
              error: error.message,
              idUser: user?.id,
            },
          },
        })
          .then(() => (window.location.href = "/error"))
          .catch((e) => {
            insertError({
              variables: {
                errorLogInput: {
                  descripcion: `Error al guardar el error en el log en ${window.location.href}`,
                  error: e.message,
                },
              },
            }).then(() => (window.location.href = "/error"));
          });
      }
    }
  }, [error]);

  return null;
}

function fallbackRender({ error }) {
  return <ErrorLog error={error} />;
}

document.fonts.ready.then(() => {
  document.fonts.forEach((font) => {
    font.load();
  });
});

root.render(
  <ApolloClientProvider>
    <UserProvider>
      <ErrorBoundary fallbackRender={fallbackRender} onReset={(details) => {}}>
        <UiProvider>
          <BrowserRouter>
            <SoftUIControllerProvider>
              <MessageManagerProvider>
                <NotificationProvider>
                  <ChatProvider>
                    <App />
                  </ChatProvider>
                </NotificationProvider>
              </MessageManagerProvider>
            </SoftUIControllerProvider>
          </BrowserRouter>
        </UiProvider>
      </ErrorBoundary>
    </UserProvider>
  </ApolloClientProvider>
);

serviceWorkerRegistration.unregister();
