import { gql, useLazyQuery } from "@apollo/client";
import {
  Card,
  Fade,
  Grid,
  Icon,
  Modal,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import ModalAsistencias from "../ModalAsistencias";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
};

export default function ModalComisionDetalles({ open, handleClose, sede, comisiones, alumno }) {
  const [openModalAsistencias, setOpenModalAsistencias] = useState(false);
  const [getAsistencias, { data: asistencias }] = useLazyQuery(gql`
    query asistenciasAlumno($idAlumno: ID!, $idComision: ID!) {
      asistenciasAlumno(idAlumno: $idAlumno, idComision: $idComision, limit: 5) {
        id
        fecha
        estado
      }
    }
  `);

  useEffect(() => {
    if (open) {
      getAsistencias({
        variables: {
          idAlumno: alumno.id,
          idComision: comisiones[0].id,
        },
      });
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Boolean(open)}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3} pb={0}>
              <SoftBox>
                <SoftTypography variant="h6">Detalles de la comisión</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
              <Card>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} container spacing={2}>
                    <Grid item xs={12}>
                      <MiniStatisticsCard
                        title={{ text: "Comisión" }}
                        count={makeNiceText(comisiones[0]?.nombre, "nombre")}
                        icon={{ color: "dark", component: "receipt" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MiniStatisticsCard
                        title={{ text: sede?.nombre }}
                        count={makeNiceText(comisiones[0]?.horarios[0]?.aula?.nombre, "nombre")}
                        icon={{ color: "primary", component: "account_balance" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MiniStatisticsCard
                        title={{ text: "Docente" }}
                        count={makeNiceText(
                          `${comisiones[0]?.docente?.nombre} ${comisiones[0]?.docente?.apellido}`,
                          "nombre"
                        )}
                        icon={{ color: "dark", component: "person" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card
                      sx={{
                        height: "100%",
                      }}
                    >
                      <SoftBox
                        p={2}
                        sx={{
                          height: "100%",
                        }}
                      >
                        <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                          <SoftTypography variant="h6">Historial de asistencias</SoftTypography>
                          <SoftButton
                            color="primary"
                            sx={{
                              display: {
                                xs: "none",
                                sm: "flex",
                              },
                            }}
                            circular
                            onClick={() => {
                              setOpenModalAsistencias(true);
                            }}
                          >
                            <Icon>visibility</Icon>
                            &nbsp;Ver todo
                          </SoftButton>
                          <SoftButton
                            color="primary"
                            circular
                            onClick={() => {
                              setOpenModalAsistencias(true);
                            }}
                            iconOnly
                            sx={{
                              display: {
                                xs: "block",
                                sm: "none",
                              },
                            }}
                          >
                            <Icon>visibility</Icon>
                          </SoftButton>
                        </SoftBox>
                        <SoftBox
                          alignItems="center"
                          py={asistencias?.asistenciasAlumno?.length > 0 ? 3 : 0}
                          justifyContent="center"
                          flexDirection="row-reverse"
                          sx={{
                            display: {
                              xs: "none",
                              sm: "flex",
                            },
                          }}
                        >
                          {asistencias?.asistenciasAlumno?.length > 0 ? (
                            new Array(5).fill(0).map((_, index) => {
                              const asistencia = asistencias.asistenciasAlumno[index];
                              return (
                                <SoftBox key={index} mx={1}>
                                  <Tooltip
                                    title={
                                      asistencia?.fecha
                                        ? `${asistencia?.fecha} ${asistencia?.estado}`
                                        : "No hay datos"
                                    }
                                    placement="top"
                                  >
                                    <SoftButton
                                      color={
                                        asistencia?.estado === "Presente"
                                          ? "uproGreen"
                                          : asistencia?.estado === "Justificado"
                                          ? "uproYellow"
                                          : asistencia?.estado === "Ausente"
                                          ? "primary"
                                          : "secondary"
                                      }
                                      circular
                                      iconOnly
                                    >
                                      <Icon>
                                        {asistencia?.estado === "Presente"
                                          ? "done"
                                          : asistencia?.estado === "Justificado"
                                          ? "edit_note"
                                          : asistencia?.estado === "Ausente"
                                          ? "clear"
                                          : "event_busy"}
                                      </Icon>
                                    </SoftButton>
                                  </Tooltip>
                                </SoftBox>
                              );
                            })
                          ) : (
                            <SinDatos />
                          )}
                        </SoftBox>
                        <SoftBox
                          alignItems="center"
                          mt={1}
                          py={1}
                          justifyContent="center"
                          flexDirection="column"
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "none",
                            },
                          }}
                        >
                          {asistencias?.asistenciasAlumno?.length > 0 ? (
                            new Array(4).fill(0).map((_, index) => {
                              const asistencia = asistencias.asistenciasAlumno[index];
                              return (
                                <SoftBox
                                  key={index}
                                  my={1}
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      asistencia?.fecha
                                        ? `${asistencia?.fecha} ${asistencia?.estado}`
                                        : "No hay datos"
                                    }
                                    placement="top"
                                  >
                                    <SoftButton
                                      color={
                                        asistencia?.estado === "Presente"
                                          ? "uproGreen"
                                          : asistencia?.estado === "Justificado"
                                          ? "uproYellow"
                                          : asistencia?.estado === "Ausente"
                                          ? "primary"
                                          : "secondary"
                                      }
                                      fullWidth
                                      circular
                                    >
                                      <Icon>
                                        {asistencia?.estado === "Presente"
                                          ? "done"
                                          : asistencia?.estado === "Justificado"
                                          ? "edit_note"
                                          : asistencia?.estado === "Ausente"
                                          ? "clear"
                                          : "event_busy"}
                                      </Icon>
                                      &nbsp;
                                      {asistencia?.fecha
                                        ? `${dayjs(asistencia?.fecha).format("DD/MM/YYYY") || ""} ${
                                            asistencia?.estado
                                          }`
                                        : "No hay datos"}
                                    </SoftButton>
                                  </Tooltip>
                                </SoftBox>
                              );
                            })
                          ) : (
                            <SinDatos />
                          )}
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                  >
                    <Card
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        maxHeight: "60vh",
                        overflow: "auto",
                      }}
                    >
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon
                              sx={{
                                color: getUproColor("uproYellow"),
                              }}
                            >
                              calendar_today
                            </Icon>
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            Horarios de cursada
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox>
                        <Table stickyHeader>
                          <TableHead
                            sx={{
                              display: "revert !important",
                            }}
                          >
                            <TableRow>
                              <TableCell>&nbsp;</TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Lunes
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Martes
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Miércoles
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Jueves
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Viernes
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {new Array(17).fill(0).map((_, index) => {
                            const hora = dayjs()
                              .startOf("day")
                              .add(index + 7, "hour");

                            const comisionesSede = comisiones?.filter(
                              (comision) => comision?.sede?.id === sede?.id
                            );

                            const filtrarComisionesPorDiaYHora = (dia) => {
                              return (
                                comisionesSede?.filter((comision) =>
                                  comision.horarios.some(
                                    (horario) =>
                                      horario.dia === dia &&
                                      (dayjs(horario.horarioInicio, "HH:mm").isSame(hora, "hour") ||
                                        (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                          hora,
                                          "hour"
                                        ) &&
                                          dayjs(horario.horarioFin, "HH:mm").isAfter(hora, "hour")))
                                  )
                                ) || []
                              );
                            };

                            return (
                              <TableRow key={index}>
                                <TableCell>{hora.format("HH:mm")}</TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Lunes").length > 0
                                        ? filtrarComisionesPorDiaYHora("Lunes")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Lunes").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Lunes")?.aula
                                          ?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Martes").length > 0
                                        ? filtrarComisionesPorDiaYHora("Martes")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Martes").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Martes")?.aula
                                          ?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Miércoles").length > 0
                                        ? filtrarComisionesPorDiaYHora("Miércoles")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Miércoles").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Miércoles")
                                          ?.aula?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Jueves").length > 0
                                        ? filtrarComisionesPorDiaYHora("Jueves")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Jueves").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Jueves")?.aula
                                          ?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Viernes").length > 0
                                        ? filtrarComisionesPorDiaYHora("Viernes")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Viernes").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Viernes")?.aula
                                          ?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </Table>
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: {
                        xs: "block",
                        sm: "none",
                      },
                    }}
                  >
                    <Card>
                      <SoftBox p={2}>
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon
                              sx={{
                                color: getUproColor("uproYellow"),
                              }}
                            >
                              calendar_today
                            </Icon>
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            Horarios de cursada
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mt={2}>
                          {comisiones?.map((comision) =>
                            comision.horarios.map((horario, index) => (
                              <SoftBox
                                key={comision.id}
                                display="flex"
                                flexDirection="column"
                                textAlign="center"
                                mt={index > 0 ? 1 : 0}
                              >
                                <SoftTypography variant="h7" fontWeight="bold" color="primary">
                                  {horario.dia}
                                </SoftTypography>
                                <SoftBox>
                                  <SoftTypography variant="h6" fontWeight="bold">
                                    {`${dayjs(horario.horarioInicio, "HH:mm").format(
                                      "HH:mm"
                                    )}hs - ${dayjs(horario.horarioFin, "HH:mm").format("HH:mm")}hs`}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            ))
                          )}
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </SoftBox>
            <ModalAsistencias
              open={openModalAsistencias}
              handleClose={() => setOpenModalAsistencias(false)}
              comision={comisiones[0]}
              alumno={alumno}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalComisionDetalles.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  sede: PropTypes.object,
  comisiones: PropTypes.array,
  alumno: PropTypes.object,
};
