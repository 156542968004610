// React
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

// Components
import {
  Card,
  CircularProgress,
  Collapse,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Data
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftInput from "components/SoftInput";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import { UserContext } from "context/user";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import ModalAlumnoDetalles from "./components/ModalAlumnoDetalles";
import dataAlumnos from "./data/dataAlumnos";
import ModalEmpresas from "./components/ModalEmpresas";
import { makeNiceText } from "utils/formatters";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

function Pasantias() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [openModalEmpresas, setOpenModalEmpresas] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombreCompleto",
    orden: "ASC",
  });

  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataAlumnos;
  const { menu } = useContext(MenuContext);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [filter, setFilter] = useState({});

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [getAlumnosReporte, { loading: loadingDescargar }] = useLazyQuery(
    gql`
      query getUsuarios(
        $search: String
        $order: Order
        $filter: JSON
        $idCarrera: ID
        $idSede: ID
      ) {
        usuarios(search: $search, order: $order, filter: $filter) {
          id
          nombre
          apellido
          username
          email
          dni
          curriculum
          telefono
          genero
          inscripcionActual(idCarrera: $idCarrera, idSede: $idSede) {
            id
            carrera {
              nombre
            }
            sede {
              nombre
            }
          }
        }
      }
    `
  );

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getUsuarios(
        $search: String
        $page: Int
        $order: Order
        $filter: JSON
        $idCarrera: ID
        $idSede: ID
      ) {
        paginationInfo {
          pages
          total
        }
        usuarios(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: $filter
        ) {
          id
          nombre
          apellido
          username
          email
          dni
          curriculum
          inscripcionActual(idCarrera: $idCarrera, idSede: $idSede) {
            id
            carrera {
              nombre
            }
            sede {
              nombre
            }
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
        filter: {
          tipoUser: "Alumno",
          aceptaPasantia: true,
          curriculum: {
            _ne: null,
          },
          _inscripciones: {
            tipo: ["Inscripto", "Inscripto Condicional"],
            idCarrera: filter.idCarrera,
            idSede: filter.idSede,
          },
        },
        idCarrera: filter.idCarrera,
        idSede: filter.idSede,
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { data: sedes } = useQuery(
    gql`
      query getSedes {
        sedes(order: { field: "nombre", order: ASC }) {
          id
          nombre
        }
      }
    `
  );

  const { data: carreras } = useQuery(
    gql`
      query getCarreras {
        carreras(order: { field: "nombre", order: ASC }) {
          id
          nombre
        }
      }
    `
  );

  useEffect(() => {
    if (data) {
      setRows(
        getRows(data.usuarios, (event, item) => {
          menu({
            open: event.currentTarget,
            align: "right",
            options: [
              {
                name: "Ver detalles",
                icon: { icon: "visibility", color: getUproColor("uproGreen") },
                onClick: () => {
                  setSelectedUsuario(item);
                  setOpenModalAdd(true);
                },
              },
              {
                name: item?.curriculum ? "Descargar curriculum" : "No hay curriculum",
                icon: { icon: "download", color: getUproColor("uproYellow") },
                disabled: !item?.curriculum,
                onClick: async () => {
                  const file = await fetch(`${API_URL}/${item?.curriculum}`);
                  const blob = await file.blob();
                  saveAs(blob, `Curriculum - ${item?.nombre} ${item?.apellido}.pdf`);
                },
              },
            ],
            handleClose: () => {},
          });
        })
      );
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Pasantias</SoftTypography>
                    <SoftBox display="flex" justifyContent="end" gap={2}>
                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                      >
                        <Tooltip title={"Descargar listado de alumnos"} placement="top">
                          <SoftButton color="uproBlue" circular iconOnly onClick={() => {}}>
                            {loadingDescargar ? (
                              <CircularProgress size={15} color="inherit" />
                            ) : (
                              <Icon>download</Icon>
                            )}
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                      >
                        <Tooltip
                          title={expandedFilter ? "Quitar filtros" : "Mostrar filtros"}
                          placement="top"
                        >
                          <SoftButton
                            color="uproYellow"
                            circular
                            iconOnly
                            onClick={() => {
                              setExpandedFilter(!expandedFilter);
                              setFilter({});
                            }}
                          >
                            <Icon>{expandedFilter ? "filter_alt_off" : "filter_alt"}</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                    gap={2}
                  >
                    <SoftBox
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                    >
                      <Tooltip title="Administrar empresas" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          iconOnly
                          onClick={() => setOpenModalEmpresas(true)}
                        >
                          <Icon>work</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Administrar empresas" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          onClick={() => setOpenModalEmpresas(true)}
                        >
                          <Icon>work</Icon>
                          &nbsp;Empresas
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Descargar listado de alumnos" placement="top">
                        <SoftButton
                          color="uproBlue"
                          circular
                          iconOnly
                          disabled={loadingDescargar}
                          onClick={() => {
                            getAlumnosReporte({
                              variables: {
                                search: search || null,
                                order: { field: orderBy.campo, order: orderBy.orden },
                                filter: {
                                  tipoUser: "Alumno",
                                  aceptaPasantia: true,
                                  curriculum: {
                                    _ne: null,
                                  },
                                  _inscripciones: {
                                    tipo: ["Inscripto", "Inscripto Condicional"],
                                    idCarrera: filter.idCarrera,
                                    idSede: filter.idSede,
                                  },
                                },
                              },
                            }).then((data) => {
                              let reporte = data?.data?.usuarios || [];
                              let reporteExportar = reporte.map((item) => {
                                return {
                                  ID: item.id,
                                  "Apellido y Nombre": makeNiceText(
                                    `${item.apellido} ${item.nombre}`
                                  ),
                                  "Carrera":
                                  makeNiceText(item?.inscripcionActual?.carrera?.nombre, "nombre"),
                                  "Sede": item?.inscripcionActual?.sede?.nombre,
                                  DNI: item.dni,
                                  Email: item.email,
                                  Género: item.genero,
                                  Teléfono: item.telefono,
                                };
                              });
                              const cabecera = [
                                [
                                  `Alumnos ${
                                    filter.idSedeCurso
                                      ? `(Sede: ${
                                          sedes?.sedes.find(
                                            (sede) => sede.id === filter.idSedeCurso
                                          )?.nombre
                                        })`
                                      : ""
                                  } ${
                                    filter.estadoInscripcionCurso
                                      ? `(Estado: ${filter.estadoInscripcionCurso})`
                                      : ""
                                  } - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte de pasantías - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            });
                          }}
                        >
                          {loadingDescargar ? (
                            <CircularProgress size={15} color="inherit" />
                          ) : (
                            <Icon>download</Icon>
                          )}
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip
                        title={expandedFilter ? "Quitar filtros" : "Mostrar filtros"}
                        placement="top"
                      >
                        <SoftButton
                          color="uproYellow"
                          circular
                          iconOnly
                          onClick={() => {
                            setExpandedFilter(!expandedFilter);
                            setFilter({});
                          }}
                        >
                          <Icon>{expandedFilter ? "filter_alt_off" : "filter_alt"}</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <Collapse in={expandedFilter}>
                  <Grid container spacing={2} mt={1} alignItems="center" justifyContent="end">
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="idCarrera">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Carrera
                        </SoftTypography>
                      </InputLabel>
                      <Select
                        value={filter.idCarrera || -1}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            idCarrera: e.target.value === -1 ? undefined : e.target.value,
                          });
                          setPage(1);
                        }}
                      >
                        <MenuItem value={-1}>Todas las carreras</MenuItem>
                        {carreras?.carreras.map((carrera) => (
                          <MenuItem key={carrera.id} value={carrera.id}>
                            {carrera.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="idSede">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Sede
                        </SoftTypography>
                      </InputLabel>
                      <Select
                        value={filter.idSede || -1}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            idSede: e.target.value === -1 ? undefined : e.target.value,
                          });
                          setPage(1);
                        }}
                      >
                        <MenuItem value={-1}>Todas las sedes</MenuItem>
                        {sedes?.sedes.map((sede) => (
                          <MenuItem key={sede.id} value={sede.id}>
                            {sede.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : rows?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.usuarios.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
          <ModalAlumnoDetalles
            open={openModalAdd}
            handleClose={() => {
              setOpenModalAdd(false);
              setSelectedUsuario();
            }}
            refetch={refetch}
            idUsuario={selectedUsuario?.id}
          />
          <ModalEmpresas
            open={openModalEmpresas}
            handleClose={() => setOpenModalEmpresas(false)}
            refetch={refetch}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Pasantias;
