// React
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalNewInscripcion({
  open,
  handleClose,
  idAlumno,
  refetch,
  tipo,
  inscripciones,
}) {
  const { handleSnackbar } = useContext(MessageManager);
  const [idCarrera, setIdCarrera] = useState();
  const [idSede, setIdSede] = useState();
  const [sedes, setSedes] = useState([]);

  const [getCarreras, { data: carreras }] = useLazyQuery(
    gql`
      query getCarreras($tipo: String!) {
        carrerasPublic(filter: { tipo: $tipo }) {
          id
          nombre
          codigo
          sedes {
            id
            nombre
          }
        }
      }
    `
  );

  const [getSedes, { data: sedesData }] = useLazyQuery(
    gql`
      query getSedes {
        sedes(order: { field: "nombre", order: ASC }) {
          id
          nombre
        }
      }
    `
  );

  const [altaInscripcion, { loading: loadingAlta }] = useMutation(
    gql`
      mutation altaInscripcion($idUser: ID!, $idCarrera: ID!, $idSede: ID!) {
        altaInscripcion(idUser: $idUser, idCarrera: $idCarrera, idSede: $idSede) {
          id
        }
      }
    `
  );

  useEffect(() => {
    if (tipo && open) {
      getSedes();
      setIdCarrera(null);
      setIdSede(null);
      getCarreras({ variables: { tipo } }).then((res) => {
        const sedes = res.data.carrerasPublic.reduce((acc, carrera) => {
          if (carrera.sedes) {
            carrera.sedes.forEach((sede) => {
              if (!acc.find((s) => s.id === sede.id)) {
                acc.push(sede);
              }
            });
          }
          return acc;
        }, []);
        setSedes(sedes);
      });
    }
  }, [tipo, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Nueva inscripción</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Sede
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Sede"
                    name="idSede"
                    value={idSede || -1}
                    onChange={(e) => {
                      setIdSede(e.target.value);
                      setIdCarrera(null);
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una sede
                    </MenuItem>
                    {tipo === "Curso de Ingreso"
                      ? sedesData?.sedes?.map((sede) => (
                          <MenuItem key={sede.id} value={sede.id}>
                            {sede.nombre}
                          </MenuItem>
                        ))
                      : sedes?.map((sede) => (
                          <MenuItem key={sede.id} value={sede.id}>
                            {sede.nombre}
                          </MenuItem>
                        ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      {tipo}
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Carrera"
                    name="idCarrera"
                    value={idCarrera || -1}
                    onChange={(e) => setIdCarrera(e.target.value)}
                  >
                    <MenuItem value={-1} disabled>
                      {idSede
                        ? `Seleccione ${tipo === "Carrera" ? "una" : "un"} ${tipo.toLowerCase()}`
                        : "Seleccione antes una sede"}
                    </MenuItem>
                    {tipo === "Curso de Ingreso"
                      ? carreras?.carrerasPublic.map((carrera) => (
                          <MenuItem
                            key={carrera.id}
                            value={carrera.id}
                            disabled={inscripciones.some(
                              (inscripcion) =>
                                inscripcion.carrera.id === carrera.id && inscripcion.tipo !== "Baja"
                            )}
                          >
                            {`${carrera.codigo} - ${carrera.nombre}`}
                          </MenuItem>
                        ))
                      : carreras?.carrerasPublic
                          .filter((carrera) => carrera.sedes.find((sede) => sede.id === idSede))
                          .map((carrera) => (
                            <MenuItem
                              key={carrera.id}
                              value={carrera.id}
                              disabled={inscripciones.some(
                                (inscripcion) =>
                                  inscripcion.carrera.id === carrera.id &&
                                  inscripcion.tipo !== "Baja"
                              )}
                            >
                              {`${carrera.codigo} - ${carrera.nombre}`}
                            </MenuItem>
                          ))}
                  </Select>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={!idCarrera || !idSede || loadingAlta}
                  onClick={() => {
                    altaInscripcion({
                      variables: {
                        idUser: idAlumno,
                        idCarrera,
                        idSede,
                      },
                    })
                      .then(() => {
                        handleSnackbar("Inscripción realizada con éxito", "success");
                        refetch();
                        handleClose();
                      })
                      .catch((e) => {
                        handleSnackbar(
                          e.message || "Ocurrió un error al realizar la inscripción",
                          "error"
                        );
                      });
                  }}
                >
                  {loadingAlta ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNewInscripcion.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idAlumno: PropTypes.string,
  refetch: PropTypes.func,
  tipo: PropTypes.string,
  inscripciones: PropTypes.array,
};
