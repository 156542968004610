// React

// Components
import {
  Card,
  Collapse,
  Grid,
  Icon,
  MenuItem,
  Select,
  Skeleton,
  SwipeableDrawer,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Data
import { gql, useQuery } from "@apollo/client";
import SoftButton from "components/SoftButton";
import { MenuContext } from "context/menuContext";
import { PropTypes } from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import Joyride from "react-joyride";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import Certificados from "./components/Certificados";
import DatosPersonales from "./components/DatosPersonales";
import Inscripciones from "./components/Inscripciones";
import ModalCurriculum from "./components/ModalCurriculum";
import ModalTalles from "./components/ModalTalles";
import Resumen from "./components/Resumen";
import ModalCuestionario from "./components/ModalCuestionario";

const CustomBeacon = ({ onClick }) => (
  <Tooltip title="Ver tutorial" placement="top">
    <SoftButton
      onClick={onClick}
      color="primary"
      circular
      iconOnly
      sx={{
        cursor: "help",
        animation: "pulse 2s infinite",
        "@keyframes pulse": {
          "0%": {
            transform: "scale(1)",
          },
          "50%": {
            transform: "scale(1.1)",
          },
          "100%": {
            transform: "scale(1)",
          },
        },
      }}
    >
      <Icon>question_mark</Icon>
    </SoftButton>
  </Tooltip>
);

const tutorialVersion = 1;

const stepsBase = [
  {
    target: "#autogestion",
    content:
      "¡Bienvenido a la sección de autogestión! Aquí podrás gestionar toda tu vida académica dentro de la UPrO. Echemos un vistazo a las distintas secciones.",
  },
  {
    target: "#selectSeccion",
    content:
      "Desde aquí podrás seleccionar la sección que deseas ver. Podrás ver un resumen de tu situación académica, tus inscripciones, tus datos personales y tus certificados.",
  },
  {
    target: "#step-resumen",
    content:
      "Aquí podrás ver un resumen de tu situación académica. Podrás gestionar la documentación requerida para la carrera, editar tu perfil y generar tokens de acceso.",
  },
  {
    target: "#step-inscripciones",
    content:
      "Aquí podrás ver tus inscripciones a materias y exámenes. Desde aquí podrás realizar los exámenes a los que estés inscripto, como también inscribirte a nuevos exámenes y materias en caso de que estén disponibles.",
  },
  {
    target: "#step-datosPersonales",
    content: "Aquí podrás ver y modificar tus datos personales.",
  },
  {
    target: "#step-certificados",
    content: "Aquí podrás ver tus certificados y generar nuevos.",
  },
  {
    target: "#botonCarrera",
    content:
      "Desde aquí podrás seleccionar la carrera o curso del que deseas ver la información. Toda la información de las secciones está ligada a la carrera o curso que esté seleccionado.",
  },
  {
    target: "#botonTutorial",
    content: "Con este botón podrás volver a ver este tutorial las veces que desees.",
  },
  {
    target: "#profileCard",
    content: "En esta tarjeta podrás ver algunos datos importantes de tu perfil.",
  },

  {
    target: "#atajoEditarPerfil",
    content: "Desde aquí podrás ir a la sección de datos personales para editar tu perfil.",
  },
  {
    target: "#generarToken",
    content:
      "Desde aquí podrás generar un token de acceso, necesario para realizar algunos trámites. Generalo solo si se te solicita.",
  },
  {
    target: "#documentacionCard",
    content:
      "En esta tarjeta podrás ver la documentación que cargaste y otros documentos que podés cargar en caso de ser necesario.",
  },
  {
    target: "#selectSeccion",
    content: "Seleccioná inscripciones para continuar el tutorial.",
  },
  {
    target: "#step-inscripciones",
    content: "Haz click en inscripciones para continuar el tutorial.",
  },
];

const stepsInscripciones = [
  {
    target: "#unidadesInscriptas",
    content:
      "Aquí podrás ver las unidades de competencia (materias) a las que estás inscripto, como así también ver sus detalles, asistencias, horarios y mucho más.",
  },
  {
    target: "#unidadesDisponibles",
    content:
      "Aquí podrás ver las unidades de competencia (materias) disponibles para inscribirte. Podrás ver sus detalles, horarios, docentes y más.",
  },
  {
    target: "#examenesInscriptos",
    content:
      "Aquí podrás ver los exámenes a los que estás inscripto y las calificaciones que hayas tenido.",
  },
  {
    target: "#examenesDisponibles",
    content:
      "Aquí podrás ver los exámenes disponibles para inscribirte. Podrás ver sus detalles, fechas, horarios y más.",
  },
  {
    target: "#selectSeccion",
    content: "Seleccioná datos personales para continuar el tutorial.",
  },
  {
    target: "#step-datosPersonales",
    content: "Haz click en datos personales para continuar el tutorial.",
  },
];

const stepsDatosPersonales = [
  {
    target: "#cardDatosPersonales",
    content: "Aquí podrás ver y modificar tus datos personales y de contacto.",
  },
  {
    target: "#selectSeccion",
    content: "Seleccioná certificados para continuar el tutorial.",
  },
  {
    target: "#step-certificados",
    content: "Haz click en certificados para continuar el tutorial.",
  },
];

const stepsCertificados = [
  {
    target: "#certificadosCard",
    content:
      "Aquí podrás ver tus certificados y generar nuevos. Para generar un certificado, haz click en el botón de generar certificado. Para ver certificados generados, asegurate de expandir el historial del certificado que deseas ver.",
  },
  {
    target: "#botonTutorial",
    content:
      "¡Eso es todo! Podés volver a ver el tutorial las veces que desees, simplemente hacé click en este botón.",
  },
];

function Autogestion() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCarrera, setSelectedCarrera] = useState(null);
  const [selectedInscripcion, setSelectedInscripcion] = useState(null);
  const [showCuestionarios, setShowCuestionarios] = useState(false);
  const [selectedCuestionario, setSelectedCuestionario] = useState(null);
  const [selectedCupo, setSelectedCupo] = useState(null);
  const [modalTalles, setModalTalles] = useState(false);
  const [modalCurriculum, setModalCurriculum] = useState(false);
  const { menu } = useContext(MenuContext);
  const [expandCarreras, setExpandCarreras] = useState(false);
  const [isJoyrideActive, setIsJoyrideActive] = useState(false);
  const [isJoyridePasantiasActive, setIsJoyridePasantiasActive] = useState(false);
  const joyrideRef = useRef(null);
  const pasantiasRef = useRef(null);

  const {
    data: usuario,
    loading,
    refetch,
  } = useQuery(
    gql`
      query getUser {
        currentUser {
          id
          tipoUser
          username
          nombre
          apellido
          email
          dni
          talleZapato
          talleTorso
          tallePantalon
          fechaNacimiento
          pais {
            id
          }
          esDiscapacitado
          discapacidad
          genero
          preferenciaHorario
          poseeBeneficiosSociales
          telefono
          telefonoRespaldo
          tipoEstudios
          aceptaPasantia
          curriculum
          cuestionariosDisponibles {
            id
            nombre
          }
          localidad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
          nombreEscuela
          escalaMatematicas
          escalaComprensionTextos
          inscripciones {
            id
            sede {
              id
              codigo
              nombre
            }
            carrera {
              id
              nombre
              codigo
              tipo
              requiereTalles
              planes {
                id
                codigo
                nombre
                niveles {
                  id
                  codigo
                  nombre
                  materias {
                    id
                    codigo
                    nombre
                    comisiones {
                      id
                      codigo
                      nombre
                    }
                  }
                }
              }
            }
            tipo
            createdAt
            cupo {
              nombre
              sede {
                nombre
              }
            }
          }
          documentos {
            id
            nombre
            file
            createdAt
            estado
          }
          inscripcionesExamenes {
            id
            estado
            createdAt
            acta {
              id
              estado
              nota
            }
            examen {
              id
              fecha
              titulo
              duracion
              materia {
                nombre
                nivel {
                  planEstudio {
                    carrera {
                      id
                    }
                  }
                }
              }
            }
          }
          inscripcionesComisiones {
            id
            comision {
              id
              nombre
              codigo
              color
              nivel
              fechaInicio
              fechaFin
              sede {
                id
                nombre
                aulas {
                  id
                  nombre
                  capacidad
                }
              }
              docente {
                id
                nombre
                apellido
              }
              horarios {
                dia
                aula {
                  id
                  nombre
                }
                capacidad
                horarioInicio
                horarioFin
              }
              materia {
                id
                nombre
                nivel {
                  planEstudio {
                    carrera {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (usuario?.currentUser?.inscripciones.length > 0) {
      setSelectedCarrera(
        usuario?.currentUser.inscripciones.find((inscripcion) => inscripcion.tipo === "Inscripto")
          ?.carrera ||
          usuario?.currentUser.inscripciones.find(
            (inscripcion) => inscripcion.tipo === "Inscripto Condicional"
          )?.carrera ||
          usuario?.currentUser.inscripciones.find((inscripcion) => inscripcion.tipo === "Aprobado")
            ?.carrera ||
          usuario?.currentUser.inscripciones.find((inscripcion) => inscripcion.tipo === "Libre")
            ?.carrera ||
          null
      );
      setSelectedInscripcion(
        usuario?.currentUser.inscripciones.find(
          (inscripcion) => inscripcion.tipo === "Inscripto"
        ) ||
          usuario?.currentUser.inscripciones.find(
            (inscripcion) => inscripcion.tipo === "Inscripto Condicional"
          ) ||
          usuario?.currentUser.inscripciones.find(
            (inscripcion) => inscripcion.tipo === "Aprobado"
          ) ||
          usuario?.currentUser.inscripciones.find((inscripcion) => inscripcion.tipo === "Libre") ||
          null
      );
      setSelectedCupo(
        usuario?.currentUser.inscripciones.find((inscripcion) => inscripcion.tipo === "Inscripto")
          ?.cupo ||
          usuario?.currentUser.inscripciones.find(
            (inscripcion) => inscripcion.tipo === "Inscripto Condicional"
          )?.cupo ||
          usuario?.currentUser.inscripciones.find((inscripcion) => inscripcion.tipo === "Aprobado")
            ?.cupo ||
          usuario?.currentUser.inscripciones.find((inscripcion) => inscripcion.tipo === "Libre")
            ?.cupo ||
          null
      );
    }
  }, [usuario]);

  // useEffect(() => {
  //   const tutorialVersionStorage = localStorage.getItem("tutorialAutogestionVersion");
  //   if (tutorialVersionStorage !== tutorialVersion.toString()) {
  //     setIsJoyrideActive(true);
  //   }
  // }, []);

  const handleRestartJoyride = () => {
    setIsJoyrideActive(false);
    setTimeout(() => {
      setIsJoyrideActive(true);
    }, 100);
  };

  useEffect(() => {
    const tutorialPasantiasVisto = localStorage.getItem("tutorialPasantias");
    if (usuario) {
      if (usuario.currentUser.aceptaPasantia && !usuario.currentUser.curriculum) {
        setModalCurriculum(true);
      }
      if (usuario.currentUser.cuestionariosDisponibles.length > 0) {
        setShowCuestionarios(true);
      } else {
        setShowCuestionarios(false);
      }
    }
    if (selectedCarrera) {
      if (
        selectedCarrera?.requiereTalles &&
        (!usuario?.currentUser?.talleZapato ||
          !usuario?.currentUser?.talleTorso ||
          !usuario?.currentUser?.tallePantalon)
      ) {
        setModalTalles(true);
        setIsJoyridePasantiasActive(false);
      } else {
        setModalTalles(false);
        if (!tutorialPasantiasVisto && !modalTalles) {
          setIsJoyridePasantiasActive(true);
        }
      }
    }
  }, [selectedCarrera, modalTalles, usuario]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SwipeableDrawer
        anchor={"bottom"}
        open={showCuestionarios}
        sx={{
          width: "100%",
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: {
              xs: "100%",
              sm: "30%",
            },
            height: "auto",
            margin: "0 auto",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          },
        }}
        onClose={() => {
          setShowCuestionarios(false);
        }}
        onOpen={() => {
          setShowCuestionarios(true);
        }}
      >
        <SoftBox display="flex" flexDirection="column" p={2} gap={2}>
          <SoftBox
            sx={{
              display: {
                xs: "flex",
                sm: "none",
              },
            }}
            display="flex"
            justifyContent="center"
          >
            <Skeleton width={40} height={10} variant="text" animation={false} />
          </SoftBox>
          <SoftBox
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mb={-2}
          >
            <SoftButton
              circular
              iconOnly
              color="primary"
              onClick={() => setShowCuestionarios(false)}
            >
              <Icon>close</Icon>
            </SoftButton>
          </SoftBox>
          <Collapse in={showCuestionarios} unmountOnExit mountOnEnter>
            <SoftBox display="flex" flexDirection="column" gap={1} alignItems="center" p={2}>
              <SoftTypography variant="h2" fontWeight="bold" color="dark">
                {`📋`}
              </SoftTypography>
              <SoftTypography variant="h6" fontWeight="bold" color="dark">
                {`Nos gustaría que respondas ${
                  usuario?.currentUser?.cuestionariosDisponibles.length > 1 ? "estos" : "este"
                } cuestionario${
                  usuario?.currentUser?.cuestionariosDisponibles.length > 1 ? "s" : ""
                }`}
              </SoftTypography>
              <SoftTypography variant="h7" fontWeight="regular" color="dark">
                {`Nos ayudará${
                  usuario?.currentUser?.cuestionariosDisponibles.length > 1 ? "n" : ""
                } a mejorar tu experiencia en la UPrO`}
              </SoftTypography>
            </SoftBox>
          </Collapse>
          <SoftBox
            sx={{
              width: "100%",
            }}
          >
            <Collapse in={showCuestionarios} unmountOnExit mountOnEnter>
              <SoftBox display="flex" flexDirection="column" gap={2}>
                {usuario?.currentUser?.cuestionariosDisponibles.map((cuestionario) => (
                  <SoftButton
                    key={cuestionario.id}
                    color="primary"
                    fullWidth
                    circular
                    onClick={() => {
                      setSelectedCuestionario(cuestionario);
                    }}
                  >
                    {cuestionario.nombre.length > 30
                      ? cuestionario.nombre.substring(0, 30) + "..."
                      : cuestionario.nombre}
                  </SoftButton>
                ))}
              </SoftBox>
            </Collapse>
          </SoftBox>
        </SoftBox>
      </SwipeableDrawer>
      <SoftBox py={3}>
        <Joyride
          run={isJoyrideActive}
          ref={joyrideRef}
          locale={{
            back: "Atrás",
            close: "Cerrar",
            last: "Entendido",
            next: "Siguiente",
            skip: "Omitir",
            open: "Continuar tutorial",
          }}
          steps={
            selectedTab === 1
              ? stepsInscripciones
              : selectedTab === 2
              ? stepsDatosPersonales
              : selectedTab === 3
              ? stepsCertificados
              : stepsBase
          }
          continuous
          styles={{
            options: {
              zIndex: 9999,
            },
            overlay: {
              zIndex: 9999,
            },
            tooltip: {
              zIndex: 9999,
              borderRadius: 10,
            },
            buttonBack: {
              backgroundColor: getUproColor("uproYellow"),
              color: "#fff",
              borderRadius: "10rem",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "0.5rem 1rem",
              fontFamily: "inherit",
            },
            buttonNext: {
              backgroundColor: getUproColor("uproGreen"),
              color: "#fff",
              borderRadius: "10rem",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "0.5rem 1rem",
              fontFamily: "inherit",
            },
            buttonSkip: {
              backgroundColor: getUproColor("uproPink"),
              color: "#fff",
              borderRadius: "10rem",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "0.5rem 1rem",
              fontFamily: "inherit",
            },
            buttonClose: {
              display: "none",
            },
          }}
          beaconComponent={CustomBeacon}
          callback={(data) => {
            if (data.status === "finished" && selectedTab === 3) {
              localStorage.setItem("tutorialAutogestionVersion", tutorialVersion);
            }
          }}
          disableOverlayClose
          showSkipButton
        />
        <Joyride
          run={isJoyridePasantiasActive}
          ref={pasantiasRef}
          locale={{
            last: "Entendido",
          }}
          steps={[
            {
              target: "#pasantias",
              disableBeacon: true,
              content:
                "¡Bienvenido a la sección de pasantías! Aquí podrás decidir si deseas participar de las pasantías de la UPrO. Recordá subir tu curriculum!",
            },
          ]}
          continuous
          styles={{
            options: {
              zIndex: 9999,
            },
            overlay: {
              zIndex: 9999,
            },
            tooltip: {
              zIndex: 9999,
              borderRadius: 10,
            },
            buttonNext: {
              backgroundColor: getUproColor("uproGreen"),
              color: "#fff",
              borderRadius: "10rem",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "0.5rem 1rem",
              fontFamily: "inherit",
            },
            buttonClose: {
              display: "none",
            },
          }}
          callback={(data) => {
            if (data.status === "finished") {
              localStorage.setItem("tutorialPasantias", true);
            }
          }}
          disableOverlayClose
          showSkipButton
        />
        <SoftBox mb={3} id="autogestion">
          <Card
            sx={{
              cursor: "move",
              position: "relative",
            }}
            draggable
            onDragStart={(e) => {
              e.dataTransfer.setData("text/plain", null);
            }}
            onDragEnd={(e) => {
              const card = e.target.closest(".MuiCard-root");
              if (card) {
                card.style.position = "absolute";
                card.style.left = `${e.clientX - card.offsetWidth / 2}px`;
                card.style.top = `${e.clientY - card.offsetHeight / 2}px`;
              }
            }}
          >
            <SoftBox
              p={3}
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="space-between"
            >
              <SoftBox
                mr={2}
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                  width: "100%",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={(e, value) => {
                    setSelectedTab(value);
                    const tutorialVersionStorage = localStorage.getItem(
                      "tutorialAutogestionVersion"
                    );
                  }}
                >
                  <Tab
                    id="step-resumen"
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 0 ? "primary" : "secondary"}>
                            description
                          </Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 0 ? "bold" : "regular"}
                        >
                          Resumen
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={0}
                  />
                  <Tab
                    id="step-inscripciones"
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 1 ? "primary" : "secondary"}>school</Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 1 ? "bold" : "regular"}
                        >
                          Inscripciones
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={1}
                  />
                  <Tab
                    id="step-datosPersonales"
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 2 ? "primary" : "secondary"}>
                            account_circle
                          </Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 2 ? "bold" : "regular"}
                        >
                          Datos personales
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={2}
                  />
                  <Tab
                    id="step-certificados"
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 3 ? "primary" : "secondary"}>verified</Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 3 ? "bold" : "regular"}
                        >
                          Certificados
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={3}
                  />
                </Tabs>
              </SoftBox>
              <SoftBox
                sx={{
                  display: {
                    xs: "flex",
                    sm: "none",
                  },
                  width: "100%",
                }}
                mb={2}
                gap={1}
              >
                <Select
                  id="selectSeccion"
                  value={selectedTab}
                  onChange={(e) => {
                    setSelectedTab(e.target.value);
                    refetch();
                    const tutorialVersionStorage = localStorage.getItem(
                      "tutorialAutogestionVersion"
                    );
                    // if (tutorialVersionStorage !== tutorialVersion.toString()) {
                    //   handleRestartJoyride();
                    // }
                  }}
                >
                  <MenuItem value={0}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 0 ? "primary" : "secondary"}>description</Icon>
                        &nbsp; Resumen
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value={1}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 1 ? "primary" : "secondary"}>school</Icon>
                        &nbsp; Inscripciones
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value={2}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 2 ? "primary" : "secondary"}>
                          account_circle
                        </Icon>
                        &nbsp; Datos personales
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value={3}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 3 ? "primary" : "secondary"}>verified</Icon>
                        &nbsp; Certificados
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                </Select>
                <Tooltip title="Ver tutorial" placement="top">
                  <SoftButton
                    circular
                    iconOnly
                    color="primary"
                    onClick={() => {
                      handleRestartJoyride();
                      setIsJoyrideActive(true);
                    }}
                    id="botonTutorial"
                  >
                    <Icon>question_mark</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
              <SoftBox display="flex" gap={1} alignItems="center">
                <SoftButton
                  id="botonCarrera"
                  color="uproYellow"
                  circular
                  sx={{
                    textWrap: "nowrap",
                  }}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={(event) => {
                    setExpandCarreras(true);
                    menu({
                      open: event.currentTarget,
                      align: "right",
                      onClose: () => {
                        setExpandCarreras(false);
                      },
                      options: usuario?.currentUser?.inscripciones
                        .filter(
                          (inscripcion) =>
                            inscripcion.tipo === "Inscripto" ||
                            inscripcion.tipo === "Inscripto Condicional" ||
                            inscripcion.tipo === "Preinscripto" ||
                            inscripcion.tipo === "Libre" ||
                            inscripcion.tipo === "Aprobado"
                        )
                        .map((inscripcion) => ({
                          name: makeNiceText(inscripcion.carrera.nombre, "nombre"),
                          disabled: inscripcion.tipo === "Preinscripto",
                          icon: {
                            icon: inscripcion.carrera.tipo === "Carrera" ? "school" : "menu_book",
                            color:
                              selectedCarrera?.id === inscripcion.carrera.id
                                ? getUproColor("uproYellow")
                                : getUproColor("uproGreen"),
                          },
                          onClick: () => {
                            setSelectedCarrera(inscripcion.carrera);
                            setSelectedInscripcion(inscripcion);
                            setSelectedCupo(inscripcion.cupo);
                          },
                        })),
                    });
                  }}
                >
                  <Icon>school</Icon>
                  &nbsp;
                  {selectedCarrera?.nombre.length > 30
                    ? makeNiceText(selectedCarrera?.nombre).substring(0, 30) + "..."
                    : makeNiceText(selectedCarrera?.nombre) || "Seleccione una carrera"}
                  &nbsp;
                  <Icon
                    sx={{
                      rotate: expandCarreras ? "180deg" : "0deg",
                      transition: "all 0.5s",
                    }}
                  >
                    expand_more
                  </Icon>
                </SoftButton>
                <SoftBox
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                  }}
                >
                  <Tooltip title="Ver tutorial" placement="top">
                    <SoftButton
                      circular
                      iconOnly
                      color="primary"
                      onClick={() => {
                        handleRestartJoyride();
                        setIsJoyrideActive(true);
                      }}
                      id="botonTutorial"
                    >
                      <Icon>question_mark</Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Grid container spacing={2} p={3} pt={0}>
              <Grid item xs={12}>
                <Collapse in={selectedTab === 0} unmountOnExit mountOnEnter>
                  <Resumen
                    usuario={usuario?.currentUser}
                    carrera={selectedCarrera}
                    loading={loading}
                    refetch={refetch}
                    setTab={setSelectedTab}
                  />
                </Collapse>
                <Collapse in={selectedTab === 1} unmountOnExit mountOnEnter>
                  <Inscripciones
                    usuario={usuario?.currentUser}
                    loading={loading}
                    inscripcion={selectedInscripcion}
                  />
                </Collapse>
                <Collapse in={selectedTab === 2} unmountOnExit mountOnEnter>
                  <DatosPersonales
                    selectedUsuario={usuario?.currentUser}
                    loading={loading}
                    refetch={refetch}
                  />
                </Collapse>
                <Collapse in={selectedTab === 3} unmountOnExit mountOnEnter>
                  <Certificados
                    usuario={usuario?.currentUser}
                    carrera={selectedCarrera}
                    loading={loading}
                    cupo={selectedCupo}
                    selectedInscripcion={selectedInscripcion}
                  />
                </Collapse>
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
      </SoftBox>
      <ModalTalles
        open={modalTalles}
        handleClose={() => setModalTalles(false)}
        idCarrera={selectedCarrera?.id}
        refetch={refetch}
      />
      <ModalCurriculum
        open={modalCurriculum}
        handleClose={() => setModalCurriculum(false)}
        idCarrera={selectedCarrera?.id}
        refetch={refetch}
      />
      <ModalCuestionario
        cuestionario={selectedCuestionario}
        handleClose={() => setSelectedCuestionario(null)}
        refetch={refetch}
      />
      <Footer />
    </DashboardLayout>
  );
}

CustomBeacon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Autogestion;
