import { Card, Collapse, Grid, Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { gql, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import dayjs from "dayjs";
import ModalRevision from "layouts/autogestion/components/ModalRevision";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUproColor } from "utils/colors";
import ModalCertificadoMateria from "../ModalCertificado/Materia";
import ModalComisionDetalles from "../ModalComisionDetalles";
import ModalInscripcionNivel from "../ModalInscripcionNivel";

export default function Inscripciones({ usuario, loading, inscripcion }) {
  const [expanded, setExpanded] = useState([
    "Unidades de Competencia Inscriptas",
    "Examenes Inscriptos",
  ]);
  const { confirmar } = useContext(ConfirmarContext);
  const { handleSnackbar } = useContext(MessageManager);
  const navigate = useNavigate();
  const [openModalVerExamen, setOpenModalVerExamen] = useState(false);
  const [openModalComisionDetalles, setOpenModalComisionDetalles] = useState(false);
  const [openModalCertificadoMateria, setOpenModalCertificadoMateria] = useState(false);
  const [openModalInscripcionNivel, setOpenModalInscripcionNivel] = useState(false);
  const [selectedExamen, setSelectedExamen] = useState(null);
  const [selectedComision, setSelectedComision] = useState(null);
  const [selectedNivel, setSelectedNivel] = useState(null);

  const [comenzarExamen] = useMutation(
    gql`
      mutation comenzarExamen($idInscripcion: ID!) {
        comenzarExamen(idInscripcion: $idInscripcion)
      }
    `
  );

  return (
    <SoftBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            id="unidadesInscriptas"
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={2}
              py={1}
              sx={{
                borderBottom: !expanded.includes("Unidades de Competencia Inscriptas")
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e) => {
                setExpanded(
                  expanded.includes("Unidades de Competencia Inscriptas")
                    ? expanded.filter((i) => i !== "Unidades de Competencia Inscriptas")
                    : [...expanded, "Unidades de Competencia Inscriptas"]
                );
              }}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftBox mr={1} display="flex" alignItems="center">
                  <Icon color="primary">assignment_turned_in</Icon>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Unidades de Competencia inscriptas
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={
                      expanded.includes("Unidades de Competencia Inscriptas")
                        ? "Ocultar"
                        : "Mostrar"
                    }
                    placement="top"
                  >
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() =>
                        setExpanded(
                          expanded.includes("Unidades de Competencia Inscriptas")
                            ? expanded.filter((i) => i !== "Unidades de Competencia Inscriptas")
                            : [...expanded, "Unidades de Competencia Inscriptas"]
                        )
                      }
                    >
                      <Icon
                        sx={{
                          rotate: expanded.includes("Unidades de Competencia Inscriptas")
                            ? "180deg"
                            : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={expanded.includes("Unidades de Competencia Inscriptas")}>
              {usuario?.inscripcionesComisiones?.filter(
                (item) =>
                  item?.comision?.materia?.nivel?.planEstudio?.carrera?.id ===
                  inscripcion?.carrera?.id
              )?.length > 0 ? (
                usuario?.inscripcionesComisiones
                  ?.filter(
                    (item) =>
                      item?.comision?.materia?.nivel?.planEstudio?.carrera?.id ===
                      inscripcion?.carrera?.id
                  )
                  ?.map((item, index) => (
                    <SoftBox key={item.id}>
                      <SoftBox
                        px={2}
                        py={1}
                        sx={{
                          borderBottom:
                            usuario?.inscripcionesComisiones?.length - 1 === index
                              ? "none"
                              : "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <SoftBox
                          display={{
                            xs: "flex-row",
                            sm: "flex",
                          }}
                          justifyContent="space-between"
                        >
                          <SoftBox
                            display={{
                              xs: "flex-row",
                              sm: "flex",
                            }}
                            alignItems="center"
                            pl={{
                              xs: 0,
                              sm: 2,
                            }}
                          >
                            <SoftBox
                              mr={1}
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                },
                              }}
                            >
                              <Icon
                                sx={{
                                  color: getUproColor("uproPink"),
                                }}
                              >
                                subdirectory_arrow_right
                              </Icon>
                            </SoftBox>
                            <SoftBox
                              mr={1}
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                },
                              }}
                            >
                              <Tooltip title="Nivel" placement="top">
                                <Icon
                                  sx={{
                                    color: getUproColor("uproGreen"),
                                  }}
                                >
                                  book
                                </Icon>
                              </Tooltip>
                            </SoftBox>
                            <SoftTypography variant="h6">{`${item.comision.materia.nombre} - ${item.comision.codigo} - ${item.comision.nombre}`}</SoftTypography>
                          </SoftBox>
                          <SoftBox
                            display={{
                              xs: "none",
                              sm: "flex",
                            }}
                            justifyContent="flex-end"
                            alignItems="center"
                            gap={1}
                          >
                            <Tooltip title="Generar comprobante" placement="top">
                              <SoftBox>
                                <SoftButton
                                  circular
                                  iconOnly
                                  color="uproYellow"
                                  onClick={() => {
                                    setSelectedComision(item.comision);
                                    setOpenModalCertificadoMateria(true);
                                  }}
                                >
                                  <Icon>download</Icon>
                                </SoftButton>
                              </SoftBox>
                            </Tooltip>
                            <Tooltip title="Ver detalles" placement="top">
                              <SoftBox>
                                <SoftButton
                                  circular
                                  iconOnly
                                  color="uproGreen"
                                  onClick={() => {
                                    setSelectedComision(item.comision);
                                    setOpenModalComisionDetalles(true);
                                  }}
                                >
                                  <Icon>visibility</Icon>
                                </SoftButton>
                              </SoftBox>
                            </Tooltip>
                          </SoftBox>
                          <SoftBox
                            display={{
                              xs: "flex",
                              sm: "none",
                            }}
                            justifyContent="center"
                            alignItems="center"
                            my={2}
                          >
                            <Tooltip title="Ver detalles" placement="top">
                              <SoftBox
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <SoftButton
                                  circular
                                  fullWidth
                                  color="uproGreen"
                                  onClick={() => {
                                    setSelectedComision(item.comision);
                                    setOpenModalComisionDetalles(true);
                                  }}
                                >
                                  <Icon>visibility</Icon>
                                  &nbsp;Ver detalles
                                </SoftButton>
                              </SoftBox>
                            </Tooltip>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  ))
              ) : (
                <Grid container spacing={2} p={2}>
                  <SinDatos />
                </Grid>
              )}
            </Collapse>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            id="unidadesDisponibles"
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={2}
              py={1}
              sx={{
                borderBottom: !expanded.includes("Unidad de Competencia Disponibles")
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e) => {
                setExpanded(
                  expanded.includes("Unidad de Competencia Disponibles")
                    ? expanded.filter((i) => i !== "Unidad de Competencia Disponibles")
                    : [...expanded, "Unidad de Competencia Disponibles"]
                );
              }}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftBox mr={1} display="flex" alignItems="center">
                  <Icon
                    sx={{
                      color: getUproColor("uproGreen"),
                    }}
                  >
                    assignment
                  </Icon>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Niveles y unidades disponibles para inscripción
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={
                      expanded.includes("Unidad de Competencia Disponibles") ? "Ocultar" : "Mostrar"
                    }
                    placement="top"
                  >
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() =>
                        setExpanded(
                          expanded.includes("Unidad de Competencia Disponibles")
                            ? expanded.filter((i) => i !== "Unidad de Competencia Disponibles")
                            : [...expanded, "Unidad de Competencia Disponibles"]
                        )
                      }
                    >
                      <Icon
                        sx={{
                          rotate: expanded.includes("Unidad de Competencia Disponibles")
                            ? "180deg"
                            : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={expanded.includes("Unidad de Competencia Disponibles")}>
              {inscripcion?.carrera?.planes[inscripcion?.carrera?.planes?.length - 1]?.niveles
                .length > 0 && inscripcion?.carrera?.id != 1 ? (
                inscripcion?.carrera?.planes[inscripcion?.carrera?.planes?.length - 1]?.niveles.map(
                  (nivel, index) => (
                    <SoftBox key={nivel.id}>
                      <SoftBox
                        px={2}
                        py={1}
                        sx={{
                          borderBottom:
                            inscripcion?.carrera?.planes[0]?.niveles.length - 1 === index
                              ? "none"
                              : "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <SoftBox
                          display={{
                            xs: "flex-row",
                            sm: "flex",
                          }}
                          justifyContent="space-between"
                        >
                          <SoftBox
                            display={{
                              xs: "flex-row",
                              sm: "flex",
                            }}
                            alignItems="center"
                            pl={{
                              xs: 0,
                              sm: 2,
                            }}
                          >
                            <SoftBox
                              mr={1}
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                },
                              }}
                            >
                              <Icon
                                sx={{
                                  color: getUproColor("uproPink"),
                                }}
                              >
                                subdirectory_arrow_right
                              </Icon>
                            </SoftBox>
                            <SoftBox
                              mr={1}
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                },
                              }}
                            >
                              <Tooltip title="Nivel" placement="top">
                                <Icon
                                  sx={{
                                    color: getUproColor("uproYellow"),
                                  }}
                                >
                                  article
                                </Icon>
                              </Tooltip>
                            </SoftBox>
                            <SoftTypography variant="h6">{`${nivel.codigo} - ${nivel.nombre}`}</SoftTypography>
                          </SoftBox>
                          <SoftBox
                            display={{
                              xs: "none",
                              sm: "flex",
                            }}
                            justifyContent="flex-end"
                            alignItems="center"
                            gap={1}
                          >
                            <Tooltip title="Ver unidades" placement="top">
                              <SoftBox>
                                <SoftButton
                                  circular
                                  color="uproGreen"
                                  onClick={() => {
                                    setSelectedNivel(nivel);
                                    setOpenModalInscripcionNivel(true);
                                  }}
                                >
                                  Ver unidades&nbsp;
                                  <Icon>arrow_forward</Icon>
                                </SoftButton>
                              </SoftBox>
                            </Tooltip>
                          </SoftBox>
                          <SoftBox
                            display={{
                              xs: "flex",
                              sm: "none",
                            }}
                            justifyContent="center"
                            alignItems="center"
                            my={2}
                          >
                            <Tooltip title="Ver unidades" placement="top">
                              <SoftBox
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <SoftButton circular fullWidth color="uproGreen" onClick={() => {
                                    setSelectedNivel(nivel);
                                    setOpenModalInscripcionNivel(true);
                                  }}>
                                  Ver unidades&nbsp;
                                  <Icon>arrow_forward</Icon>
                                </SoftButton>
                              </SoftBox>
                            </Tooltip>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  )
                )
              ) : (
                <Grid container spacing={2} p={2}>
                  <SinDatos />
                </Grid>
              )}
            </Collapse>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            id="examenesInscriptos"
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={2}
              py={1}
              sx={{
                borderBottom: !expanded.includes("Examenes Inscriptos")
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e) => {
                setExpanded(
                  expanded.includes("Examenes Inscriptos")
                    ? expanded.filter((i) => i !== "Examenes Inscriptos")
                    : [...expanded, "Examenes Inscriptos"]
                );
              }}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftBox mr={1} display="flex" alignItems="center">
                  <Icon color="primary">task</Icon>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Exámenes inscriptos
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={expanded.includes("Examenes Inscriptos") ? "Ocultar" : "Mostrar"}
                    placement="top"
                  >
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() =>
                        setExpanded(
                          expanded.includes("Examenes Inscriptos")
                            ? expanded.filter((i) => i !== "Examenes Inscriptos")
                            : [...expanded, "Examenes Inscriptos"]
                        )
                      }
                    >
                      <Icon
                        sx={{
                          rotate: expanded.includes("Examenes Inscriptos") ? "180deg" : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={expanded.includes("Examenes Inscriptos")}>
              {usuario?.inscripcionesExamenes?.filter(
                (item) =>
                  item?.examen?.materia?.nivel?.planEstudio?.carrera?.id ===
                  inscripcion?.carrera?.id
              )?.length > 0 ? (
                usuario?.inscripcionesExamenes
                  ?.filter(
                    (item) =>
                      item?.examen?.materia?.nivel?.planEstudio?.carrera?.id ===
                      inscripcion?.carrera?.id
                  )
                  ?.map((item, index) => (
                    <SoftBox key={item.id}>
                      <SoftBox
                        px={2}
                        py={1}
                        sx={{
                          borderBottom:
                            usuario?.inscripcionesExamenes?.length - 1 === index
                              ? "none"
                              : "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <SoftBox
                          display={{
                            xs: "flex",
                            sm: "none",
                          }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Tooltip title="Duración del examen una vez iniciado" placement="top">
                            <SoftBox display="flex" alignItems="center">
                              <Icon
                                sx={{
                                  color: getUproColor("uproGreen"),
                                }}
                              >
                                access_time
                              </Icon>
                              <SoftTypography
                                variant="h6"
                                color="uproGreen"
                                sx={{
                                  marginLeft: 0.5,
                                }}
                              >
                                {`${item.examen.duracion}'`}
                              </SoftTypography>
                            </SoftBox>
                          </Tooltip>
                          <SoftBox
                            ml={1}
                            mr={{
                              xs: 1,
                              sm: 0,
                            }}
                            alignItems="center"
                          >
                            <SoftBadge
                              color={
                                dayjs(item?.examen?.fecha).isBefore(dayjs(), "day")
                                  ? "primary"
                                  : dayjs(item?.examen?.fecha).isSame(dayjs(), "day")
                                  ? "uproYellow"
                                  : "uproGreen"
                              }
                              badgeContent={`${dayjs(item?.examen?.fecha).format("DD/MM/YYYY")}`}
                            />
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          display={{
                            xs: "flex-row",
                            sm: "flex",
                          }}
                          justifyContent="space-between"
                        >
                          <SoftBox
                            display={{
                              xs: "flex-row",
                              sm: "flex",
                            }}
                            alignItems="center"
                            pl={{
                              xs: 0,
                              sm: 2,
                            }}
                          >
                            <SoftBox
                              mr={1}
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                },
                              }}
                            >
                              <Icon
                                sx={{
                                  color: getUproColor("uproPink"),
                                }}
                              >
                                subdirectory_arrow_right
                              </Icon>
                            </SoftBox>
                            <SoftBox
                              mr={1}
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                },
                              }}
                            >
                              <Tooltip title="Nivel" placement="top">
                                <Icon
                                  sx={{
                                    color: getUproColor("uproYellow"),
                                  }}
                                >
                                  quiz
                                </Icon>
                              </Tooltip>
                            </SoftBox>
                            <SoftTypography variant="h6">{`${item.examen.materia.nombre} - ${item.examen.titulo}`}</SoftTypography>
                            <Tooltip title="Duración del examen una vez iniciado" placement="top">
                              <SoftBox
                                display={{
                                  xs: "none",
                                  sm: "flex",
                                }}
                                alignItems="center"
                                ml={1}
                              >
                                <Icon
                                  sx={{
                                    color: getUproColor("uproGreen"),
                                  }}
                                >
                                  access_time
                                </Icon>
                                <SoftTypography
                                  variant="h6"
                                  color="uproGreen"
                                  sx={{
                                    marginLeft: 0.5,
                                  }}
                                >
                                  {`${item.examen.duracion}'`}
                                </SoftTypography>
                              </SoftBox>
                            </Tooltip>
                            <SoftBox
                              ml={1}
                              mr={{
                                xs: 1,
                                sm: 0,
                              }}
                              display={{
                                xs: "none",
                                sm: "flex",
                              }}
                              alignItems="center"
                            >
                              <SoftBadge
                                color={
                                  dayjs(item?.examen?.fecha).isBefore(dayjs(), "day")
                                    ? "primary"
                                    : dayjs(item?.examen?.fecha).isSame(dayjs(), "day")
                                    ? "uproYellow"
                                    : "uproGreen"
                                }
                                badgeContent={`${dayjs(item?.examen?.fecha).format("DD/MM/YYYY")}`}
                              />
                            </SoftBox>
                          </SoftBox>
                          <SoftBox
                            display={{
                              xs: "none",
                              sm: "flex",
                            }}
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            {item?.acta && item?.acta?.estado !== "Ausente" && (
                              <Tooltip title="Ver examen" placement="top">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    color={item?.acta?.nota >= 70 ? "uproGreen" : "uproPink"}
                                    circular
                                    onClick={() => {
                                      setSelectedExamen(item);
                                      setOpenModalVerExamen(true);
                                    }}
                                  >
                                    <Icon>visibility</Icon>
                                    &nbsp;{item?.acta?.estado}
                                  </SoftButton>
                                </SoftBox>
                              </Tooltip>
                            )}
                            <Tooltip
                              title={
                                item?.estado === "Finalizado"
                                  ? "Examen finalizado"
                                  : item?.estado === "Ausente"
                                  ? "Se ausentó al examen"
                                  : dayjs(item?.examen?.fecha).endOf("day").isBefore(dayjs())
                                  ? "La fecha de examen ya pasó"
                                  : dayjs(item?.examen?.fecha).startOf("day").isAfter(dayjs())
                                  ? "Aún no es la fecha de examen"
                                  : item?.estado === "Deshabilitado"
                                  ? "Requiere habilitación del docente"
                                  : item?.estado === "Habilitado"
                                  ? "Ir a rendir"
                                  : item?.estado === "En curso"
                                  ? "Continuar examen"
                                  : item?.estado
                              }
                              placement="top"
                            >
                              <SoftBox>
                                <SoftButton
                                  color={
                                    item?.estado === "Habilitado"
                                      ? "primary"
                                      : item?.estado === "En curso"
                                      ? "uproYellow"
                                      : item?.estado === "Finalizado"
                                      ? "uproGreen"
                                      : item?.estado === "Deshabilitado"
                                      ? "secondary"
                                      : "secondary"
                                  }
                                  circular
                                  disabled={
                                    dayjs(item?.examen?.fecha).endOf("day").isBefore(dayjs()) ||
                                    dayjs(item?.examen?.fecha).startOf("day").isAfter(dayjs()) ||
                                    item?.estado === "Finalizado" ||
                                    item?.estado === "Deshabilitado"
                                  }
                                  onClick={() => {
                                    if (item?.estado === "Habilitado") {
                                      confirmar({
                                        title: "Comenzar examen",
                                        message: `¿Está seguro que quieres iniciar el examen ${item?.examen?.materia?.nombre} - ${item?.examen?.titulo}? Una vez iniciado tendrás ${item?.examen?.duracion} minutos para completarlo, sin excepción.`,
                                        icon: "quiz",
                                        func: () => {
                                          comenzarExamen({ variables: { idInscripcion: item.id } })
                                            .then((response) => {
                                              if (response?.data?.comenzarExamen) {
                                                navigate("/examen", {
                                                  state: { inscripcion: item },
                                                });
                                              } else {
                                                handleSnackbar(
                                                  "Ha ocurrido un error al intentar comenzar el examen",
                                                  "error"
                                                );
                                              }
                                            })
                                            .catch((e) => {
                                              handleSnackbar(
                                                e.message ||
                                                  "Ha ocurrido un error al intentar comenzar el examen",
                                                "error"
                                              );
                                            });
                                        },
                                      });
                                    } else if (item?.estado === "En curso") {
                                      navigate("/examen", {
                                        state: { inscripcion: item },
                                      });
                                    }
                                  }}
                                >
                                  <Icon>
                                    {item?.estado === "Habilitado"
                                      ? "arrow_forward"
                                      : item?.estado === "En curso"
                                      ? "play_circle_filled"
                                      : item?.estado === "Finalizado"
                                      ? "done"
                                      : item?.estado === "Deshabilitado"
                                      ? "lock"
                                      : "visibility"}
                                  </Icon>
                                  &nbsp;
                                  {item?.estado === "Habilitado" ? "Ir a rendir" : item?.estado}
                                </SoftButton>
                              </SoftBox>
                            </Tooltip>
                          </SoftBox>
                          <SoftBox
                            display={{
                              xs: "flex",
                              sm: "none",
                            }}
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            my={2}
                            gap={2}
                          >
                            {item?.acta && (
                              <Tooltip title="Ver examen" placement="top">
                                <SoftBox
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <SoftButton
                                    fullWidth
                                    color={item?.acta?.nota >= 70 ? "uproGreen" : "uproPink"}
                                    circular
                                    onClick={() => {
                                      setSelectedExamen(item);
                                      setOpenModalVerExamen(true);
                                    }}
                                  >
                                    <Icon>visibility</Icon>
                                    &nbsp;{item?.acta?.estado}
                                  </SoftButton>
                                </SoftBox>
                              </Tooltip>
                            )}
                            <Tooltip
                              title={
                                item?.estado === "Finalizado"
                                  ? "Examen finalizado"
                                  : dayjs(item?.examen?.fecha).endOf("day").isBefore(dayjs())
                                  ? "La fecha de examen ya pasó"
                                  : dayjs(item?.examen?.fecha).startOf("day").isAfter(dayjs())
                                  ? "Aún no es la fecha de examen"
                                  : item?.estado === "Deshabilitado"
                                  ? "Requiere habilitación del docente"
                                  : item?.estado === "Habilitado"
                                  ? "Ir a rendir"
                                  : item?.estado === "En curso"
                                  ? "Continuar examen"
                                  : item?.estado
                              }
                              placement="top"
                            >
                              <SoftBox
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <SoftButton
                                  fullWidth
                                  color={
                                    item?.estado === "Habilitado"
                                      ? "primary"
                                      : item?.estado === "En curso"
                                      ? "uproYellow"
                                      : item?.estado === "Finalizado"
                                      ? "uproGreen"
                                      : item?.estado === "Deshabilitado"
                                      ? "secondary"
                                      : "secondary"
                                  }
                                  circular
                                  disabled={
                                    dayjs(item?.examen?.fecha).endOf("day").isBefore(dayjs()) ||
                                    dayjs(item?.examen?.fecha).startOf("day").isAfter(dayjs()) ||
                                    item?.estado === "Finalizado" ||
                                    item?.estado === "Deshabilitado"
                                  }
                                  onClick={() => {
                                    if (item?.estado === "Habilitado") {
                                      confirmar({
                                        title: "Comenzar examen",
                                        message: `¿Está seguro que quieres iniciar el examen ${item?.examen?.materia?.nombre} - ${item?.examen?.titulo}? Una vez iniciado tendrás ${item?.examen?.duracion} minutos para completarlo, sin excepción.`,
                                        icon: "quiz",
                                        func: () => {
                                          comenzarExamen({ variables: { idInscripcion: item.id } })
                                            .then((response) => {
                                              if (response?.data?.comenzarExamen) {
                                                navigate("/examen", {
                                                  state: { inscripcion: item },
                                                });
                                              } else {
                                                handleSnackbar(
                                                  "Ha ocurrido un error al intentar comenzar el examen",
                                                  "error"
                                                );
                                              }
                                            })
                                            .catch((e) => {
                                              handleSnackbar(
                                                e.message ||
                                                  "Ha ocurrido un error al intentar comenzar el examen",
                                                "error"
                                              );
                                            });
                                        },
                                      });
                                    } else if (item?.estado === "En curso") {
                                      navigate("/examen", {
                                        state: { inscripcion: item },
                                      });
                                    }
                                  }}
                                >
                                  <Icon>
                                    {item?.estado === "Habilitado"
                                      ? "arrow_forward"
                                      : item?.estado === "En curso"
                                      ? "play_circle_filled"
                                      : item?.estado === "Finalizado"
                                      ? "done"
                                      : item?.estado === "Deshabilitado"
                                      ? "lock"
                                      : "visibility"}
                                  </Icon>
                                  &nbsp;
                                  {item?.estado === "Habilitado" ? "Ir a rendir" : item?.estado}
                                </SoftButton>
                              </SoftBox>
                            </Tooltip>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  ))
              ) : (
                <Grid container spacing={2} p={2}>
                  <SinDatos />
                </Grid>
              )}
            </Collapse>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            id="examenesDisponibles"
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={2}
              py={1}
              sx={{
                borderBottom: !expanded.includes("Examenes Disponibles")
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e) => {
                setExpanded(
                  expanded.includes("Examenes Disponibles")
                    ? expanded.filter((i) => i !== "Examenes Disponibles")
                    : [...expanded, "Examenes Disponibles"]
                );
              }}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftBox mr={1} display="flex" alignItems="center">
                  <Icon
                    sx={{
                      color: getUproColor("uproGreen"),
                    }}
                  >
                    note_add
                  </Icon>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Exámenes disponibles
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={expanded.includes("Examenes Disponibles") ? "Ocultar" : "Mostrar"}
                    placement="top"
                  >
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() =>
                        setExpanded(
                          expanded.includes("Examenes Disponibles")
                            ? expanded.filter((i) => i !== "Examenes Disponibles")
                            : [...expanded, "Examenes Disponibles"]
                        )
                      }
                    >
                      <Icon
                        sx={{
                          rotate: expanded.includes("Examenes Disponibles") ? "180deg" : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={expanded.includes("Examenes Disponibles")}>
              <Grid container spacing={2} p={2}>
                <SinDatos />
              </Grid>
            </Collapse>
          </Card>
        </Grid>
        <ModalRevision
          open={openModalVerExamen}
          handleClose={() => setOpenModalVerExamen(false)}
          idInscripcion={selectedExamen?.id}
          refetch={() => {}}
          readOnly={true}
        />
        <ModalComisionDetalles
          open={openModalComisionDetalles}
          handleClose={() => setOpenModalComisionDetalles(false)}
          sede={selectedComision?.sede}
          comisiones={selectedComision ? [selectedComision] : []}
          alumno={usuario}
          carrera={selectedComision?.carrera}
        />
        <ModalCertificadoMateria
          open={openModalCertificadoMateria}
          handleClose={() => setOpenModalCertificadoMateria(false)}
          comision={selectedComision}
          usuario={usuario}
        />
        <ModalInscripcionNivel
          open={openModalInscripcionNivel}
          handleClose={() => setOpenModalInscripcionNivel(false)}
          idNivel={selectedNivel?.id}
          inscripcion={inscripcion}
          readOnly={true}
        />
      </Grid>
    </SoftBox>
  );
}

Inscripciones.propTypes = {
  usuario: PropTypes.object,
  loading: PropTypes.bool,
  inscripcion: PropTypes.object,
};
