import PropTypes from "prop-types";
import { Card, CircularProgress, Fade, Icon, Input, Modal, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useEffect, useRef, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import SoftInput from "components/SoftInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalVerificarDocente({
  open,
  handleClose,
  usuario,
  handleCancel,
  handleResend,
}) {
  const inputRefs = useRef([]);
  const [code, setCode] = useState(Array(6).fill(""));
  const [isConfirmDisabled, setConfirmDisabled] = useState(true);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
      }, 100);
    }
  }, [open]);

  useEffect(() => {
    const isCodeComplete = code.every((digit) => digit !== "");
    setConfirmDisabled(!isCodeComplete);
  }, [code]);

  useEffect(() => {
    let timer;
    if (isResendDisabled && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setResendDisabled(false);
      setResendTimer(60);
    }
    return () => clearInterval(timer);
  }, [isResendDisabled, resendTimer]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      e.target.value = "";
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    if (e.key === "Enter" && index === 5) {
      handleVerificarCodigo();
    }
  };

  const [
    verificarCodigo,
    { data: dataVerificar, loading: loadingVerificar, error: errorVerificar, reset },
  ] = useMutation(gql`
    mutation verificarCrearPostulanteDocente(
      $email: String!
      $codigo: String!
      $aspirante: AspiranteDocenteInput!
    ) {
      verificarCrearPostulanteDocente(email: $email, codigo: $codigo, aspirante: $aspirante)
    }
  `);

  const handleResendCode = () => {
    handleResend();
    reset();
    // Limpiar código
    setCode(Array(6).fill(""));

    setResendDisabled(true);
    setResendTimer(60);
    inputRefs.current[0].focus();
  };

  const handleVerificarCodigo = async () => {
    let usuarioData = { ...usuario };
    delete usuarioData.provincia;
    delete usuarioData.localidad;
    await verificarCodigo({
      variables: {
        email: usuario.email,
        codigo: code.join(""),
        aspirante: usuarioData,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {dataVerificar ? "Postulación enviada" : "Confirmar preinscripción"}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                        if (dataVerificar) {
                          handleCancel();
                        }
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            {dataVerificar ? (
              <SoftBox>
                <SoftBox
                  sx={{
                    lineHeight: "normal",
                  }}
                >
                  <SoftTypography variant="h7" fontWeight="regular">
                    Se ha registrado con éxito su postulación. Nos pondremos en contacto con usted a
                    través de los medios de contacto que proporcionó en el formulario.
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={3}>
                  <SoftBox>
                    <SoftButton
                      color="primary"
                      fullWidth
                      circular
                      onClick={() => {
                        handleClose();
                        handleCancel();
                      }}
                    >
                      <Icon sx={{ fontWeight: "light" }}>arrow_forward</Icon>
                      &nbsp; Finalizar
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            ) : (
              <SoftBox>
                <SoftBox
                  sx={{
                    lineHeight: "normal",
                  }}
                >
                  <SoftTypography variant="h7" fontWeight="regular">
                    Para confirmar la preinscripción a la carrera, ingrese el código de verificación
                    de seis dígitos que le fue enviado a su correo electrónico.
                  </SoftTypography>
                </SoftBox>
                <SoftBox py={3}>
                  <Card>
                    <SoftBox p={3} textAlign="center">
                      <SoftTypography variant="h6" color="primary">
                        Código de verificación
                      </SoftTypography>
                      <SoftBox
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                          },
                        }}
                        my={2}
                        display="flex"
                        justifyContent="center"
                        gap={2}
                      >
                        {[...Array(6)].map((_, index) => (
                          <SoftBox key={index} sx={{ width: "50px" }}>
                            <Input
                              inputRef={(el) => (inputRefs.current[index] = el)}
                              sx={{
                                textAlign: "center",
                                border: "none",
                                fontSize: "1.5rem",
                                padding: "15px 0 15px 0 !important",
                                input: { fontSize: "1.5rem", textAlign: "center" },
                              }}
                              onChange={(e) => handleChange(e, index)}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              inputProps={{ maxLength: 1 }} // Limita a un solo carácter
                            />
                          </SoftBox>
                        ))}
                      </SoftBox>
                      <SoftBox
                        sx={{
                          display: {
                            xs: "block",
                            sm: "none",
                          },
                        }}
                        my={2}
                        display="flex"
                        justifyContent="center"
                        gap={2}
                      >
                        <SoftInput
                          value={code.join("")}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCode(value.split(""));
                          }}
                          inputProps={{ maxLength: 6 }}
                          fullWidth
                          sx={{
                            "& input": {
                              textAlign: "center",
                              fontSize: "1.5rem",
                            },
                          }}
                        />
                      </SoftBox>
                      <SoftBox mt={2}>
                        <SoftTypography variant="h7" color="error" fontWeight="regular">
                          {errorVerificar ? errorVerificar.message : ""}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
                <SoftBox>
                  <SoftBox>
                    <SoftButton
                      color="primary"
                      fullWidth
                      circular
                      disabled={isConfirmDisabled || loadingVerificar || code.join("").length < 6}
                      onClick={handleVerificarCodigo}
                    >
                      {loadingVerificar ? (
                        <CircularProgress
                          size={15}
                          sx={{
                            marginRight: 0.5,
                          }}
                          color="inherit"
                        />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>check</Icon>
                      )}
                      &nbsp; Confirmar
                    </SoftButton>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftButton
                      color={isResendDisabled ? "secondary" : "primary"}
                      fullWidth
                      variant="text"
                      circular
                      onClick={!isResendDisabled ? handleResendCode : null}
                      sx={{
                        cursor: isResendDisabled ? "not-allowed" : "pointer",
                      }}
                    >
                      <Icon sx={{ fontWeight: "light" }}>refresh</Icon>
                      &nbsp;{" "}
                      {isResendDisabled ? `Reenviar en ${resendTimer} segundos` : "Reenviar código"}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalVerificarDocente.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  usuario: PropTypes.object,
  handleCancel: PropTypes.func,
  handleResend: PropTypes.func,
};
