// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Fade, Grid, Icon, InputLabel, Modal, Skeleton, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import dayjs from "dayjs";
import SoftButton from "components/SoftButton";
import { API_URL } from "services/config";
import { formatMoneyPunto } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalAlumnoDetalles({ open, handleClose, refetch, idUsuario }) {
  const [getUsuario, { data: dataUsuario, loading }] = useLazyQuery(
    gql`
      query getUsuario($id: ID!) {
        usuario(id: $id) {
          id
          email
          nombre
          apellido
          dni
          fechaNacimiento
          pais {
            id
          }
          genero
          esDiscapacitado
          discapacidad
          localidad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
          telefono
          telefonoRespaldo
          curriculum
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { data: paises } = useQuery(
    gql`
      query getPaises {
        paises {
          id
          nombre
          nacionalidad
        }
      }
    `
  );

  useEffect(() => {
    if (idUsuario && open) {
      getUsuario({ variables: { id: idUsuario } });
    }
  }, [idUsuario, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              <SoftBox>
                <SoftTypography variant="h6">
                  {`Ver detalles de ${dataUsuario?.usuario?.apellido} ${dataUsuario?.usuario?.nombre}`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <Tooltip title="Cerrar" placement="top">
                  <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                    close
                  </Icon>
                </Tooltip>
              </SoftBox>
            </SoftBox>
            <SoftBox mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="username">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Email de usuario
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">
                      {dataUsuario?.usuario?.email || "No disponible"}
                    </SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre Completo
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">
                      {`${dataUsuario?.usuario?.apellido} ${dataUsuario?.usuario?.nombre}`}
                    </SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="dni">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      DNI
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">{`${formatMoneyPunto(
                      dataUsuario?.usuario?.dni
                    )}`}</SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="fechaNacimiento">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha de nacimiento
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">
                      {`${dayjs(dataUsuario?.usuario?.fechaNacimiento).format("DD/MM/YYYY")}`}
                    </SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="nacionalidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nacionalidad
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">
                      {paises?.paises.find((pais) => pais.id === dataUsuario?.usuario?.pais?.id)
                        ?.nacionalidad || "No disponible"}
                    </SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="genero">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Genero
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">
                      {dataUsuario?.usuario?.genero || "No disponible"}
                    </SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="esDiscapacitado">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Posee discapacidad
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">
                      {dataUsuario?.usuario?.esDiscapacitado === "Si"
                        ? dataUsuario?.usuario?.discapacidad
                        : "No"}
                    </SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="provincia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Lugar de residencia
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">
                      {`${dataUsuario?.usuario?.localidad?.nombre}, ${dataUsuario?.usuario?.localidad?.provincia?.nombre}`}
                    </SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="telefono">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Teléfono de contacto
                    </SoftTypography>
                  </InputLabel>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <SoftTypography variant="h6">
                      {dataUsuario?.usuario?.telefono || "No disponible"}
                    </SoftTypography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <SoftButton
                    disabled={!dataUsuario?.usuario?.curriculum || loading}
                    onClick={async () => {
                      const file = await fetch(`${API_URL}/${dataUsuario?.usuario?.curriculum}`);
                      const blob = await file.blob();
                      saveAs(
                        blob,
                        `Curriculum - ${dataUsuario?.usuario?.nombre} ${dataUsuario?.usuario?.apellido}.pdf`
                      );
                    }}
                    color="uproGreen"
                    circular
                    fullWidth
                  >
                    {dataUsuario?.usuario?.curriculum
                      ? "Descargar curriculum"
                      : "El alumno no ha subido su curriculum"}
                    <Icon>download</Icon>
                  </SoftButton>
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAlumnoDetalles.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  idUsuario: PropTypes.string,
  tipo: PropTypes.string,
};
