import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import cover from "assets/images/cover.png";
import logo from "assets/images/logo.png";
import logo_alt from "assets/images/logo_alt.png";
import ModalLoading from "components/Modals/Loading";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import dataPDF from "../data/dataPDF";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import Loading from "components/Carteles/Loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
};

export default function PDFResponsabilidad({ open, handleClose }) {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedSede, setSelectedSede] = useState(null);
  const [docente, setDocente] = useState();
  const [alumnos, setAlumnos] = useState([]);
  const [pagesInfo, setPagesInfo] = useState({ pages: 0, total: 0 });
  const { columns, getRows } = dataPDF;
  const [loading, setLoading] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);

  const { data: sedes } = useQuery(
    gql`
      query getSedes {
        sedes(order: { field: "nombre", order: ASC }) {
          id
          nombre
        }
      }
    `
  );

  const generatePDF = async () => {
    setLoading(true);
    handleSnackbar("Generando documento, por favor espere", "info");
    const element = document.getElementById("certificado");
    if (element) {
      const canvas = await html2canvas(element, { scale: 2 });
      const report = new jsPDF({
        orientation: "portrait",
        compress: true,
      });

      const pdfWidth = report.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      const addPageToPDF = async (page) => {
        setPage(page);
        await new Promise((resolve) => setTimeout(resolve, 100));
        const canvas = await html2canvas(element, { scale: 2 });
        const imgData = canvas.toDataURL("image/jpeg", 0.7);
        if (page > 1) {
          report.addPage();
        }
        const imgProps = report.getImageProperties(imgData);
        const imgWidth = pdfWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        report.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        if (page < pagesInfo.pages) {
          await addPageToPDF(page + 1);
        } else {
          report.save(
            `Nota de Responsabilidad de Diploma - ${
              sedes?.sedes?.find((sede) => sede.id === selectedSede)?.nombre || ""
            } - ${dayjs().format("DD-MM-YYYY")}.pdf`
          );
        }
      };

      await addPageToPDF(1);
      setPage(1);
      handleSnackbar("Documento generado correctamente", "success");
    }
    setLoading(false);
  };

  const [getAlumnos, { loading: loadingAlumnos }] = useLazyQuery(
    gql`
      query getEgresados($filter: JSON, $order: Order) {
        paginationInfo {
          pages
          total
        }
        egresados(order: $order, filter: $filter) {
          id
          nombre
          apellido
          dni
          carrera
          carreraSecundaria
          acreditacion
          diplomaEntregado
          orden
        }
      }
    `,
    {
      variables: {
        filter: { acreditacion: null },
        order: { field: "apellido", order: "ASC" },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (alumnos) {
      setRows(getRows(alumnos.slice((page - 1) * 10, page * 10)));
      setPagesInfo({ pages: Math.ceil(alumnos.length / 10), total: alumnos.length });
    }
  }, [alumnos, page]);

  useEffect(() => {
    if (open) {
      getAlumnos()
        .then((res) => {
          setAlumnos(res.data.egresados);
        })
        .catch((err) => {
          handleSnackbar("Ocurrió un error al cargar los datos", "error");
        });
    } else {
      setRows([]);
      setPagesInfo({ pages: 0, total: 0 });
      setPage(1);
      setAlumnos([]);
      setDocente();
      setSelectedSede(null);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        if (!loading) handleClose();
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <ModalLoading open={loading} />
            <SoftBox display="flex" justifyContent="space-between" p={3} pb={0}>
              <SoftBox>
                <SoftTypography variant="h6">Acta</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        if (!loading) handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3} pb={0} display="flex" gap={2}>
              <SoftBox
                sx={{
                  width: "100%",
                }}
              >
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Sede
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  label="Sede"
                  value={selectedSede || -1}
                  onChange={(e) => setSelectedSede(e.target.value)}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione una sede
                  </MenuItem>
                  {sedes?.sedes?.map((sede) => (
                    <MenuItem key={sede.id} value={sede.id}>
                      {sede.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </SoftBox>
              <SoftBox
                sx={{
                  width: "100%",
                }}
              >
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Nombre del jefe de sede
                  </SoftTypography>
                </InputLabel>
                <SoftInput
                  label="nombre"
                  placeholder="Ingrese el nombre del jefe de sede"
                  type="text"
                  name="tarea"
                  value={docente}
                  onChange={(e) => setDocente(e.target.value)}
                />
              </SoftBox>
            </SoftBox>
            <SoftBox id="certificado" p={3}>
              <SoftBox position="relative">
                <SoftBox
                  display="flex"
                  alignItems="top"
                  position="relative"
                  minHeight="18vh"
                  borderRadius="xl"
                  sx={{
                    backgroundImage: ({
                      functions: { rgba, linearGradient },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.primary.main, 0.6),
                        rgba(gradients.primary.state, 0.6)
                      )}, url(${cover})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Grid container justifyContent="center" p={4} mb={4} alignItems={"center"}>
                    <Grid item xs={12} sm={3}>
                      <SoftBox
                        component="img"
                        src={logo_alt}
                        alt="UPrO Logo"
                        width="100%"
                        p={3}
                        sx={{
                          minWidth: "200px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </SoftBox>
                <Card
                  sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                      rgba(white.main, 1),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 2,
                    py: 2,
                    px: 2,
                  }}
                >
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                      <SoftTypography variant="h6" fontWeight="light">
                        Sede
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {sedes?.sedes?.find((sede) => sede.id === selectedSede)?.nombre || (
                          <Skeleton variant="text" width={100} />
                        )}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox textAlign="right">
                      <SoftTypography variant="h6" fontWeight="light">
                        Fecha de emisión
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {dayjs().format("DD/MM/YYYY HH:mm")}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <SoftTypography variant="h5" fontWeight="bold" textAlign="center">
                        Nota de Responsabilidad de Diploma
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftTypography variant="h6" fontWeight="light">
                        Desde el área de Alumnos y Graduados se dejan en la sede{" "}
                        <strong>
                          {sedes?.sedes?.find((sede) => sede.id === selectedSede)?.nombre ||
                            "_________________"}
                        </strong>{" "}
                        el día <strong> {dayjs().format("DD/MM/YYYY")} </strong> bajo la
                        responsabilidad y resguardo del jefe de sede
                        <strong> {docente || "_________________"} </strong> la cantidad de{" "}
                        <strong>{alumnos?.length}</strong> diplomas de los siguientes alumnos y
                        carreras:
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      {loadingAlumnos ? (
                        <Loading />
                      ) : (
                        <SoftBox
                          sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                              "& td": {
                                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                  `${borderWidth[1]} solid ${borderColor}`,
                              },
                            },
                          }}
                        >
                          <DataTable columns={columns} rows={rows} />
                          <CustomPagination
                            loading={false}
                            length={rows?.length}
                            total={alumnos?.length}
                            pages={pagesInfo?.pages}
                            page={page}
                            setPage={setPage}
                            noCard
                          />
                        </SoftBox>
                      )}
                    </Grid>
                  </Grid>
                  <SoftBox display="flex" justifyContent="center" mt={3} alignItems="end">
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={2}
                      p={3}
                    >
                      <SoftTypography variant="caption" fontWeight="light">
                        ___________________________
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Área de Alumnos y Graduados
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={2}
                      p={3}
                    >
                      <SoftTypography variant="caption" fontWeight="light">
                        ___________________________
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Jefe de Sede
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="center" mt={3} alignItems="end">
                    <SoftBox component="img" src={logo} alt="UPrO Logo" width="90px" />
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="center" mt={3} alignItems="center">
                    <SoftBox textAlign="center">
                      <SoftTypography variant="caption" fontWeight="light">
                        Powered by&nbsp;
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="bold">
                        UPrO
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </SoftBox>
            <SoftBox
              p={3}
              pt={0}
              display={{
                xs: "none",
                sm: "flex",
              }}
              justifyContent="flex-end"
            >
              <SoftButton
                color="primary"
                onClick={generatePDF}
                disabled={loading || !selectedSede || !docente || !alumnos.length || loadingAlumnos}
                circular
              >
                <Icon>download</Icon>
                &nbsp;Descargar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

PDFResponsabilidad.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
