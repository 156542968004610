import { useState } from "react";
import { Modal, Fade, Backdrop, Card, Grid, Icon } from "@mui/material";
import PropTypes from "prop-types";
// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "50%", lg: "40%", xl: "30%" },
  p: 3,
};

export default function ModalConfirm({
  open,
  handleClose,
  title,
  message,
  func,
  pedirMotivo,
  icon,
  placeholder,
  buttonText,
}) {
  const [motivo, setMotivo] = useState("");
  const handleConfirm = () => {
    func(motivo);
    handleClose();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox display="flex" gap={1}>
            {icon ? <Icon fontSize="medium">{icon}</Icon> : null}
            <SoftTypography variant="h6">{title}</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Grid container justifyContent="start" alignItems="center" pb={1} pt={2}>
              <SoftTypography variant="h6" fontWeight="light">
                {message || "¿Esta seguro que quiere confirmar?"}
              </SoftTypography>
            </Grid>
          </SoftBox>
          {pedirMotivo && (
            <SoftBox>
              <SoftInput
                multiline
                rows={4}
                placeholder={placeholder}
                onChange={(e) => setMotivo(e.target.value)}
              />
            </SoftBox>
          )}
          <SoftBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            pt={3}
            gap={2}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <SoftButton variant="gradient" color="dark" onClick={handleClose} circular fullWidth>
              <Icon>undo</Icon>&nbsp; Volver
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="primary"
              onClick={handleConfirm}
              circular
              disabled={pedirMotivo && !motivo?.trim()}
              fullWidth
            >
              <Icon>check</Icon>&nbsp;
              {buttonText || "Confirmar"}
            </SoftButton>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalConfirm.defaultProps = {
  func: () => {},
  title: "Confirmar",
  message: "¿Esta seguro que quiere confirmar?",
  pedirMotivo: false,
  icon: "check_circle",
  placeholder: "Ingrese un motivo",
};

ModalConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  func: PropTypes.func,
  pedirMotivo: PropTypes.bool,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  buttonText: PropTypes.string,
};
