// @mui material components
import { Card, CircularProgress, Collapse, Fade, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import { useContext, useRef, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "30%", xxl: "30%" },
  overflow: "auto",
};

export default function ModalCurriculum({ open, handleClose, refetch }) {
  const { user } = useContext(UserContext);
  const [curriculum, setCurriculum] = useState(null);
  const { handleSnackbar } = useContext(MessageManager);
  const inputRef = useRef(null);

  const [saveCurriculumUser, { loading: loadingCurriculum }] = useMutation(
    gql`
      mutation saveCurriculumUser($idUser: ID!, $file: Upload!) {
        saveCurriculumUser(idUser: $idUser, file: $file)
      }
    `
  );

  const [saveUsuario, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveUser($input: UserInput!) {
        saveUser(input: $input) {
          id
        }
      }
    `
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox p={3} pb={0} display="flex" flexDirection="column" gap={1}>
            <SoftTypography variant="h6">
              Necesitamos tu curriculum para continuar con las pasantías
            </SoftTypography>
            <SoftTypography variant="h7" fontWeight="regular">
              Solo serán seleccionables para las pasantías aquellos alumnos que hayan subido su
              curriculum, no pierdas tu oportunidad!
            </SoftTypography>
          </SoftBox>
          <SoftBox p={3} pt={2} display="flex" flexDirection="column" gap={2}>
            <SoftBox>
              <Tooltip
                title={curriculum ? "Click para seleccionar otro archivo" : "Subir curriculum"}
                placement="top"
              >
                <SoftButton
                  circular
                  color={curriculum ? "uproBlue" : "uproYellow"}
                  fullWidth
                  onClick={() => inputRef.current.click()}
                >
                  <Icon>{curriculum ? "cloud_done" : "cloud_upload"}</Icon>
                  &nbsp;{" "}
                  {curriculum
                    ? curriculum.name?.length > 30
                      ? curriculum.name.slice(0, 30) + "..."
                      : curriculum.name
                    : "Subir curriculum"}
                </SoftButton>
              </Tooltip>
              <input
                ref={inputRef}
                type="file"
                style={{ display: "none" }}
                accept=".pdf"
                onChange={(e) => {
                  if (e.target.files[0] && e.target.files[0].size < 20000000) {
                    setCurriculum(e.target.files[0]);
                  } else {
                    handleSnackbar("El archivo debe ser menor a 20MB", "error");
                  }
                }}
              />
            </SoftBox>
            <SoftBox>
              <Tooltip title={"Darse de baja de las pasantías"} placement="top">
                <SoftButton
                  circular
                  color="primary"
                  fullWidth
                  onClick={() => {
                    saveUsuario({
                      variables: {
                        input: {
                          id: user.id,
                          aceptaPasantia: false,
                        },
                      },
                    })
                      .then(() => {
                        refetch();
                        handleSnackbar(
                          "Se ha eliminado correctamente su postulación para pasantías",
                          "success"
                        );
                        handleClose();
                      })
                      .catch((e) => {
                        handleSnackbar(
                          "Ocurrió un error, por favor intente nuevamente más tarde",
                          "error"
                        );
                      });
                  }}
                >
                  <Icon>arrow_downward</Icon>
                  &nbsp; Darme de baja
                </SoftButton>
              </Tooltip>
            </SoftBox>
            <Collapse in={curriculum} mountOnEnter unmountOnExit>
              <SoftBox>
                <SoftButton
                  fullWidth
                  color="uproGreen"
                  circular
                  disabled={!curriculum || loadingCurriculum}
                  onClick={() => {
                    saveCurriculumUser({
                      variables: {
                        idUser: user.id,
                        file: curriculum,
                      },
                    })
                      .then(() => {
                        refetch();
                        handleSnackbar("Curriculum subido correctamente", "success");
                        handleClose();
                        setCurriculum(null);
                      })
                      .catch((e) => {
                        handleSnackbar(
                          "Ocurrió un error, por favor intente nuevamente más tarde",
                          "error"
                        );
                      });
                  }}
                >
                  {loadingCurriculum ? "Subiendo" : "Subir"}
                  &nbsp;
                  {loadingCurriculum ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <Icon>save</Icon>
                  )}
                </SoftButton>
              </SoftBox>
            </Collapse>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCurriculum.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};
