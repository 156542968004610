import { gql, useMutation, useQuery } from "@apollo/client";
import { Card, Collapse, Fade, Icon, MenuItem, Modal, Select, Slide, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { useApolloClient } from "context/apolloClientContext";
import { UserContext } from "context/user";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";

const foodIcons = {
  Entrada: "restaurant",
  "Desayuno / Merienda": "emoji_food_beverage",
  "Plato principal": "lunch_dining",
  Postre: "icecream",
  Ensaladas: "rice_bowl",
  "Bebidas sin alcohol": "water_drop_outlined",
  "Bebidas con alcohol": "local_bar",
};

const getFoodIcon = (categoria) => {
  return foodIcons[categoria] || "restaurant";
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalConfirmarPedido({
  open,
  handleClose,
  carrito,
  fechaPedido,
  handleCloseAll,
  menusDelDia,
  horaReserva,
  turnoPedido,
  paraRetirar
}) {
  const client = useApolloClient("clientComedor");
  const [carritoPedido, setCarritoPedido] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [medioDePago, setMedioDePago] = useState(-1);
  const { handleSnackbar } = useContext(MessageManager);
  const { user } = useContext(UserContext);

  const [tipoUser, setTipoUser] = useState("");
  const [montoPlato, setMontoPlato] = useState(0);
  useEffect(() => {
    if (user.tipoUser === "Alumno") {
      setTipoUser("Alumno");
      setMontoPlato(2500);
    } else setTipoUser("NoAlumno"), setMontoPlato(3500);
  }, [user]);

  const { data: usuario } = useQuery(
    gql`
      query currentUser {
        currentUser {
          id
          tipoUser
          username
          nombre
          apellido
          email
        }
      }
    `
  );

  const { data: mediosDePago } = useQuery(
    gql`
      query mediosDePago {
        mediosDePago(filter: { online: true }) {
          id
          nombre
          cuotas
          alias
          cbu
          titular
        }
      }
    `,
    {
      client,
    }
  );

  const [saveVentaOnline, { data: dataSave, loading: loadingSave }] = useMutation(
    gql`
      mutation saveVentaOnline($input: VentaOnlineInput!) {
        saveVentaOnline(input: $input) {
          id
          pedido {
            id
          }
        }
      }
    `,
    {
      client,
    }
  );

  useEffect(() => {
    const newCarritoPedido = carrito.reduce((acc, producto) => {
      const index = acc.findIndex((item) => item?.id === producto?.id);
      if (index === -1) {
        acc.push({
          ...producto,
          cantidad: 1,
        });
      } else {
        acc[index].cantidad++;
      }
      return acc;
    }, []);
    setCarritoPedido(newCarritoPedido);
  }, [carrito]);

  useEffect(() => {
    if (mediosDePago?.mediosDePago && open) {
      setSuccess(false);
      setError(false);
      mediosDePago.mediosDePago.length
        ? setMedioDePago(mediosDePago.mediosDePago[0]?.id.toString())
        : setMedioDePago(-1);
    }
  }, [mediosDePago, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        if (!loadingSave) {
          handleClose();
        }
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          {success ? (
            <Slide in={open} direction="up" mountOnEnter unmountOnExit>
              <Card>
                <SoftBox
                  bgColor={getUproColor("uproGreen")}
                  p={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Icon
                    fontSize="large"
                    sx={{
                      color: "#fff",
                      animation: "shakes 1s",
                      "@keyframes shakes": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "25%": {
                          transform: "rotate(10deg)",
                        },
                        "50%": {
                          transform: "rotate(-20deg)",
                        },
                        "75%": {
                          transform: "rotate(15deg)",
                        },
                        "100%": {
                          transform: "rotate(-5deg)",
                        },
                      },
                    }}
                  >
                    check_circle
                  </Icon>
                  <SoftTypography variant="h5" color="white" fontWeight="bold" mt={2}>
                    {medioDePago == 1 ? "Pedido pendiente de pago" : "Pedido confirmado"}
                  </SoftTypography>
                  <SoftTypography variant="h6" color="white" fontWeight="bold" mt={1}>
                    Tu número de pedido es {dataSave?.saveVentaOnline?.pedido?.id}
                  </SoftTypography>
                  {medioDePago == 1 && (
                    <SoftTypography variant="caption" color="white" mt={1}>
                      <Icon>warning</Icon>
                      <SoftTypography variant="caption" color="white" fontWeight="bold">
                        {" "}
                        Recordá que deberas cargar el comprobante de pago en el detalle del pedido.
                      </SoftTypography>
                    </SoftTypography>
                  )}
                  <SoftBox mt={2}>
                    <SoftButton color="white" circular onClick={handleCloseAll}>
                      <SoftTypography variant="button" color="uproGreen">
                        CERRAR
                      </SoftTypography>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Slide>
          ) : error ? (
            <Slide in={open} direction="up" mountOnEnter unmountOnExit>
              <Card>
                <SoftBox
                  bgColor={getUproColor("uproPink")}
                  p={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Icon
                    fontSize="large"
                    sx={{
                      color: "#fff",
                      animation: "shakes 1s",
                      "@keyframes shakes": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "25%": {
                          transform: "rotate(10deg)",
                        },
                        "50%": {
                          transform: "rotate(-20deg)",
                        },
                        "75%": {
                          transform: "rotate(15deg)",
                        },
                        "100%": {
                          transform: "rotate(-5deg)",
                        },
                      },
                    }}
                  >
                    error
                  </Icon>
                  <SoftTypography variant="h5" color="white" fontWeight="bold" mt={2}>
                    Error al confirmar el pedido
                  </SoftTypography>
                  <SoftTypography variant="caption" color="white" mt={1}>
                    Ha ocurrido un error al confirmar el pedido. Por favor, intentá nuevamente más
                    tarde o comunicate con soporte.
                  </SoftTypography>
                  <SoftBox mt={2}>
                    <SoftButton color="white" circular onClick={handleClose}>
                      <SoftTypography variant="button" color="primary">
                        CERRAR
                      </SoftTypography>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Slide>
          ) : (
            <SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <SoftTypography variant="h6">Confirmar pedido</SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="space-between">
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
              <SoftBox pt={3}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  gap={2}
                >
                  <SoftBox>
                    <Card
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox p={2}>
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">event_available</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6">
                            Fecha del pedido&nbsp;
                            <Tooltip
                              placement="top"
                              title="Fecha en la que retirarás el pedido. No podrás retirarlo ni antes de la fecha ni pasada la misma."
                            >
                              <Icon
                                sx={{
                                  verticalAlign: "middle",
                                }}
                                color="secondary"
                              >
                                help
                              </Icon>
                            </Tooltip>
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mt={2}>
                          <SoftTypography
                            variant="h5"
                            fontWeight="bold"
                            textAlign="center"
                            color="primary"
                          >
                            {dayjs(fechaPedido).format("DD/MM/YYYY")}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                  <SoftBox>
                    <Card
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox p={2}>
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">attach_money</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6">Medio de pago</SoftTypography>
                        </SoftBox>
                        <SoftBox mt={2}>
                          <Select
                            fullWidth
                            variant="outlined"
                            value={medioDePago}
                            onChange={(e) => setMedioDePago(e.target.value)}
                          >
                            {mediosDePago?.mediosDePago.map((medio) => (
                              <MenuItem key={medio?.id} value={medio?.id.toString()}>
                                {medio.nombre}
                              </MenuItem>
                            ))}
                            {/* <MenuItem value={-1}>Cuenta corriente</MenuItem> */}
                          </Select>
                          {medioDePago == 1 && (
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              mt={2}
                              justifyContent="space-between"
                            >
                              <SoftBox display="flex" flexDirection="column">
                                <SoftTypography variant="caption" color="secondary">
                                  Alias:{" "}
                                  <SoftTypography
                                    variant="caption"
                                    fontWeight="bold"
                                    color="primary"
                                  >
                                    {
                                      mediosDePago?.mediosDePago.find(
                                        (medio) => medio?.id.toString() === medioDePago
                                      ).alias
                                    }
                                  </SoftTypography>
                                </SoftTypography>
                                <SoftTypography variant="caption" color="secondary">
                                  CBU:{" "}
                                  <SoftTypography
                                    variant="caption"
                                    fontWeight="bold"
                                    color="primary"
                                  >
                                    {
                                      mediosDePago?.mediosDePago.find(
                                        (medio) => medio?.id.toString() === medioDePago
                                      ).cbu
                                    }
                                  </SoftTypography>
                                </SoftTypography>

                                <SoftTypography variant="caption" color="secondary">
                                  Titular:{" "}
                                  <SoftTypography
                                    variant="caption"
                                    fontWeight="bold"
                                    color="primary"
                                  >
                                    {
                                      mediosDePago?.mediosDePago.find(
                                        (medio) => medio?.id.toString() === medioDePago
                                      ).titular
                                    }
                                  </SoftTypography>
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="column">
                                <Tooltip title="Copiar alias" placement="top">
                                  <Icon
                                    color="primary"
                                    fontSize="large"
                                    sx={{
                                      marginLeft: 1,
                                    }}
                                    onClick={() =>
                                      handleCopy(
                                        mediosDePago?.mediosDePago.find(
                                          (medio) => medio?.id.toString() === medioDePago
                                        ).alias
                                      )
                                    }
                                  >
                                    copy
                                  </Icon>
                                </Tooltip>
                              </SoftBox>
                            </SoftBox>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                  <Slide in={open} direction="up" mountOnEnter unmountOnExit>
                    <SoftBox
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Card
                        sx={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          zIndex: 2,
                        }}
                      >
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          px={2}
                          py={1}
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox mr={1} display="flex" alignItems="center">
                              <Icon color="primary">shopping_cart</Icon>
                            </SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                              Carrito
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <Collapse
                          in={open}
                          sx={{
                            maxHeight: {
                              xs: "100%",
                              sm: "50vh",
                            },
                            overflowY: "auto",
                          }}
                        >
                          {carrito.map((producto, index) => (
                            <Slide in={open} key={index} direction="up" mountOnEnter unmountOnExit>
                              <SoftBox>
                                <SoftBox
                                  px={2}
                                  py={1}
                                  sx={{
                                    borderBottom:
                                      index === carrito.length - 1 ||
                                      producto?.uniqueId ===
                                        carrito.filter((item) => !item.eliminado)?.slice(-1)[0]
                                          ?.uniqueId
                                        ? "none"
                                        : "1px solid rgba(0, 0, 0, 0.12)",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  <SoftBox
                                    display={{
                                      xs: "flex-row",
                                      sm: "flex",
                                    }}
                                    justifyContent="space-between"
                                  >
                                    <SoftBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent={{
                                        xs: "space-between",
                                        sm: "flex-start",
                                      }}
                                    >
                                      <SoftBox display="flex" alignItems="center">
                                        <SoftBox
                                          mr={{
                                            xs: 0,
                                            sm: 1,
                                          }}
                                          sx={{
                                            display: {
                                              xs: "none",
                                              sm: "block",
                                            },
                                          }}
                                        >
                                          <Tooltip
                                            title={producto?.categoriaProducto?.categoria}
                                            placement="top"
                                          >
                                            <Icon
                                              sx={{
                                                color: getUproColor("uproYellow"),
                                                cursor: "pointer",
                                              }}
                                            >
                                              {getFoodIcon(producto?.categoriaProducto?.categoria)}
                                            </Icon>
                                          </Tooltip>
                                        </SoftBox>
                                        <SoftBox display="flex" flexDirection="column">
                                          <SoftBox display="flex" alignItems="center" mr={2}>
                                            <SoftTypography variant="h6">
                                              {producto.nombre}
                                              {menusDelDia
                                                ?.map((menu) => menu.producto?.id)
                                                .includes(producto?.id) && (
                                                <Tooltip title="Oferta del día" placement="top">
                                                  <Icon
                                                    sx={{
                                                      marginLeft: 0.5,
                                                      color: getUproColor("uproGreen"),
                                                      verticalAlign: "middle",
                                                    }}
                                                  >
                                                    discount
                                                  </Icon>
                                                </Tooltip>
                                              )}
                                            </SoftTypography>
                                          </SoftBox>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      justifyContent="flex-end"
                                      mb={{
                                        xs: 1,
                                        sm: 0,
                                      }}
                                      mt={{
                                        xs: 2,
                                        sm: 0,
                                      }}
                                      alignItems="center"
                                    >
                                      <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="flex-end"
                                      >
                                        {menusDelDia
                                          ?.map((menu) => menu.producto?.id)
                                          .includes(producto?.id) && (
                                          <SoftTypography
                                            variant="caption"
                                            color="secondary"
                                            sx={{
                                              textDecoration: "line-through",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            $ {formatMoneyPunto(producto.precioCarta || 0)}
                                          </SoftTypography>
                                        )}
                                        <SoftTypography
                                          variant="h6"
                                          color={
                                            menusDelDia
                                              ?.map((menu) => menu.producto?.id)
                                              .includes(producto?.id)
                                              ? "primary"
                                              : "text"
                                          }
                                          sx={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          ${" "}
                                          {formatMoneyPunto(
                                            menusDelDia?.find(
                                              (menu) => menu.producto?.id === producto?.id
                                            )?.precio || montoPlato
                                          )}
                                        </SoftTypography>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </Slide>
                          ))}
                        </Collapse>
                      </Card>
                      <Card
                        sx={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          position: "sticky",
                          marginTop: -2,
                          zIndex: 1,
                        }}
                      >
                        <SoftBox bgColor="primary">
                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            bgColor="primary"
                            p={2}
                            pt={4}
                          >
                            <SoftTypography variant="h6" fontWeight="bold" color="white">
                              Total: ${" "}
                              {formatMoneyPunto(
                                carrito
                                  .filter((item) => !item.eliminado)
                                  .reduce(
                                    (acc, item) =>
                                      acc +
                                        menusDelDia?.find((menu) => menu.producto?.id === item?.id)
                                          ?.precio || montoPlato,
                                    0
                                  )
                              )}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </Card>

                      <SoftBox mt={2}>
                        <SoftButton
                          color="uproGreen"
                          circular
                          fullWidth
                          disabled={loadingSave || carritoPedido.length === 0 || !medioDePago}
                          onClick={() => {
                            console.log("medioDePago", medioDePago);
                            if (medioDePago === -1) {
                              saveVentaOnline({
                                variables: {
                                  input: {
                                    nombre: usuario?.currentUser?.nombre,
                                    apellido: usuario?.currentUser?.apellido,
                                    cliente: `${usuario?.currentUser?.nombre} ${usuario?.currentUser?.apellido}`,
                                    nro_doc: usuario?.currentUser?.dni,
                                    paraRetirar: paraRetirar,
                                    pagos: [
                                      // {
                                      //   nombre: mediosDePago?.mediosDePago.find(
                                      //     (medio) => medio?.id?.toString() == medioDePago
                                      //   )?.nombre,
                                      //   cuotas: mediosDePago?.mediosDePago?.find(
                                      //     (medio) => medio?.id?.toString() == medioDePago
                                      //   )?.cuotas,
                                      //   totalPago: 0,
                                      //   recargo: 0,
                                      //   totalRecargo: 0,
                                      //   idMedioDePago: medioDePago,
                                      // },
                                    ],
                                    appUsuario: "Alumnos",
                                    idUsuario: usuario?.currentUser?.id,
                                    tipoUsuario: usuario?.currentUser.tipoUser,
                                    detalles: carritoPedido
                                      .filter((item) => !item.eliminado)
                                      .map((producto) => ({
                                        idProducto: producto?.id,
                                        cantidad: producto.cantidad,
                                        precio:
                                          menusDelDia?.find(
                                            (menu) => menu.producto?.id === producto?.id
                                          )?.precio || montoPlato,
                                        idMenuDelDia: menusDelDia?.find(
                                          (menu) => menu.producto?.id === producto?.id
                                        )?.id,
                                      })),
                                    cuentaCorriente: true,
                                    fechaPedido: dayjs(fechaPedido).format("YYYY-MM-DD"),
                                    horaReserva:
                                      dayjs(fechaPedido).format("YYYY-MM-DD") + " " + horaReserva,
                                    turnoReserva: turnoPedido,
                                  },
                                },
                              })
                                .then(() => {
                                  handleSnackbar("Pedido realizado con éxito", "success");
                                  setSuccess(true);
                                })
                                .catch((err) => {
                                  handleSnackbar(
                                    err.message || "Ha ocurrido un error al realizar el pedido",
                                    "error"
                                  );
                                  setError(true);
                                  if (
                                    err.message ==
                                      "El horario seleccionado ya no tiene capacidad" ||
                                    err.message == "Hay productos sin stock"
                                  ) {
                                    handleCloseAll();
                                  }
                                });
                            } else {
                              saveVentaOnline({
                                variables: {
                                  input: {
                                    nombre: usuario?.currentUser?.nombre,
                                    apellido: usuario?.currentUser?.apellido,
                                    cliente: `${usuario?.currentUser?.nombre} ${usuario?.currentUser?.apellido}`,
                                    nro_doc: usuario?.currentUser?.dni,
                                    pagos: [
                                      {
                                        nombre: mediosDePago.mediosDePago.find(
                                          (medio) => medio?.id.toString() === medioDePago
                                        ).nombre,
                                        cuotas: mediosDePago.mediosDePago.find(
                                          (medio) => medio?.id.toString() === medioDePago
                                        ).cuotas,
                                        totalPago: 0,
                                        recargo: 0,
                                        totalRecargo: 0,
                                        idMedioDePago: medioDePago,
                                      },
                                    ],
                                    paraRetirar: paraRetirar,
                                    appUsuario: "Alumnos",
                                    idUsuario: usuario?.currentUser?.id,
                                    tipoUsuario: usuario?.currentUser.tipoUser,
                                    detalles: carritoPedido
                                      .filter((item) => !item.eliminado)
                                      .map((producto) => ({
                                        idProducto: producto?.id,
                                        cantidad: producto.cantidad,
                                        precio:
                                          menusDelDia?.find(
                                            (menu) => menu.producto?.id === producto?.id
                                          )?.precio || montoPlato,
                                        idMenuDelDia: menusDelDia?.find(
                                          (menu) => menu.producto?.id === producto?.id
                                        )?.id,
                                      })),
                                    fechaPedido: dayjs(fechaPedido).format("YYYY-MM-DD"),
                                    horaReserva:
                                      dayjs(fechaPedido).format("YYYY-MM-DD") + " " + horaReserva,
                                    turnoReserva: turnoPedido,
                                  },
                                },
                              })
                                .then(() => {
                                  handleSnackbar("Pedido realizado con éxito", "success");
                                  setSuccess(true);
                                })
                                .catch((err) => {
                                  handleSnackbar(
                                    err.message || "Ha ocurrido un error al realizar el pedido",
                                    "error"
                                  );
                                  setError(true);
                                  if (
                                    err.message ==
                                      "El horario seleccionado ya no tiene capacidad" ||
                                    err.message == "Hay productos sin stock"
                                  ) {
                                    handleCloseAll();
                                  }
                                });
                            }
                          }}
                        >
                          Realizar pedido
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Slide>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          )}
        </Card>
      </Fade>
    </Modal>
  );
}

ModalConfirmarPedido.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  carrito: PropTypes.array,
  fechaPedido: PropTypes.string,
  handleCloseAll: PropTypes.func,
  menusDelDia: PropTypes.array,
  horaReserva: PropTypes.string,
  turnoPedido: PropTypes.string,
  paraRetirar: PropTypes.bool,
};
