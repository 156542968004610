// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  Icon,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useLazyQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { GiRubberBoot, GiTShirt } from "react-icons/gi";
import { PiPantsFill } from "react-icons/pi";
import { tallesCalzado, tallesRopa } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import * as XLSX from "xlsx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "40%" },
  overflow: "auto",
};

export default function ModalTalles({ open, handleClose, idCarrera }) {
  const [selectedSede, setSelectedSede] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedNivel, setSelectedNivel] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [getCarrera, { data, loading, refetch }] = useLazyQuery(
    gql`
      query getCarrera($id: ID!) {
        carrera(id: $id) {
          nombre
          sedes {
            id
            nombre
          }
          planes {
            id
            nombre
            codigo
            niveles {
              id
              nombre
              codigo
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getNivel, { data: dataNivel, loading: loadingNivel }] = useLazyQuery(
    gql`
      query getNivel($id: ID!, $idSede: ID!) {
        nivel(id: $id) {
          nombre
          talles(idSede: $idSede) {
            calzado
            pantalon
            torso
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (idCarrera && open) {
      getCarrera({ variables: { id: idCarrera } });
    } else {
      setSelectedSede(null);
      setSelectedPlan(null);
      setSelectedNivel(null);
    }
  }, [idCarrera, open]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
              <SoftTypography variant="h6">{`Talles de indumentaria para ${makeNiceText(
                data?.carrera?.nombre
              )}`}</SoftTypography>
              <SoftBox display="flex" justifyContent="end" alignItems="center">
                <SoftBox display="flex" justifyContent="end" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3} display="flex" flexDirection="column" gap={2}>
              <SoftBox>
                {loading ? (
                  <Skeleton rounded width="100%" height={40} />
                ) : (
                  <Select
                    fullWidth
                    value={selectedSede || -1}
                    onChange={(e) => {
                      setSelectedSede(e.target.value);
                      setSelectedPlan(null);
                      setSelectedNivel(null);
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una sede
                    </MenuItem>
                    {data?.carrera?.sedes.map((sede) => (
                      <MenuItem key={sede.id} value={sede.id}>
                        {sede.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </SoftBox>
              <Collapse in={selectedSede} unmountOnExit mountOnEnter>
                <SoftBox>
                  <Select
                    fullWidth
                    value={selectedPlan || -1}
                    onChange={(e) => {
                      setSelectedPlan(e.target.value);
                      setSelectedNivel(null);
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione un plan
                    </MenuItem>
                    {data?.carrera?.planes.map((plan) => (
                      <MenuItem key={plan.id} value={plan.id}>
                        {plan.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </SoftBox>
              </Collapse>
              <Collapse in={selectedPlan} unmountOnExit mountOnEnter>
                <SoftBox>
                  <Select
                    fullWidth
                    value={selectedNivel || -1}
                    onChange={(e) => {
                      setSelectedNivel(e.target.value);
                      getNivel({ variables: { id: e.target.value, idSede: selectedSede } });
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione un nivel
                    </MenuItem>
                    {data?.carrera?.planes
                      .find((plan) => plan.id === selectedPlan)
                      ?.niveles.map((nivel) => (
                        <MenuItem key={nivel.id} value={nivel.id}>
                          {nivel.nombre}
                        </MenuItem>
                      ))}
                  </Select>
                </SoftBox>
              </Collapse>
              <Collapse in={selectedNivel} unmountOnExit mountOnEnter>
                <SoftBox display="flex" flexDirection="column" gap={2}>
                  <SoftBox>
                    <SoftBox
                      mr={2}
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                        width: "100%",
                      }}
                    >
                      <Tabs
                        value={selectedTab}
                        onChange={(e, value) => {
                          setSelectedTab(value);
                        }}
                      >
                        <Tab
                          label={
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox display="flex" alignItems="center">
                                <Icon color={selectedTab === 0 ? "primary" : "secondary"}>
                                  <GiRubberBoot size={16} />
                                </Icon>
                              </SoftBox>
                              <SoftTypography
                                variant="h6"
                                fontWeight={selectedTab === 0 ? "bold" : "regular"}
                              >
                                Calzado
                              </SoftTypography>
                            </SoftBox>
                          }
                          value={0}
                        />
                        <Tab
                          label={
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox display="flex" alignItems="center">
                                <Icon color={selectedTab === 1 ? "primary" : "secondary"}>
                                  <PiPantsFill size={16} />
                                </Icon>
                              </SoftBox>
                              <SoftTypography
                                variant="h6"
                                fontWeight={selectedTab === 1 ? "bold" : "regular"}
                              >
                                Pantalones
                              </SoftTypography>
                            </SoftBox>
                          }
                          value={1}
                        />
                        <Tab
                          label={
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox display="flex" alignItems="center">
                                <Icon color={selectedTab === 2 ? "primary" : "secondary"}>
                                  <GiTShirt size={16} />
                                </Icon>
                              </SoftBox>
                              <SoftTypography
                                variant="h6"
                                fontWeight={selectedTab === 2 ? "bold" : "regular"}
                              >
                                Torso
                              </SoftTypography>
                            </SoftBox>
                          }
                          value={2}
                        />
                      </Tabs>
                    </SoftBox>
                    <SoftBox
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                        },
                        width: "100%",
                      }}
                      mb={2}
                    >
                      <Select
                        id="selectSeccion"
                        value={selectedTab}
                        onChange={(e) => {
                          setSelectedTab(e.target.value);
                          refetch();
                        }}
                      >
                        <MenuItem value={0}>
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox display="flex" alignItems="center">
                              <Icon color={selectedTab === 0 ? "primary" : "secondary"}>
                                <GiRubberBoot size={16} />
                              </Icon>
                              &nbsp; Calzado
                            </SoftBox>
                          </SoftBox>
                        </MenuItem>
                        <MenuItem value={1}>
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox display="flex" alignItems="center">
                              <Icon color={selectedTab === 1 ? "primary" : "secondary"}>
                                <PiPantsFill size={16} />
                              </Icon>
                              &nbsp; Pantalones
                            </SoftBox>
                          </SoftBox>
                        </MenuItem>
                        <MenuItem value={2}>
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox display="flex" alignItems="center">
                              <Icon color={selectedTab === 2 ? "primary" : "secondary"}>
                                <GiTShirt size={16} />
                              </Icon>
                              &nbsp; Torso
                            </SoftBox>
                          </SoftBox>
                        </MenuItem>
                      </Select>
                    </SoftBox>
                  </SoftBox>
                  <Card>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftBox p={2} pb={0} display="flex" justifyContent="space-between">
                        <SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            Talle
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            Cantidad
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <Divider />
                      <SoftBox
                        sx={{
                          maxHeight: "50vh",
                          overflowY: "auto",
                        }}
                      >
                        <Collapse
                          in={selectedTab === 0 && !loadingNivel}
                          unmountOnExit
                          mountOnEnter
                        >
                          <SoftBox p={2} pt={0}>
                            {tallesCalzado.map((talle) => (
                              <SoftBox key={"talle"} display="flex" justifyContent="space-between">
                                <SoftBox>
                                  <SoftTypography variant="h7" fontWeight="regular">
                                    {talle}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox>
                                  <SoftTypography variant="h7" fontWeight="regular">
                                    {dataNivel?.nivel?.talles?.calzado?.find(
                                      (t) => t.talleZapato === talle
                                    )?.count || 0}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            ))}
                          </SoftBox>
                        </Collapse>
                        <Collapse
                          in={selectedTab === 1 && !loadingNivel}
                          unmountOnExit
                          mountOnEnter
                        >
                          <SoftBox p={2} pt={0}>
                            {tallesRopa.map((talle) => (
                              <SoftBox key={"talle"} display="flex" justifyContent="space-between">
                                <SoftBox>
                                  <SoftTypography variant="h7" fontWeight="regular">
                                    {talle}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox>
                                  <SoftTypography variant="h7" fontWeight="regular">
                                    {dataNivel?.nivel?.talles?.torso?.find(
                                      (t) => t.talleTorso === talle
                                    )?.count || 0}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            ))}
                          </SoftBox>
                        </Collapse>
                        <Collapse
                          in={selectedTab === 2 && !loadingNivel}
                          unmountOnExit
                          mountOnEnter
                        >
                          <SoftBox p={2} pt={0}>
                            {tallesRopa.map((talle) => (
                              <SoftBox key={"talle"} display="flex" justifyContent="space-between">
                                <SoftBox>
                                  <SoftTypography variant="h7" fontWeight="regular">
                                    {talle}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox>
                                  <SoftTypography variant="h7" fontWeight="regular">
                                    {dataNivel?.nivel?.talles?.pantalon?.find(
                                      (t) => t.tallePantalon === talle
                                    )?.count || 0}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            ))}
                          </SoftBox>
                        </Collapse>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                  <SoftBox mr={2}>
                    <Tooltip title="Descargar listado de alumnos" placement="top">
                      <SoftButton
                        color="uproBlue"
                        circular
                        fullWidth
                        onClick={() => {
                          let reporte = tallesCalzado || [];

                          let reporteExportar = reporte.map((item) => {
                            return {
                              talle: item,
                              cantidad:
                                dataNivel?.nivel?.talles?.calzado?.find(
                                  (t) => t.talleZapato === item
                                )?.count || 0,
                            };
                          });
                          const cabecera = [
                            [
                              `Talles de calzado - ${data?.carrera?.nombre} - ${
                                dataNivel?.nivel?.nombre
                              } - ${
                                data?.carrera?.sedes.find((sede) => sede.id === selectedSede)
                                  ?.nombre
                              }`,
                            ],
                          ];

                          const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                          XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                            skipHeader: false,
                            origin: -1,
                          });

                          sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                          const workbook = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(workbook, sheet, `Calzado`);

                          // Pantalones
                          reporte = tallesRopa || [];

                          reporteExportar = reporte.map((item) => {
                            return {
                              talle: item,
                              cantidad:
                                dataNivel?.nivel?.talles?.pantalon?.find(
                                  (t) => t.tallePantalon === item
                                )?.count || 0,
                            };
                          });

                          const cabecera2 = [
                            [
                              `Talles de pantalones - ${data?.carrera?.nombre} - ${
                                dataNivel?.nivel?.nombre
                              } - ${
                                data?.carrera?.sedes.find((sede) => sede.id === selectedSede)
                                  ?.nombre
                              }`,
                            ],
                          ];

                          const sheet2 = XLSX.utils.aoa_to_sheet(cabecera2);

                          XLSX.utils.sheet_add_json(sheet2, reporteExportar, {
                            skipHeader: false,
                            origin: -1,
                          });

                          sheet2["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                          XLSX.utils.book_append_sheet(workbook, sheet2, `Pantalones`);

                          // Torso

                          reporte = tallesRopa || [];

                          reporteExportar = reporte.map((item) => {
                            return {
                              talle: item,
                              cantidad:
                                dataNivel?.nivel?.talles?.torso?.find((t) => t.talleTorso === item)
                                  ?.count || 0,
                            };
                          });

                          const cabecera3 = [
                            [
                              `Talles de torso - ${data?.carrera?.nombre} - ${
                                dataNivel?.nivel?.nombre
                              } - ${
                                data?.carrera?.sedes.find((sede) => sede.id === selectedSede)
                                  ?.nombre
                              }`,
                            ],
                          ];

                          const sheet3 = XLSX.utils.aoa_to_sheet(cabecera3);

                          XLSX.utils.sheet_add_json(sheet3, reporteExportar, {
                            skipHeader: false,
                            origin: -1,
                          });

                          sheet3["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                          XLSX.utils.book_append_sheet(workbook, sheet3, `Torso`);

                          XLSX.writeFile(
                            workbook,
                            `Reporte de talles - ${data?.carrera?.nombre} - ${
                              dataNivel?.nivel?.nombre
                            } - ${
                              data?.carrera?.sedes.find((sede) => sede.id === selectedSede)?.nombre
                            } - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                          );
                        }}
                      >
                        Descargar listado
                        {false ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          <Icon>download</Icon>
                        )}
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              </Collapse>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalTalles.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idCarrera: PropTypes.string,
};
