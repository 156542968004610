import { gql, useMutation } from "@apollo/client";
import { Card, CircularProgress, Fade, Grid, Icon, Modal, Skeleton, Tooltip } from "@mui/material";
import cover from "assets/images/cover.png";
import logo from "assets/images/logo.png";
import logo_alt from "assets/images/logo_alt.png";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { API_URL } from "services/config";
import { makeNiceText } from "utils/formatters";
import { formatMoneyPunto } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
};

export default function ModalCertificado({ tipo, handleClose, usuario, carrera, cupo }) {
  const [file, setFile] = useState(null);
  const [certificado, setCertificado] = useState(null);
  const [addCertificado, { loading: loadingAdd, data: dataCertificado }] = useMutation(gql`
    mutation addCertificado($input: CertificadoInput!) {
      addCertificado(input: $input) {
        id
      }
    }
  `);

  const [updateCertificado] = useMutation(gql`
    mutation updateCertificado($file: Upload!, $id: ID!) {
      updateCertificado(file: $file, id: $id) {
        id
      }
    }
  `);

  const generatePDF = () => {
    if (certificado?.id && file) {
      file.save(`${certificado?.id} - ${tipo} - ${usuario?.dni}.pdf`);
    } else {
      addCertificado({
        variables: {
          input: {
            idUser: usuario.id,
            idCarrera: carrera.id,
            tipo: tipo,
          },
        },
      }).then((data) => {
        const element = document.getElementById("certificado");
        setCertificado(data.data.addCertificado);

        setTimeout(() => {
          if (element) {
            html2canvas(element, {
              scale: 2,
              useCORS: true,
            }).then((canvas) => {
              const imgData = canvas.toDataURL("image/jpeg", 0.9);
              const pdf = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: "a4",
                compress: true,
              });

              const pdfWidth = 210; // A4 width en mm
              const pdfHeight = 297; // A4 height en mm

              const imgRatio = canvas.width / canvas.height;
              const pageRatio = pdfWidth / pdfHeight;

              let imgWidth, imgHeight;
              if (imgRatio > pageRatio) {
                // Imagen más ancha que A4 -> Ajustar al ancho
                imgWidth = pdfWidth;
                imgHeight = pdfWidth / imgRatio;
              } else {
                // Imagen más alta que A4 -> Ajustar al alto
                imgHeight = pdfHeight;
                imgWidth = pdfHeight * imgRatio;
              }

              const posX = (pdfWidth - imgWidth) / 2;
              const posY = (pdfHeight - imgHeight) / 2;

              pdf.addImage(imgData, "JPEG", posX, posY, imgWidth, imgHeight);

              updateCertificado({
                variables: {
                  file: new File(
                    [pdf.output("blob")],
                    `${data.data.addCertificado.id} - ${tipo} - ${usuario?.dni}.pdf`
                  ),
                  id: data.data.addCertificado.id,
                },
              })
                .then(() => {
                  pdf.save(`${data.data.addCertificado.id} - ${tipo} - ${usuario?.dni}.pdf`);
                })
                .catch((err) => console.log(err));
            });
          }
        }, 500);
      });
    }
  };

  useEffect(() => {
    if (tipo && carrera && usuario) {
      setTimeout(() => {
        generatePDF();
      }, 500);
    } else {
      setFile(null);
    }
  }, [tipo]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Boolean(tipo)}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={tipo}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3} pb={0}>
              <SoftBox>
                <SoftTypography variant="h6">Certificado generado</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox id="certificado" p={3}>
              <SoftBox position="relative">
                <SoftBox
                  display="flex"
                  alignItems="top"
                  position="relative"
                  minHeight="18vh"
                  borderRadius="xl"
                  sx={{
                    backgroundImage: ({
                      functions: { rgba, linearGradient },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.primary.main, 0.6),
                        rgba(gradients.primary.state, 0.6)
                      )}, url(${cover})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Grid container justifyContent="center" p={4} mb={4} alignItems={"center"}>
                    <Grid item xs={6} lg={4}>
                      <SoftBox component="img" src={logo_alt} alt="UPrO Logo" width="100%" p={3} />
                    </Grid>
                  </Grid>
                </SoftBox>
                <Card
                  sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                      rgba(white.main, 1),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 2,
                    py: 2,
                    px: 2,
                  }}
                >
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                      <SoftTypography variant="h6" fontWeight="light">
                        Número de certificado
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {dataCertificado?.addCertificado?.id || (
                          <Skeleton variant="text" width={100} />
                        )}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox textAlign="right">
                      <SoftTypography variant="h6" fontWeight="light">
                        Fecha de emisión
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {dayjs().format("DD/MM/YYYY HH:mm")}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <SoftTypography variant="h5" fontWeight="bold" textAlign="center">
                        {tipo}
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftTypography variant="h6" fontWeight="light">
                        El presente certificado acredita que{" "}
                        {usuario?.genero === "Femenino" ? "la alumna" : "el alumno"}{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {makeNiceText(`${usuario?.nombre} ${usuario?.apellido}`, "nombre")}
                        </span>
                        , DNI{" "}
                        <span style={{ fontWeight: "bold" }}>{formatMoneyPunto(usuario?.dni)}</span>{" "}
                        ha realizado la{" "}
                        {tipo === "Certificado de Inscripción" ? "inscripción" : "preinscripción"} a
                        la carrera{" "}
                        <span
                          style={{ fontWeight: "bold" }}
                        >{`${carrera?.codigo} - ${carrera?.nombre}`}</span>{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {tipo === "Certificado de Inscripción" && cupo
                            ? ` en el cupo ${cupo?.nombre} de la sede ${cupo?.sede?.nombre} `
                            : ""}
                        </span>
                        en la Universidad Provincial de Oficios.
                      </SoftTypography>
                    </Grid>
                  </Grid>
                  <SoftBox display="flex" justifyContent="space-between" mt={3} alignItems="end">
                    <SoftBox component="img" src={logo} alt="UPrO Logo" width="90px" />
                    {certificado?.id ? (
                      <Card>
                        <SoftBox p={2} display="flex" justifyContent="center" alignItems="center">
                          <QRCode
                            size={90}
                            style={{ height: "auto", maxWidth: "90px", width: "90px" }}
                            value={`${API_URL}/certificado/${certificado?.id}`}
                            viewBox={`0 0 90 90`}
                          />
                        </SoftBox>
                      </Card>
                    ) : (
                      <Card>
                        <SoftBox p={2} display="flex" justifyContent="center" alignItems="center">
                          <Skeleton
                            variant="rectangular"
                            sx={{
                              borderRadius: "10px",
                            }}
                            width={90}
                            height={90}
                          />
                        </SoftBox>
                      </Card>
                    )}
                    {/* <SoftBox component="img" src={firma} alt="UPrO Logo" width="120px" /> */}
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="center" mt={3} alignItems="center">
                    <SoftBox textAlign="center">
                      <SoftTypography variant="caption" fontWeight="light">
                        Powered by&nbsp;
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="bold">
                        UPrO
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3} pt={0}>
              <SoftButton
                color="primary"
                onClick={generatePDF}
                circular
                fullWidth
                disabled={loadingAdd}
              >
                {loadingAdd ? (
                  <CircularProgress
                    size={15}
                    sx={{
                      marginRight: 0.5,
                    }}
                    color="white"
                  />
                ) : (
                  <Icon>download</Icon>
                )}
                &nbsp;Descargar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCertificado.propTypes = {
  handleClose: PropTypes.func,
  tipo: PropTypes.string,
  usuario: PropTypes.object,
  carrera: PropTypes.object,
  cupo: PropTypes.object,
};
