// @mui material components
import { Card, Grid, Icon, Slide, Tooltip, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";

// Kaizen Dashboard components
import { gql, useQuery } from "@apollo/client";
import DoughnutChart from "components/Charts/DoughnutCharts/CustomDoughnutChart";
import BubbleMap from "components/Map/BubbleMap";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor, uproColors } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import * as XLSX from "xlsx";
import SoftInput from "components/SoftInput";

function ReporteGeografico({ tipo, sedes, tipoCarrera }) {
  const [date, setDate] = useState({
    fechaInicio: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  });
  const [expandSelector, setExpandSelector] = useState(false);
  const [selectedSede, setSelectedSede] = useState();
  const [expandSedes, setExpandSedes] = useState(false);
  const [expandLocalidades, setExpandLocalidades] = useState(false);
  const [selectedLocalidad, setSelectedLocalidad] = useState(null);
  const { data: reportes } = useQuery(
    gql`
      query getReportes(
        $fechaInicio: String
        $fechaFin: String
        $tipo: [String]
        $idSede: ID
        $tipoCarrera: String
      ) {
        reporteGeografico(
          fechaInicio: $fechaInicio
          fechaFin: $fechaFin
          tipo: $tipo
          idSede: $idSede
          tipoCarrera: $tipoCarrera
        ) {
          totalInscripciones
          inscripcionesLocalidad {
            cantidad
            localidad {
              id
              nombre
              latitud
              longitud
              provincia {
                id
                nombre
              }
            }
          }
          inscripcionesPais {
            cantidad
            pais {
              id
              nombre
              nacionalidad
            }
          }
        }
      }
    `,
    {
      variables: {
        fechaInicio: date.fechaInicio,
        fechaFin: date.fechaFin,
        tipo: tipo.split(","),
        idSede: selectedSede?.id || null,
        tipoCarrera,
      },
    }
  );
  const { menu } = useContext(MenuContext);

  return (
    <Card>
      <SoftBox p={3}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
          <SoftTypography variant="h6">Reporte de preinscripciones por ubicación</SoftTypography>
          <SoftBox display="flex" justifyContent="start" alignItems="center">
            <SoftBox mr={2}>
              <SoftButton
                color="uproGreen"
                circular
                onClick={(event) => {
                  setExpandSedes(!expandSedes);
                  menu({
                    open: event.currentTarget,
                    align: "right",
                    onClose: () => {
                      setExpandSedes(false);
                    },
                    options: [
                      {
                        name: "Todas las sedes",
                        icon: {
                          icon: !selectedSede ? "location_on" : "location_city",
                          color: !selectedSede
                            ? getUproColor("uproPink")
                            : getUproColor("uproGreen"),
                        },
                        onClick: () => {
                          setSelectedSede(null);
                        },
                      },
                      ...sedes.map((sede) => ({
                        name: sede.nombre,
                        icon: {
                          icon: selectedSede?.id === sede.id ? "location_on" : "location_city",
                          color:
                            selectedSede?.id === sede.id
                              ? getUproColor("uproPink")
                              : getUproColor("uproGreen"),
                        },
                        onClick: () => {
                          setSelectedSede(sede);
                        },
                      })),
                    ],
                    handleClose: () => {
                      setExpandSelector(false);
                    },
                  });
                }}
              >
                <Icon fontSize="small">apartment</Icon>
                &nbsp; {selectedSede?.nombre || "Todas las sedes"} &nbsp;
                <Icon
                  sx={{
                    rotate: expandSedes ? "180deg" : "0deg",
                    transition: "all 0.5s",
                  }}
                >
                  expand_more
                </Icon>
              </SoftButton>
            </SoftBox>
            <SoftBox display="flex" gap={2}>
              <Tooltip title="Fecha de inicio" placement="top">
                <SoftInput
                  value={dayjs(date.fechaInicio).format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setDate({
                      ...date,
                      fechaInicio: dayjs(event.target.value).startOf("day").format("YYYY-MM-DD"),
                    });
                  }}
                  type="date"
                  error={dayjs(date.fechaInicio).isAfter(dayjs(date.fechaFin))}
                  inputProps={{
                    max: dayjs(date.fechaFin).format("YYYY-MM-DD"),
                  }}
                />
              </Tooltip>
              <Tooltip title="Fecha de fin" placement="top">
                <SoftInput
                  value={dayjs(date.fechaFin).format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setDate({
                      ...date,
                      fechaFin: dayjs(event.target.value)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss"),
                    });
                  }}
                  type="date"
                  error={dayjs(date.fechaInicio).isAfter(dayjs(date.fechaFin))}
                  inputProps={{
                    min: dayjs(date.fechaInicio).format("YYYY-MM-DD"),
                  }}
                />
              </Tooltip>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Card
              sx={{
                height: "100%",
              }}
            >
              <SoftBox p={3} pb={0}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">Mapa</SoftTypography>
                  <SoftButton
                    size="small"
                    variant="gradient"
                    color="dark"
                    circular
                    iconOnly
                    onClick={(event) => {
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        options: [
                          {
                            name: "Descargar reporte",
                            icon: { icon: "download", color: getUproColor("uproGreen") },
                            onClick: () => {
                              let reporte =
                                reportes?.reporteGeografico?.inscripcionesLocalidad || [];
                              let reporteOrdenado = [...reporte].sort(
                                (a, b) => b.cantidad - a.cantidad
                              );

                              let reporteExportar = reporteOrdenado.map((item) => {
                                return tipo === "Preinscripto"
                                  ? {
                                      Provincia: item.localidad.provincia.nombre,
                                      Localidad: item.localidad.nombre,
                                      "Cantidad de preinscriptos": item.cantidad,
                                    }
                                  : {
                                      Provincia: item.localidad.provincia.nombre,
                                      Localidad: item.localidad.nombre,
                                      "Cantidad de inscriptos": item.cantidad,
                                    };
                              });
                              const cabecera = [
                                [
                                  `Cantidad de ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por ubicación - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte ${
                                  tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                } por ubicación - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            },
                          },
                        ],
                        handleClose: () => {},
                      });
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </SoftButton>
                </SoftBox>
              </SoftBox>
              <SoftBox
                sx={{
                  minHeight: "50vh",
                  height: "100%",
                }}
                p={3}
                pt={0}
              >
                <BubbleMap
                  markers={reportes?.reporteGeografico?.inscripcionesLocalidad
                    .filter((item) => item.localidad.latitud && item.localidad.longitud)
                    .map((item, index) => ({
                      id: item.localidad.id,
                      color: uproColors[index],
                      latitud: item.localidad.latitud,
                      longitud: item.localidad.longitud,
                      cantidad: item.cantidad,
                      title: item.localidad.nombre,
                    }))}
                  selectedItem={selectedLocalidad}
                />
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{
                height: "100%",
              }}
            >
              <SoftBox
                p={3}
                sx={{
                  height: "100%",
                }}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <SoftBox>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                    <SoftTypography variant="h6">Preinscripciones por localidad</SoftTypography>
                    <Icon color="secondary">map</Icon>
                  </SoftBox>
                  <SoftBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <SoftBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      sx={{
                        listStyle: "none",
                        maxHeight: "50vh",
                        overflowY: "auto",
                        transition: "all 150ms ease",
                      }}
                      pr={2}
                    >
                      {reportes?.reporteGeografico?.inscripcionesLocalidad?.map((item, index) => (
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          key={index}
                          mb={0.5}
                        >
                          <Tooltip
                            placement="top"
                            title={`${item.localidad.nombre} - ${item.cantidad} inscriptos`}
                          >
                            <SoftBox
                              display="flex"
                              justifyContent="start"
                              alignItems="center"
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (selectedLocalidad?.id === item.localidad.id) {
                                  setSelectedLocalidad(null);
                                } else {
                                  setSelectedLocalidad(item.localidad);
                                }
                              }}
                            >
                              <SoftBox mr={2}>
                                <SoftButton
                                  size="small"
                                  variant="gradient"
                                  color={
                                    selectedLocalidad?.id === item.localidad.id
                                      ? "primary"
                                      : "uproGreen"
                                  }
                                  circular
                                  iconOnly
                                >
                                  {index + 1}
                                </SoftButton>
                              </SoftBox>
                              <SoftBox>
                                <SoftTypography variant="button" fontWeight="medium">
                                  {item.localidad.nombre.length > 40
                                    ? `${item.localidad.nombre.slice(0, 40)}...`
                                    : item.localidad.nombre}
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Tooltip>
                          <SoftBox>
                            <SoftTypography variant="button" fontWeight="medium">
                              {item.cantidad}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      ))}
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Card>
              <SoftBox p={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">Origen de los inscriptos</SoftTypography>
                  <SoftButton
                    size="small"
                    variant="gradient"
                    color="dark"
                    circular
                    iconOnly
                    onClick={(event) => {
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        options: [
                          {
                            name: "Descargar reporte",
                            icon: { icon: "download", color: getUproColor("uproGreen") },
                            onClick: () => {
                              let reporte = reportes?.reporteGeografico?.inscripcionesPais || [];
                              let reporteOrdenado = [...reporte].sort(
                                (a, b) => b.cantidad - a.cantidad
                              );

                              let reporteExportar = reporteOrdenado.map((item) => {
                                return tipo === "Preinscripto"
                                  ? {
                                      País: item.pais.nombre,
                                      "Cantidad de preinscriptos": item.cantidad,
                                    }
                                  : {
                                      País: item.pais.nombre,
                                      "Cantidad de inscriptos": item.cantidad,
                                    };
                              });
                              const cabecera = [
                                [
                                  `Cantidad de ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por país - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte ${
                                  tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                } por país - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            },
                          },
                        ],
                        handleClose: () => {},
                      });
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </SoftButton>
                </SoftBox>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      {reportes?.reporteGeografico?.inscripcionesPais?.map((item, index) => (
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          key={index}
                        >
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox mr={2}>
                              <SoftButton
                                size="small"
                                variant="color"
                                color={uproColors[index]}
                                circular
                                iconOnly
                              />
                            </SoftBox>
                            <SoftTypography variant="button" fontWeight="medium">
                              {`${item.pais.nombre} (${item.cantidad})`}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      ))}
                      <SoftBox pt={2}>
                        <SoftButton variant="gradient" color="uproGreen" circular>
                          {`Total ${formatMoneyPunto(
                            reportes?.reporteGeografico?.totalInscripciones || 0
                          )}`}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DoughnutChart
                      id={4}
                      title="Cantidad de preinscriptos por genero"
                      chart={{
                        labels: reportes?.reporteGeografico?.inscripcionesPais?.map(
                          (item) => item.pais.nombre
                        ),
                        datasets: {
                          backgroundColors: uproColors,
                          data: reportes?.reporteGeografico?.inscripcionesPais?.map(
                            (item) => item.cantidad
                          ),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{
                height: "100%",
              }}
            >
              <SoftBox p={3} pb={0}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">Resumen</SoftTypography>
                  <Icon fontSize="small" color="secondary">
                    checklist
                  </Icon>
                </SoftBox>
              </SoftBox>
              <SoftBox>
                <Grid
                  container
                  textAlign={"center"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  py={3}
                >
                  <Grid item xs={12}>
                    <Icon
                      sx={{
                        width: "50px",
                      }}
                    >
                      flight_land
                    </Icon>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6">Preinscriptos extranjeros</SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h3" fontWeight="bold" color="primary" textGradient>
                      {reportes?.reporteGeografico?.inscripcionesPais
                        .filter((item) => item.pais.nombre !== "Argentina")
                        .map((item) => item.cantidad)
                        .reduce((a, b) => a + b, 0) || 0}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <Icon
                      sx={{
                        width: "50px",
                      }}
                    >
                      travel_explore
                    </Icon>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6">País extranjero con más inscriptos</SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h3" fontWeight="bold" color="primary" textGradient>
                      {
                        reportes?.reporteGeografico?.inscripcionesPais.filter(
                          (item) => item.pais.nombre !== "Argentina"
                        )[0]?.pais.nombre
                      }
                    </SoftTypography>
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

ReporteGeografico.defaultProps = {
  tipo: "Preinscripto",
  sedes: [],
  tipoCarrera: "Carrera",
};

ReporteGeografico.propTypes = {
  tipo: PropTypes.string,
  sedes: PropTypes.array,
  tipoCarrera: PropTypes.string,
};

export default ReporteGeografico;
