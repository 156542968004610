// React
import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  ImageList,
  ImageListItem,
  Input,
  Modal,
  Tooltip,
} from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/CheckBox";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { MessageManager } from "context";
import { useApolloClient } from "context/apolloClientContext";

const allowedFileTypes = ".jpg, .jpeg, .png, .gif, .pdf";

const GET_MEDIO_PAGO = gql`
  query mediosDePago($id: ID!) {
    medioPago(id: $id) {
      id
      nombre
      alias
      cbu
      titular
    }
  }
`;

const CARGAR_COMPROBANTE = gql`
  mutation cargarComprobantePago($id: ID!, $comprobantes: [Upload!]!) {
    cargarComprobantePago(id: $id, comprobantes: $comprobantes) {
      id
    }
  }
`;

export default function ModalFotos({ open, handleClose, refetch, pedido }) {
  const [files, setFiles] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);
  const client = useApolloClient("clientComedor");
  const [getMedioPago, { data: medioPago }] = useLazyQuery(GET_MEDIO_PAGO, {
    client: client,
    variables: { id: 1 },
  });

  const [cargarComprobantePago, { loading }] = useMutation(CARGAR_COMPROBANTE);
  const handleFiles = (e) => {
    const filesArray = Array.from(e.target.files);
    setFiles((prev) => [...prev, ...filesArray]);
  };

  const handleSave = async () => {
    let id = pedido.venta.id;

    if (files) {
      cargarComprobantePago({ client, variables: { id, comprobantes: files } })
        .then(() => {
          refetch();
          handleSnackbar("Comprobante cargado con éxito", "success");
        })
        .catch((error) => {
          handleSnackbar(error.message, "error");
        });
      refetch();
    }
    handleClose();
  };

  useEffect(() => {
    getMedioPago();
    setFiles([]);
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: { xs: "90%", sm: "80%" },
            width: { xs: "80%", sm: "40%" },
            overflowY: "auto",
            p: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" flexDirection="row" justifyContent="space-between">
              <SoftTypography variant="h6" align="center">
                Cargar el comprobante
              </SoftTypography>
              <Tooltip title="Cerrar" placement="top">
                <Icon
                  sx={{ color: "primary", cursor: "pointer", mx: 0.5 }}
                  fontSize="small"
                  onClick={handleClose}
                >
                  close
                </Icon>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <SoftTypography variant="body2" align="center">
                {medioPago?.medioPago?.nombre}
              </SoftTypography>
              <SoftBox
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="flex-start"
                fullWidth
              >
                <SoftTypography variant="button" align="center">
                  Alias:{" "}
                  <SoftTypography variant="button" color="primary" fontWeight="bold">
                    {medioPago?.medioPago?.alias}
                  </SoftTypography>
                </SoftTypography>
                <SoftTypography variant="button" align="center">
                  CBU:{" "}
                  <SoftTypography variant="button" color="primary" fontWeight="bold">
                    {medioPago?.medioPago?.cbu}
                  </SoftTypography>
                </SoftTypography>
                <SoftTypography variant="button" align="center">
                  Titular:{" "}
                  <SoftTypography variant="button" color="primary" fontWeight="bold">
                    {medioPago?.medioPago?.titular}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12}>
              <SoftButton
                color="primary"
                fullWidth
                onClick={() => {
                  document.getElementById("input-file").click();
                }}
              >
                Agregar <Icon>upload</Icon>
              </SoftButton>
            </Grid>
            <Grid item xs={12}>
              <ImageList sx={{ height: 200 }} cols={3} rowHeight={164}>
                {files.length ? (
                  <>
                    {files?.map((file, index) => (
                      <ImageListItem
                        key={index}
                        sx={{
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        {file.type?.startsWith("image") ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={"foto-" + index}
                            loading="lazy"
                            style={{ objectFit: "contain", width: "100%", height: "100%" }}
                          />
                        ) : (
                          <SoftBox
                            width="100%"
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Icon fontSize="large">insert_drive_file</Icon>
                          </SoftBox>
                        )}
                        <Tooltip title="Eliminar" placement="top">
                          <Icon
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              cursor: "pointer",
                              color: "white",
                              backgroundColor: "rgba(255,255,255,0.5)",
                              borderRadius: "50%",
                            }}
                            onClick={() => {
                              const newImagenes = files.filter((imagen, i) => i !== index);
                              setFiles(newImagenes);
                            }}
                          >
                            cancel
                          </Icon>
                        </Tooltip>
                      </ImageListItem>
                    ))}
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    ></Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                      <SinDatos
                        titulo="No hay documentos cargados"
                        mensaje="Los documentos cargados se mostrarán aquí"
                        icono="upload_file"
                      />
                    </Grid>
                  </>
                )}
              </ImageList>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              sx={{
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </Grid>
              <Grid
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={handleSave}
                  disabled={loading || !files.length}
                >
                  <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  &nbsp;Guardar
                </SoftButton>
              </Grid>
            </Grid>
            <Grid sx={{ display: "none" }}>
              <Input
                type="file"
                id="input-file"
                onChange={handleFiles}
                name="fotos"
                inputProps={{
                  accept: allowedFileTypes,
                  multiple: true,
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalFotos.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  pedido: PropTypes.object,
};
