// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import { useContext, useState } from "react";
import { tallesCalzado, tallesRopa } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "30%", xxl: "30%" },
  overflow: "auto",
};

export default function ModalTalles({ open, handleClose, refetch }) {
  const [selectedCalzado, setSelectedCalzado] = useState(null);
  const [selectedPantalon, setSelectedPantalon] = useState(null);
  const [selectedTorso, setSelectedTorso] = useState(null);
  const { user } = useContext(UserContext);
  const { handleSnackbar } = useContext(MessageManager);

  const [saveUser, { loading: loadingSave }] = useMutation(gql`
    mutation saveUser($input: UserInput!) {
      saveUser(input: $input) {
        id
      }
    }
  `);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
              <SoftTypography variant="h6">
                Necesitamos que cargues tus talles para la ropa de la carrera
              </SoftTypography>
            </SoftBox>
            <SoftBox p={3} pt={1} display="flex" flexDirection="column" gap={2}>
              <SoftBox>
                <InputLabel htmlFor="talleCalzado">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Talle de calzado
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  value={selectedCalzado || -1}
                  onChange={(e) => {
                    setSelectedCalzado(e.target.value);
                  }}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione un talle
                  </MenuItem>
                  {tallesCalzado.map((talle) => (
                    <MenuItem key={talle} value={talle}>
                      {talle}
                    </MenuItem>
                  ))}
                </Select>
              </SoftBox>
              <SoftBox>
                <InputLabel htmlFor="tallePantalon">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Talle de pantalón
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  value={selectedPantalon || -1}
                  onChange={(e) => {
                    setSelectedPantalon(e.target.value);
                  }}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione un talle
                  </MenuItem>
                  {tallesRopa.map((talle) => (
                    <MenuItem key={talle} value={talle}>
                      {talle}
                    </MenuItem>
                  ))}
                </Select>
              </SoftBox>
              <SoftBox>
                <InputLabel htmlFor="talleTorso">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Talle de torso
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  value={selectedTorso || -1}
                  onChange={(e) => {
                    setSelectedTorso(e.target.value);
                  }}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione un talle
                  </MenuItem>
                  {tallesRopa.map((talle) => (
                    <MenuItem key={talle} value={talle}>
                      {talle}
                    </MenuItem>
                  ))}
                </Select>
              </SoftBox>
              <SoftBox>
                <Tooltip title="Guardar talles" placement="top">
                  <SoftButton
                    onClick={() => {
                      saveUser({
                        variables: {
                          input: {
                            id: user.id,
                            talleZapato: selectedCalzado,
                            tallePantalon: selectedPantalon,
                            talleTorso: selectedTorso,
                          },
                        },
                      })
                        .then(() => {
                          handleSnackbar("Talles guardados correctamente", "success");
                          handleClose();
                          refetch();
                        })
                        .catch((error) => {
                          handleSnackbar(
                            "Hubo un error al guardar los talles, por favor intenta nuevamente más tarde",
                            "error"
                          );
                          handleClose();
                          refetch();
                        });
                    }}
                    color="uproGreen"
                    disabled={
                      !selectedCalzado || !selectedPantalon || !selectedTorso || loadingSave
                    }
                    circular
                    fullWidth
                  >
                    Guardar&nbsp;
                    {loadingSave ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <Icon>save</Icon>
                    )}
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalTalles.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};
