import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Icon,
  MenuItem,
  Modal,
  Select,
  Slide,
  Tooltip,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { useApolloClient } from "context/apolloClientContext";
import { UserContext } from "context/user";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalInfo({ open, handleCloseAll, success, error }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        handleCloseAll();
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          {success && (
            <Slide in={open} direction="up" mountOnEnter unmountOnExit>
              <Card>
                <SoftBox
                  bgColor={getUproColor("uproGreen")}
                  p={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Icon
                    fontSize="large"
                    sx={{
                      color: "#fff",
                      animation: "shakes 1s",
                      "@keyframes shakes": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "25%": {
                          transform: "rotate(10deg)",
                        },
                        "50%": {
                          transform: "rotate(-20deg)",
                        },
                        "75%": {
                          transform: "rotate(15deg)",
                        },
                        "100%": {
                          transform: "rotate(-5deg)",
                        },
                      },
                    }}
                  >
                    check_circle
                  </Icon>
                  <SoftTypography variant="h5" color="white" fontWeight="bold" mt={2}>
                    Scaneo exitoso
                  </SoftTypography>
                  <SoftTypography variant="h6" color="white" fontWeight="bold" mt={1}>
                    Tu pedido ha sido escaneado correctamente
                  </SoftTypography>
                  <SoftTypography variant="caption" color="white" mt={1}>
                    ¡Gracias por tu compra!
                  </SoftTypography>
                  <SoftBox mt={2}>
                    <SoftButton color="white" circular onClick={handleCloseAll}>
                      <SoftTypography variant="button" color="uproGreen">
                        CERRAR
                      </SoftTypography>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Slide>
          )}
          {error && (
            <Slide in={open} direction="up" mountOnEnter unmountOnExit>
              <Card>
                <SoftBox
                  bgColor={getUproColor("uproPink")}
                  p={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Icon
                    fontSize="large"
                    sx={{
                      color: "#fff",
                      animation: "shakes 1s",
                      "@keyframes shakes": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "25%": {
                          transform: "rotate(10deg)",
                        },
                        "50%": {
                          transform: "rotate(-20deg)",
                        },
                        "75%": {
                          transform: "rotate(15deg)",
                        },
                        "100%": {
                          transform: "rotate(-5deg)",
                        },
                      },
                    }}
                  >
                    error
                  </Icon>
                  <SoftTypography variant="h5" color="white" fontWeight="bold" mt={2}>
                    Error al escanear el pedido
                  </SoftTypography>
                  <SoftTypography variant="caption" color="white" mt={1}>
                    Por favor, intenta nuevamente o contacta a un encargado
                  </SoftTypography>
                  <SoftBox mt={2}>
                    <SoftButton color="white" circular onClick={handleCloseAll}>
                      <SoftTypography variant="button" color="primary">
                        CERRAR
                      </SoftTypography>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Slide>
          )}
        </Card>
      </Fade>
    </Modal>
  );
}

ModalInfo.propTypes = {
  open: PropTypes.bool,
  handleCloseAll: PropTypes.func,
  success: PropTypes.bool,
  error: PropTypes.bool,
};
