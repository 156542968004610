// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Fade, Grid, Icon, Menu, MenuItem, Modal, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import ModalDelete from "components/Modals/Delete";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import dataCuestionario from "../../data/dataCuestionarios";
import { getUproColor } from "utils/colors";
import ModalCuestionario from "../ModalCuestionario";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
};

export default function ModalCuestionarios({ open, handleClose }) {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [selectedCuestionario, setSelectedCuestionario] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombre",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataCuestionario;

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedCuestionario(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteCuestionario, { loading: loadingDelete }] = useMutation(
    gql`
      mutation deleteCuestionario($id: ID!) {
        deleteCuestionario(id: $id) {
          id
        }
      }
    `
  );

  const [getCuestionarios, { loading, error, data, refetch }] = useLazyQuery(
    gql`
      query getCuestionarios($search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        cuestionarios(search: $search, pagination: { page: $page, limit: 10 }, order: $order) {
          id
          nombre
          fechaLimite
          obligatorio
          cantidadRespuestas
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (open) {
      getCuestionarios();
    }
  }, [open]);

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      <MenuItem
        disabled={false}
        onClick={() => {
          setOpenModalAdd(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Editar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproGreen"),
              }}
            >
              edit
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      {verificarAcceso(34) && (
        <MenuItem
          onClick={() => {
            setOpenModalDelete(true);
            handleCloseMenu();
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox>
              <SoftTypography variant="p">Eliminar</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("uproPink"),
                }}
              >
                delete
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )}
    </Menu>
  );

  const handleDeleteCuestionario = async (id) => {
    try {
      await deleteCuestionario({ variables: { id } });
      refetch();
      setSelectedCuestionario();
      handleSnackbar("Cuestionario eliminado correctamente", "success");
    } catch (e) {
      handleSnackbar(e.message || "Error al eliminar el cuestionario", "error");
      refetch();
      setSelectedCuestionario();
    }
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.cuestionarios, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftTypography variant="h6">Cuestionarios</SoftTypography>
                      <SoftBox
                        display={{
                          xs: "flex",
                          sm: "none",
                        }}
                        alignItems="center"
                        gap={1}
                      >
                        <Tooltip title="Agregar nuevo cuestionario" placement="top">
                          <SoftButton
                            color="primary"
                            circular
                            iconOnly
                            onClick={() => {
                              setOpenModalAdd(true);
                            }}
                          >
                            <Icon>add</Icon>
                          </SoftButton>
                        </Tooltip>
                        <Tooltip title="Cerrar" placement="top">
                          <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                            close
                          </Icon>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftBox
                        mr={{
                          xs: 0,
                          sm: 2,
                        }}
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "auto",
                          },
                        }}
                      >
                        <SoftInput
                          placeholder="Escribe aquí..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                        />
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "none",
                          sm: "block",
                        }}
                      >
                        <Tooltip title="Agregar nuevo cuestionario" placement="top">
                          <SoftButton
                            color="primary"
                            circular
                            onClick={() => setOpenModalAdd(true)}
                          >
                            <Icon>add</Icon>
                            &nbsp;Agregar
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        ml={2}
                        display={{
                          xs: "none",
                          sm: "flex",
                        }}
                      >
                        <Tooltip title="Cerrar" placement="top">
                          <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                            close
                          </Icon>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <Loading />
                ) : rows?.length > 0 ? (
                  <DataTable
                    columns={columns}
                    rows={rows}
                    order={orderBy}
                    setOrder={handleOrderBy}
                  />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={data?.cuestionarios.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </Card>
            {renderMenu}
            <ModalCuestionario
              open={openModalAdd}
              handleClose={() => {
                setOpenModalAdd(false);
                setSelectedCuestionario(null);
              }}
              refetch={refetch}
              selectedCuestionario={selectedCuestionario}
            />
            <ModalDelete
              open={openModalDelete}
              handleClose={() => setOpenModalDelete(false)}
              nombre={`el cuestionario ${selectedCuestionario?.nombre}`}
              functionDelete={() => {
                handleDeleteCuestionario(selectedCuestionario.id);
                setOpenModalDelete(false);
              }}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCuestionarios.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
